import moment from "moment";
import { useEffect, useState } from "react";
import { api } from "services";
import ExameCard from "./components/ExamCard";
import ExameCardSkeleton from "./components/ExamCardSkeleton";

interface Exam {
  id: number;
  legacyId: number;
  anexosCount: number;
  title: string;
  type: number;
  date: string;
  specilistDoctorName: string;
}

const Exams = () => {
  const [exams, setExams] = useState<Exam[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const sortExamsByDescendingDate = (exams: Exam[]) =>
  exams.sort((firstItem: Exam, secondItem: Exam) =>
    moment(firstItem.date) > moment(secondItem.date) ? -1 : 1
  );

  useEffect(() => {
    const getExams = async () => {
      try {
        const { data } = await api.get("/Exams");

        setExams(data);
      } catch (error) {
        console.log(error);
      }
    };

    getExams().then(() => setIsLoading(false));
  }, []);

  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="text-center mb-5">Meus Exames</h1>
                <div className="card text-body bg-light p-3 px-md-5 py-md-4 mb-5">
                  {!isLoading && !exams.length && (
                    <div className="text-center text-muted">
                      <p className="mb-0">
                        <i className="las la-file-excel display-4"></i>
                      </p>
                      <h6 className="mb-3">Nenhum exame encontrado</h6>
                    </div>
                  )}

                  {(!!isLoading || !!exams.length) && (
                    <>
                      <div className="row mb-2 px-3">
                        <div className="col-4 col-lg-3 px-2">
                          <h5>Data</h5>
                        </div>
                        <div className="col-4 col-lg-3 px-2">
                          <h5>Tipo</h5>
                        </div>
                        <div className="col-4 col-lg-3 px-2">
                          <h5>Exame</h5>
                        </div>
                        <div className="d-none" />
                      </div>

                      <div
                        className="listagem"
                        style={{ maxHeight: "50vh", overflowY: "auto" }}
                      >
                        {!isLoading
                          ? sortExamsByDescendingDate(exams).map((exam) => (
                              <ExameCard key={exam.id} exam={exam} />
                            ))
                          : [...Array(3)].map((_, index) => (
                              <ExameCardSkeleton key={index} />
                            ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exams;
