import axios, { AxiosRequestConfig } from "axios";

import { API_URL } from "config";
import { sessStorage } from "utils/storage";

export const api = axios.create({
  baseURL: API_URL,
});

let abortController:AbortController;

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = sessStorage.getToken();

  if(config.params && config.params.forceCancel){
    // Se já houver uma requisição anterior para mesma url, cancelamos ela
    if (abortController) {
      abortController.abort();
    }
    // Criamos um novo abortController
    abortController = new AbortController();
    config.signal = abortController.signal;  // Adiciona o sinal ao request
  }

  if (!config.headers) {
    config.headers = {};
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers.Accept = "application/json";

  return config;
};

api.interceptors.request.use(authRequestInterceptor);
