import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const { logout, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(token && token != ""){
      logout();
    }
  }, [logout, navigate])

  return (<></>);
};

export default Logout;
