import { useState } from "react";
import { Handle, Position } from "@xyflow/react";
import { CustomNode, WaitTimeNodeProps, Button } from "../utils/nodeTypes";

const WaitTimeNode = ({ data, onDelete, ...props }: WaitTimeNodeProps) => {

  return (
    <div className={`card wait-time-node ${props.selected ? 'border-primary shadow' : ''}`}>
      <div className="rounded-top bg-light border-bottom py-1 px-2 position-relative">
        <Handle id={`from-${props.id}`} type="target" position={Position.Left} className={`${props.selected ? 'border-primary' : ''}`} />
        <div className="row gx-2">
          <div className="col col-auto">
            <i className="uil uil-stopwatch text-primary"></i>
          </div>
          <div className="col fw-medium">
            <strong>Tempo de Espera</strong>
          </div>
          {props.selected && (
            <div className="col col-auto">
              {/* Botão de excluir o WaitTimeNode */}
              <button
                onClick={() => onDelete(props.id)} // Chama a função onDelete ao clicar
                className="btn btn-light text-danger btn-sm rounded p-1 lh-1 my-n1"
              >
                <i className="uil uil-trash"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="px-2 py-3 position-relative">
        <p className="small lh-sm text-center mb-0">Após aguardar <b>{data.interval} {data.interval == 1 ? data.unit.slice(0, -1) : data.unit}</b></p>
        <Handle
          id={`to-${props.id}`}
          type="source"
          position={Position.Right}
          className={`${props.selected ? 'border-primary' : ''}`}
        />
      </div>
    </div>
  );
};

export default WaitTimeNode;