import { useState, useEffect, useRef, ReactNode } from "react";
import 'animate.css';

interface AnimatedProps {
  children: ReactNode;
  type?: string;
  delay?: number;
  duration?: number;
  threshold?: number;
}

// // Define diferentes variantes de animação
// const animationVariants: Record<string, Variants> = {
//   fadeIn: {
//     hidden: { opacity: 0 },
//     visible: { opacity: 1 },
//   },
//   fadeInLeft: {
//     hidden: { opacity: 0, x: -100 },
//     visible: { opacity: 1, x: 0 },
//   },
//   fadeInRight: {
//     hidden: { opacity: 0, x: 100 },
//     visible: { opacity: 1, x: 0 },
//   },
//   fadeInUp: {
//     hidden: { opacity: 0, y: 100 },
//     visible: { opacity: 1, y: 0 },
//   },
// };

const Animated = ({ children, type, duration = 0.5, delay = 0, threshold = 0.2 }: AnimatedProps) => {
  // const controls = useAnimation();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false); // Novo estado
  // const variants = type && animationVariants[type] ? animationVariants[type] : animationVariants.fadeIn;

  // Adiciona o delay e duration às variantes
  // const delayedAnimationVariants: Variants = {
  //   hidden: variants.hidden,
  //   visible: {
  //     ...variants.visible,
  //     transition: { duration, delay }
  //   }
  // };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // Se você quiser parar de observar depois que o elemento aparecer
          // observer.unobserve(entry.target);
        } else {
          setIsVisible(false); // Se você quiser que a animação reinicie quando sair da tela
        }
        if (entry.isIntersecting && !hasAnimated) { // Verifica se já animou
          setHasAnimated(true); // Marca como animado
        }
      },
      {
        root: null, // Usar o viewport
        rootMargin: '0px',
        threshold: threshold // O elemento é considerado visível se 20% dele estiver visível
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [hasAnimated]);

  return (
    <div
      ref={ref}
      // initial="hidden"
      // animate={controls}
      // variants={{
      //   ...variants,
      //   visible: { ...variants.visible, transition: { delay } }
      // }}
      className={`${hasAnimated  ? 'animate__animated animate__'+type : ''}`} // Aplica a animação se visível
      style={{ 
        opacity: hasAnimated ? 1 : 0,  // Opacidade inicial
        animationDuration: `${duration}s`, 
        animationDelay: `${delay}s`, 
        // animationFillMode: 'both' // Para manter o estado final após a animação
      }}
    >
      {children}
    </div>
  );
};

export default Animated;
