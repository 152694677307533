import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";
import { Step } from 'react-joyride';

interface TutorialProviderProps {
  children: ReactNode;
}

interface TutorialProviderData {
  run: boolean;
  setRun: React.Dispatch<React.SetStateAction<boolean>>;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  tourActive: boolean;
  setTourActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TutorialContext = createContext<TutorialProviderData>(
  {} as TutorialProviderData
);

export const TutorialProvider = ({
  children,
}: TutorialProviderProps) => {
  const [run, setRun] = useState(false);
  const [tourActive, setTourActive] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const tutorialSteps = [
    {
      content: (<>
        <h2>Como utilizar o CRM!</h2>
        <p>Siga os passos para aprender como utilizar o sistema!</p>
      </>),
      placement: 'center',
      target: 'body',
      data: {
        next: '/',
      },
    },
    {
      content: (<>
        <h2>Menu</h2>
        <p>Aqui está o menu de navegação, você pode optar...</p>
      </>),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      spotlightClicks: true, //permite interagir com a página
      disableOverlayClose: true,
      target: '#header',
      data: {
        previous: '/',
        next: '/pacientes',
      },
      placement: 'right-start',
      disableBeacon: true,
    },
  ];
  const [steps, setSteps] = useState(tutorialSteps as Step[]);
  return (
    <TutorialContext.Provider
      value={{
        run,
        setRun,
        stepIndex,
        setStepIndex,
        steps,
        setSteps,
        tourActive,
        setTourActive,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => useContext(TutorialContext);
