import moment from "moment";

export const validateCpf = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  inputValue = inputValue.replace(/\D/g, "");

  const cpfNumbersList = inputValue
    .split("")
    .map((cpfStrChar) => parseInt(cpfStrChar));

  if (cpfNumbersList.length !== 11) {
    return false;
  }

  // Check CPF with all digits the same
  if (cpfNumbersList.every((digit) => digit === cpfNumbersList[0])) {
    return false;
  }

  let digit1 = 0;
  // digit2 = 0

  // First Check Digit
  let sum = 0;
  let sumNumber = 2;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  let rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[9] !== 0) {
      return false;
    } else {
      digit1 = 0;
    }
  } else {
    if (cpfNumbersList[9] !== 11 - rest) {
      return false;
    } else {
      digit1 = 11 - rest;
    }
  }

  // Second Check Digit
  sum = digit1 * 2;
  sumNumber = 3;

  for (const digit of cpfNumbersList.slice(0, 9).reverse()) {
    sum += digit * sumNumber;
    sumNumber++;
  }

  rest = sum % 11;

  if (rest < 2) {
    if (cpfNumbersList[10] !== 0) {
      return false;
    } // else digit2 = 0
  } else {
    if (cpfNumbersList[10] !== 11 - rest) {
      return false;
    } // else digit2 = 11 - rest
  }

  return true;
};

export const validateCnpj = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  inputValue = inputValue.replace(/[^\d]+/g, "");

  const cnpjNumbersList = inputValue
    .split("")
    .map((cnpjStrChar) => parseInt(cnpjStrChar));

  if (cnpjNumbersList.length !== 14) {
    return false;
  }

  // Check CNPJ with all digits the same
  if (cnpjNumbersList.every((digit) => digit === cnpjNumbersList[0])) {
    return false;
  }

  // Validate Check Digits
  let size = inputValue.length - 2;
  let numbers = inputValue.substring(0, size);
  const digitos = inputValue.substring(size);
  let sum = 0;
  let pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digitos.charAt(0))) return false;

  size = size + 1;
  numbers = inputValue.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result != parseInt(digitos.charAt(1))) return false;

  return true;
};

export const validateCpfAndCnpj = (inputValue: string | undefined) => {
  const isValidCpf = validateCpf(inputValue);
  const isValidCnpj = validateCnpj(inputValue);

  if (isValidCpf || isValidCnpj) {
    return true;
  }
  return false;
};

export const validateBirthDate = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  if (moment(inputValue, "DD/MM/YYYY") >= moment().startOf("d")) {
    return false;
  }

  return moment(inputValue, "DD/MM/YYYY", true).isValid();
};

export const validateDate = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  return moment(inputValue, "DD/MM/YYYY", true).isValid();
};

export const validateExpiryDate = (inputValue: string | undefined) => {
  if (!inputValue) {
    return false;
  }

  if (moment(inputValue, "MM/YYYY") <= moment().startOf("month")) {
    return false;
  }

  return moment(inputValue, "MM/YYYY", true).isValid();
};

export const validateProcedureDate = (inputValue: Date | null | undefined, minLimitDateProcedure: number) => {
  if (moment(inputValue) < moment().startOf("d").add(minLimitDateProcedure, "d")) {
    return false;
  }

  return true;
};
