import { Box, Skeleton } from "@mui/material";

const ExameCardSkeleton = () => {
  return (
    <Box className="card p-3 mb-2">
      <Box className="row align-items-center">
        <Box className="col-4 col-lg-3">
          <Skeleton variant="text" width={"5rem"} />
          <Skeleton variant="text" width={"3rem"} />
        </Box>
        <Box className="col-4 col-lg-3">
          <Skeleton variant="text" width={"5rem"} />
        </Box>
        <Box className="col-4 col-lg-3">
          <Skeleton variant="text" width={"5rem"} />
        </Box>
        <Box className="col-12 col-lg-3 border-top border-lg-top-0 d-flex justify-content-center mt-3 mt-lg-0 pt-3 pt-lg-0">
          <Skeleton
            variant="text"
            width={90}
            height={50}
            sx={{ bgcolor: "primary.main", borderRadius: "1.2rem" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ExameCardSkeleton;
