import { ReactNode, useState, useEffect, useRef } from "react";

export interface AsideProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode;
  isOpen?: boolean; // Recebe o estado de visibilidade
  onToggleAside?: (show: boolean) => void;
}

const Aside = ({
  children,
  isOpen,
  onToggleAside,
  ...rest
}: AsideProps) => {

  const asideRef = useRef<HTMLBaseElement>(null);

  const [asideShow, setAsideShow] = useState(isOpen ?? false);
  const [asideName, setAsideName] = useState('');

  const handleAsideShow = () => {
    const allAsides = document.querySelectorAll("aside");
    allAsides.forEach((aside) => {
      if (aside.id !== asideRef.current?.id && aside.classList.contains("active")) {
        const button = aside.querySelector(".abre-aside");
        if (button) {
          (button as HTMLElement).click();
        }
      }
    });
    const newAsideShow = !asideShow;
    setAsideShow(newAsideShow);
    if (onToggleAside) {
      onToggleAside(newAsideShow);
    }
  };

  const handleResize = () => {
    if (document.body.clientWidth > 991) {
      setAsideShow(false);
    }
  }

  useEffect(() => {
    if (asideRef.current && asideRef.current.id) {
      setAsideName(asideRef.current.id.toLowerCase().replace(/\s+/g, '') + 'Show');
    }
  }, [asideRef]);

  useEffect(() => {
    if (asideName) {
      setAsideShow(localStorage.getItem(asideName) === "false" ? false : true);
    }
  }, [asideName]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (asideName) {
      localStorage.setItem(asideName, asideShow.toString());
    }
  }, [asideShow, asideName]);

  useEffect(() => {
    setAsideShow(isOpen ?? false);
  }, [isOpen]);

  return (
    <>
      <aside {...rest} ref={asideRef} className={`${rest.className ?? ''} ${asideShow === false ? '' : 'active'}`}>
        <a role="button" onClick={handleAsideShow} className="abre-aside btn btn-primary"><i className="uil uil-angle-right"></i></a>
        <div className="overflow-aside">
          {children}
        </div>
      </aside>
      <div className={`aside-overlay ${asideShow === false ? 'd-none' : ''}`} onClick={handleAsideShow}></div>
    </>
  );
};

export default Aside;
