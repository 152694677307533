import { useState, useEffect } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";

import PersonalDataForm from "./components/PersonalDataForm";
import Footer from "components/Footer";
import imgGirl from "assets/medica.png";
import imgLinha from "assets/cuidado.png";
import { Id } from "types/board";

interface LocationState {
  id?: number|null;
  idboard?: Id|null;
}

const Cadastro = () => {
  useEffect(() => { document.title = 'Cadastro | CRM - Conectadoc'; }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const specialistId = state?.id ?? null;
  const idBoard = state?.idboard ?? null;
  useEffect(() => {
    if(!specialistId){
      navigate('/');
    }
  },[]);

  return (
    <ContainerAnimation className="content p-0">
      <div className="row g-0 min-vh-100">
        <div className="col-12 col-lg-6 bg-white d-flex flex-column">
          <div className="flex-grow-1 d-flex align-items-center justify-content-center px-3 py-5">
            <PersonalDataForm specialistId={specialistId} idBoard={idBoard} />
          </div>
          <Footer/>
        </div>
        <div className="col-lg-6 bg-gradient-login px-5 d-none d-lg-flex align-items-center justify-content-center">
          <div className="bg-image-login position-relative d-flex ps-5 pt-5 shadow-lg">
            <div className="col col-2 pb-5">
              <img src={imgLinha} className="img-fluid animated slideInLeft" alt="Linhas de Cuidado"/>
            </div>
            <div className="col d-flex flex-column">
              <div className="icon-animation">
                <div className="circle shadow-lg">
                  <i className="uil uil-lock fs-1 lh-1 text-primary"></i>
                </div>
              </div>
              <div className="ps-4">
                <h3 className="mb-4 pe-4 text-pure-white fw-light animated slideInDown">O profissional acompanha seu caso de forma detalhada.</h3>
              </div>
              <div className="flex-grow-1">
                <img src={imgGirl} className="position-absolute animated slideInUp" style={{width:'90%',left: '26%'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Cadastro;
