import { ReactNode } from "react";

import { AuthProvider } from "./Auth";
import { CompanyProvider } from "./Company";
import { DarkModeProvider } from "./DarkMode";
import { PatientProvider } from "./Patient";
import { ProfessionalProvider } from "./Professional";
import { FeedbackProvider } from "./Feedback";
import { TutorialProvider } from "./Tutorial";
import { RegisterProvider } from "./Register";
import { UserProvider } from "./User";
import { BoardProvider } from "./Board";
import { WhatsappProvider } from "./Whatsapp";
import { VersionNotesProvider } from "./VersionNotes";

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <AuthProvider>
      <RegisterProvider>
        <TutorialProvider>
          <DarkModeProvider>
            <VersionNotesProvider>
              <UserProvider>
                <CompanyProvider>
                  <ProfessionalProvider>
                    <FeedbackProvider>
                      <PatientProvider>
                        <BoardProvider>
                          <WhatsappProvider>
                            {children}
                          </WhatsappProvider>
                        </BoardProvider>
                      </PatientProvider>
                    </FeedbackProvider>
                  </ProfessionalProvider>
                </CompanyProvider>
              </UserProvider>
            </VersionNotesProvider>
          </DarkModeProvider>
        </TutorialProvider>
      </RegisterProvider>
    </AuthProvider>
  );
};

export default Providers;
