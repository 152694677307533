import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface DarkModeProviderProps {
  children: ReactNode;
}

interface DarkModeProviderData {
  darkMode: boolean;
  toggleDarkMode: () => void;
  menuShow: boolean;
  setMenuShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleMenuShow: () => void;
}

export const DarkModeContext = createContext<DarkModeProviderData>(
  {} as DarkModeProviderData
);

export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  const [darkMode, setDarkMode] = useState(false);

  const [menuShow, setMenuShow] = useState(false);

  const handleMenuShow = () => {
    setMenuShow(!menuShow)
  };
  
  useEffect(() => {
    const menuShow = localStorage.getItem("menuShow");
    if (menuShow === "true") {
      setMenuShow(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("menuShow", menuShow.toString());
    
    if(menuShow){
      document.body.classList.add('left-menu');
    }else{
      document.body.classList.remove('left-menu');
    }
  }, [menuShow]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleResize = () => {
    if (document.body.clientWidth <= 640) {
      setMenuShow(false);
    }
  }

  useEffect(() => {
    const darkMode = localStorage.getItem("darkMode");
    if (darkMode === "true") {
      setDarkMode(true);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
    
    if (darkMode) {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.removeAttribute("data-bs-theme");
    }
  }, [darkMode]);

  return (
    <DarkModeContext.Provider
      value={{
        darkMode,
        toggleDarkMode,
        menuShow,
        setMenuShow,
        handleMenuShow
      }}
    > 
      { menuShow && (<div className="menu-overlay" onClick={()=>handleMenuShow()}></div>)}
      
      {children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);
