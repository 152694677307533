import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { sessStorage } from "utils/storage";
import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import SelectDropdown from "components/SelectDropdown";
import Alert from 'react-bootstrap/Alert';
import RadioInput from "components/RadioInput";
import ModalComponent from 'components/ModalComponent';
import { useAuth } from "providers/Auth";
import { usePatient } from "providers/Patient";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { Gender } from "types/enums";
import { PatientData } from "types/patient";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault } from "types/board";
import { cpfMask, mobilePhoneMask } from "utils/formMasks";
import { healthInsurenceOptions } from "utils/healthInsurence";
import { validateBirthDate, validateCpf } from "utils/formValidations";
import { toast } from "utils/toast";
import {
  handleBirthDateInput,
  handleCpfInput,
  handleMobilePhoneInput,
} from "utils/handleInput";
import imgLogo from "assets/logo-conectadoc.svg";
import logger from "utils/logger";

interface PropsPersonalDataForm {
  specialistId: number | null;
  idBoard: Id | null;
}

const PersonalDataForm = ({
  specialistId,
  idBoard
}: PropsPersonalDataForm) => {

  // limpa ao atalizar a tela.
  useEffect(() => {
    clearPatient();
  }, []);

  const { token, userRole, loginQuick } = useAuth();
  const { userData } = useUser();

  const {
    patients,
    setPatients,
    patientData,
    setPatientData,
    editPatientData,
    patientLoading,
    getPatientById,
    getPatientByCpf,
    searchPatient,
    getPatientAppointments,
    appointments,
    setAppointments
  } = usePatient();

  const {
    boards,
    isLoading,
    setBoards,
    getBoards,
    boardData,
    setBoardData,
    tasksData,
    setTasksData,
    columnsData,
    setColumnsData,
    createBoard,
    deleteBoard,
    getBoardById,
    addColumnToBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    editColumnFromBoard,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate
  } = useBoard();

  const [termsOfUseModalShow, setTermsOfUseModalShow] = useState(false);
  const [endProcess, setEndProcess] = useState(false);

  const schemaCpf = yup.object().shape({
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test(
        "cpfValidator",
        `*CPF inválido`,
        validateCpf
      )
      .transform((value) => value.replace(/\D/g, "")),
  });

  const schemaPatient = yup.object().shape({
    isTermsOfUseAccepted: yup
      .boolean()
      .oneOf([true], "*É necessário concordar com os Termos Gerais para prosseguir."),
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido"),
    sex: yup.string(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
    // healthInsurence: yup
    //   .object()
    //   .required("*Campo obrigatório")
    //   .shape({
    //     value: yup.string().required("*Selecione uma opção"),
    //     label: yup.string().required("*Selecione uma opção"),
    //   }),
  });

  const handleTermsOfUseInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setValue("isTermsOfUseAccepted", true);
    } else {
      setValue("isTermsOfUseAccepted", false);
    }
    trigger("isTermsOfUseAccepted");
  };

  const getAuthToken = (specialistIs: number) => {
    var now = new Date;
    var str = now.getDate().toString(10).padStart(2, '0') + "#" + specialistIs;
    var passPhrase = now.getDate().toString(10).padStart(2, '0') + "C0n3ct@D0c2o2s";
    var tempToken = "";
    for (var i = 0; i < str.length; i++) {
      var ic = str.substr(i, 1).charCodeAt(0) ^ passPhrase.substr(i % passPhrase.length, 1).charCodeAt(0);
      tempToken += ic.toString(16).padStart(2, '0');
    }
    return tempToken;
  }

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<PatientData>({
    resolver: yupResolver((patientData.cpf ? schemaPatient : schemaCpf)),
  });

  const onSubmit = (data: PatientData) => {

    if (patientData.cpf) {
      setPatientData({ ...patientData, ...data });
      if (specialistId) {
        handleQuickLogin(specialistId, idBoard);
      }
    } else {
      getPatientByCpf(data.cpf);
      // if(boards && boards.length > 0){
      //   getBoardById(boards[0].id);
      // }
    }
  };

  const clearPatient = () => {
    setPatientData({} as PatientData);
    reset();
    sessStorage.clearToken();
    setBoards({} as Board[]);
    setBoardData({} as Board);
    setColumnsData([]);
    setEndProcess(false);
    console.log(patientData);
  };

  const handleQuickLogin = async (id: number, idBoard?: Id | null) => {
    const tempToken: string = getAuthToken(id);
    console.log(idBoard);

    try {
      const tokenQuick = await loginQuick(id, tempToken);
      if (tokenQuick && tokenQuick != "") {
        // seta o token na sessão temporariamente para cadastrar os boards.
        sessStorage.setToken(tokenQuick);

        if (boards.length > 0) {
          // cadastra paciente no board indicado
          if (idBoard) {
            getBoardById(idBoard);
            logger.log("Get Board: " + idBoard);
            // cadastra paciente no primerio board encontrado
          } else {
            logger.log("Get first Board: " + boards[0].id);
            getBoardById(boards[0].id);
          }
          return;
        }

        getBoards();
        
      } else {
        toast.fire({
          icon: "error",
          title: "Occorreu um erro ao salvar.",
          text: "Verifique com a secretária.",
        });
        console.error("Erro ao obter token no QuickLogin.");
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  /////////////////////////
  // BOARD
  /////////////////////////
  const defaultCols: ColumnDefault[] = [
    {
      title: "Leads",
      type: 1,
      order: 0
    },
    {
      title: "Consulta",
      color: "#ecefaf",
      type: 3,
      order: 1
    },
    {
      title: "Cirurgia",
      type: 0,
      order: 2
    },
    {
      title: "Pós Cirurgia",
      color: "#ffe5d0",
      type: 0,
      order: 3
    },
    {
      title: "Fim do Processo",
      color: "#c1e1c5",
      type: 2,
      order: 999999
    },
  ];

  useEffect(() => {
    if (boards.length > 0) {
      if (idBoard) {
        getBoardById(idBoard);
        logger.log("Get Board: " + idBoard);
      } else {
        logger.log("Get first Board: " + boards[0].id);
        getBoardById(boards[0].id);
      }
      return;
    }
  }, [boards]);

  useEffect(() => {
    if (boardData.columns) {
      if (boardData.columns.length <= 0) {
        logger.log("Criando Columns Default.");
        {
          defaultCols.map((column) => {
            addColumnToBoard(boardData.id, column);
          })
        }
        if (boardData.columns.length == defaultCols.length) {
          getBoardById(boards[0].id);
        }
      }
      if (boardData.columns.length > 0) {
        logger.log("Set Board Columns.");
        logger.log(boardData.columns);
        setColumnsData(boardData.columns);
      }
    }
  }, [boardData]);

  const addPatientToBoard = async () => {
    try {
      const leadsColumn = boardData.columns.filter((col) => col.type === 1); // 0 = normal, 1 = start, 2 = end, 3 = appointment
      if (leadsColumn[0].tasks && leadsColumn[0].tasks.length > 0) {
        const filteredTasks = (leadsColumn[0].tasks).filter((task) => task.title === patientData?.name);
        if (filteredTasks.length > 0) {
          setEndProcess(true);
          // limpa o token na sessão.
          sessStorage.clearToken();
          return;
        }
      }

      const patientReturn = await addTaskToBoardColumn(boardData.id, leadsColumn[0].id, {
        title: patientData?.name ?? "",
        description: "Lead entrou pelo QR-Code",
        patient: {
          id: patientData.id,
          name: patientData?.name ?? "",
          email: patientData?.email
        }
      }, true);
      if (patientReturn) {
        setEndProcess(true);
        // limpa o token na sessão.
        sessStorage.clearToken();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    logger.log("atualizou columnsData, endProcess:" + endProcess + ", patientData");
    // pega a coluna de leads.
    if (patientData.finish && endProcess == false && columnsData.length > 0) {
      console.log("addPatientToBoard");
      console.log(patientData);
      addPatientToBoard();
      return;
    }
    if (patientData && columnsData.length > 0 && endProcess == false) {
      console.log("editPatientData");
      console.log(patientData);
      editPatientData(patientData);
      return;
    }
  }, [columnsData, endProcess, patientData]);

  return (
    <>
      <ContainerAnimation className="w-100 d-flex justify-content-center">
        <form className="form-login aos-init"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-once
          onSubmit={handleSubmit(onSubmit)} >

          {endProcess ? (
            <>
              <div className="mb-3">
                <h1 className="mb-5 text-center"><img src={imgLogo} className="img-fluid" alt="CRM" width={150} /></h1>
                <h2 className="fw-semibold">OBRIGADO!</h2>
                <p className="text-muted">Seu cadastro foi finalizado com sucesso!</p>
              </div>
              <a role="button" onClick={() => { clearPatient() }} className="small text-decoration-hover"><i className="uil uil-arrow-left me-2"></i>Voltar</a>
            </>
          ) : (
            <>
              <div className="mb-3">
                <h1 className="mb-5 text-center"><img src={imgLogo} className="img-fluid" alt="CRM" width={150} /></h1>
                <h2 className="fw-semibold">Cadastro</h2>
                {patientData.cpf ? (
                  <>
                    {!patientData.name ? (
                      <p className="text-muted mb-0">Não encontramos seu CPF em nossa base, finalize o cadastro para: <b>{cpfMask(patientData.cpf)}</b>.</p>
                    ) : (
                      <p className="text-muted">Confirme os dados do CPF: <b>{cpfMask(patientData.cpf)}</b>.</p>
                    )}
                  </>
                ) : (
                  <p className="text-muted">Informe seu CPF para prosseguir!</p>
                )}
              </div>
              {!patientData.cpf ? (
                <Input
                  containerClassName="form-floating mb-3"
                  label="CPF: 999.999.999-99"
                  placeholder="CPF: 999.999.999-99"
                  type="tel"
                  maxLength={14}
                  register={register("cpf")}
                  error={errors?.cpf?.message}
                  onChange={(e) => handleCpfInput(e, setValue, "cpf")}
                  defaultValue={cpfMask(`${patientData.cpf}`)}
                  autoComplete="off"
                />
              ) : (
                <>
                  <Input
                    containerClassName="form-floating mb-3"
                    className="text-uppercase form-floating"
                    type="text"
                    label="Nome Completo"
                    placeholder="Seu Nome Aqui"
                    register={register("name")}
                    error={errors?.name?.message}
                    defaultValue={patientData?.name}
                    autoComplete="off"
                  />

                  <Input
                    containerClassName="form-floating mb-3"
                    type="email"
                    label="E-mail"
                    placeholder="seu@email.com"
                    register={register("email")}
                    error={errors?.email?.message}
                    defaultValue={patientData?.email}
                    autoComplete="off"
                  />

                  <div className="form-floating mb-3 animated fadeInUp delay-200ms">
                    <div className="form-control form-control">
                      <div className="row">
                        <div className="col col-auto">
                          <RadioInput
                            value="M"
                            label="Masc."
                            register={register("sex")}
                            defaultChecked={patientData?.sex === Gender.Male}
                          />
                        </div>
                        <div className="col col-auto">
                          <RadioInput
                            value="F"
                            label="Fem."
                            register={register("sex")}
                            defaultChecked={patientData?.sex === Gender.Female}
                          />
                        </div>
                        <div className="col col-auto">
                          <RadioInput
                            value="I"
                            label="Intersexo"
                            register={register("sex")}
                            defaultChecked={patientData?.sex === Gender.Intersex}
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="select">Sexo</label>
                    <div className="text-danger mb-3">
                      <small>{!!errors?.sex && "*Selecione uma opção"}</small>
                    </div>
                  </div>

                  <Input
                    containerClassName="form-floating mb-3"
                    type="tel"
                    label="Data de Nascimento"
                    placeholder="dd/mm/aaaa"
                    maxLength={10}
                    register={register("birthDate")}
                    error={errors?.birthDate?.message}
                    onChange={(e) =>
                      handleBirthDateInput(e, setValue, "birthDate")
                    }
                    defaultValue={patientData?.birthDate ? moment(patientData?.birthDate).format("DD/MM/YYYY") : ''}
                    autoComplete="off"
                  />

                  <Input
                    containerClassName="form-floating mb-3"
                    type="tel"
                    label="Telefone"
                    placeholder="(99) 99999-9999"
                    maxLength={15}
                    register={register("mobilePhone")}
                    error={errors?.mobilePhone?.message}
                    onChange={(e) =>
                      handleMobilePhoneInput(e, setValue, "mobilePhone")
                    }
                    defaultValue={mobilePhoneMask(
                      `${patientData.dddMobilePhone}${patientData.mobilePhone}`
                    )}
                    autoComplete="off"
                  />


                  {/* <SelectDropdown
                    containerClassName="form-floating mb-3"
                    options={healthInsurenceOptions}
                    label="Convênio"
                    name="healthInsurence"
                    placeholder="Convênio"
                    control={control}
                    error={errors.healthInsurence?.label?.message}
                    isSearchable={true}
                    isDisabled={false}
                    isClearable={true}
                    defaultValue={healthInsurenceOptions.find(option => option.value === patientData.scheduleHealthInsurence)}
                  /> */}
                  <div className="mb-4">
                    <label
                      htmlFor="acceptTermsOfUse"
                      className="control control--checkbox mb-0"
                    >
                      <span className="caption">
                        Li e concordo com os
                      </span>
                      <input
                        id="acceptTermsOfUse"
                        type="checkbox"
                        {...register("isTermsOfUseAccepted")}
                        onChange={handleTermsOfUseInput}
                      />
                      <div className={`control__indicator ${errors?.isTermsOfUseAccepted ? "border border-danger" : ""}`} ></div>
                    </label>
                    <a role="button"
                      className="ms-2 text-decoration-hover"
                      onClick={() => setTermsOfUseModalShow(true)}
                    >Termos Gerais</a>.
                    {errors?.isTermsOfUseAccepted ? (<>
                      <div className="text-danger">
                        <small>
                          {errors.isTermsOfUseAccepted.message}
                        </small>
                      </div>
                    </>) : (<></>)}
                  </div>
                </>
              )}
              <div className="d-grid gap-2">
                <Button type="submit" className="btn-lg btn-primary rounded-pill" disabled={patientLoading}>
                  {patientData.cpf ? "Finalizar Cadastro" : "Prosseguir"}
                  {!!patientLoading && (
                    <CircularProgress
                      color="inherit"
                      size={12}
                      className="align-text-top ms-2"
                    />
                  )}
                </Button>
              </div>
              {patientData.cpf && (
                <div className="pt-3">
                  <a role="button" onClick={() => { clearPatient() }} className="small text-decoration-hover"><i className="uil uil-arrow-left me-2"></i>Voltar</a>
                </div>
              )}
            </>
          )}
          <div className="text-center pt-3">
            <Link
              to="/login"
              className="text-decoration-hover"
              style={{ pointerEvents: patientLoading ? "none" : undefined }}
            >
              Fazer Login
            </Link>
          </div>
        </form>
      </ContainerAnimation>

      {/* Modal de Termos */}
      <ModalComponent
        title="Termos Gerais"
        show={termsOfUseModalShow}
        onHide={() => {
          setTermsOfUseModalShow(false);
        }}
        size="lg"
      >
        <Modal.Body>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {`Caro Usuário,

O Brasil,  desde  o  ano  de  2018,  possui  uma  Lei  específica  para  regulamentar  a proteção de dados pessoais e dados pessoais sensíveis da população. Trata-se da Lei nº 13.709/2018, mais conhecida como LGPD.

A Lei divide os dados em 2 modalidades distintas:
- Dado   pessoal:   informação   relacionada   à   pessoa   natural   identificada   ou identificável; encaixa-se nessa modalidade, por exemplo, o nome, RG, CPF e endereço.
- Dado pessoal sensível: dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou à organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.

Ainda, a lei prevê  a  possibilidade  de  transformar  um  dado  pessoal  ou  dado  pessoal sensível em um dado anonimizado, ou seja, um dado em que não se pode identificar o seu titular.

E dispõe também sobre as regras específicas para realização de tratamento de cada uma  das  modalidades  de  dados.  O  tratamento,  segundo  a  Lei,  se  refere  a  "toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção,  classificação,  utilização,  acesso,  reprodução,  transmissão,  distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração".

Nossa plataforma, em função de sua natureza e finalidade relacionada à telemedicina, promove o tratamento de dados pessoais e dados pessoais sensíveis dos usuários. 

Entendemos que é muito importante que você tenha conhecimento das atividades de tratamento  que  são  realizadas  e  também  que  concorde  com  esse  tratamento,  para que não tenhamos problemas futuramente. 

Como  a  nossa  plataforma  contempla  diferentes  serviços  na  área  de  saúde,  as modalidades  de  tratamentos  de  dados  que  serão  realizados  dependem  de  qual  é  o serviço contratado pelo usuário.

Para  os  usuários  que  se  cadastraram  diretamente  na  plataforma,  as  atividades  de tratamento realizadas pela plataforma são as seguintes:
1. Coleta e armazenamento de dados pessoais do usuário da plataforma;
2. Armazenamento  de  dados  pessoais  sensíveis  relacionados  ao  quadro  de saúde do usuário da plataforma, anotados pelo(s) profissional(is) médico(s) em seus respectivos prontuários;
3. Armazenamento   de   arquivos   criptografados   contendo   as   gravações   das teleconsultas realizadas pelo usuário na plataforma;
4. Compartilhamento da gravação da teleconsulta exclusivamente com o médico responsável pelo atendimento;
5. Compartilhamento   de   dados   pessoais   e   de   dados   pessoais   sensíveis relacionados  ao  quadro  de  saúde  do  usuário  da  plataforma (prontuário) com profissionais médicos (exclusivamente aqueles que possuem relação médico-paciente com o usuário) bem como instituições públicas, especialmente Secretárias e Unidades de Saúde, para o auxílio na prestação de serviços, cálculo de estatísticas e na continuidade do serviço de saúde;

Já  para  os  usuários  incluídos  na  plataforma  através  da  contratação  de  nossos serviços pelos empregadores, as atividades de tratamento realizadas pela plataforma são as seguintes:
1. Coleta e armazenamento de dados pessoais do usuário da plataforma;
2. Armazenamento  de  dados  pessoais  sensíveis  relacionados  ao  quadro  de saúde do usuário da plataforma, anotados pelo(s) profissional(is) de saúde nos atendimentos realizados;
3. Armazenamento   de   arquivos   criptografados   contendo   as   gravações   das teleconsultas realizadas pelo usuário na plataforma;
4. Compartilhamento da gravação da teleconsulta exclusivamente com o médico responsável pelo atendimento;
5. Compartilhamento   de   dados   pessoais   e   de   dados   pessoais   sensíveis relacionados  ao  quadro  de  saúde  do  usuário  da  plataforma  exclusivamente com outros profissionais de saúde que componham o grupo responsável pelos cuidados digitais na área de saúde dos empregados;

Para este grupo específico de usuários, considerando que um dos objetivos do serviço contratado pelos empregadores é ter acesso a um panorama geral sobre a saúde de seus  empregados  (seja  ela  de  natureza  física,  mental,  entre  outras),  os  dados pessoais  sensíveis  relacionados  ao  quadro  de  saúde  serão  anonimizados  (tornados anônimos,  ou  seja,  sem  possibilidade  de  ligação  entre  os  dados  e  seu  titular), compilados  com  os  dados  pessoais  sensíveis  dos  demais  usuários  (podem  ser membros de equipe, membros do setor ou mesmo de toda empresa) e apresentados ao empregador em forma de relatório.

Esse  estudo,  extremamente  positivo,  visa  possibilitar  que  o  empregador  conduza analises  sobre  possíveis  medidas  que  podem  ser  adotadas  com  vistas  a  auxiliar  os empregados na manutenção e/ou melhoria de sua saúde.

Igualmente, entendemos que é relevante que todos saibam que a plataforma também opera  mediante  agendamentos  ativos  promovidos  pelas  equipes  de  saúde  e  com envio   e   recebimento  de   mensagens   digitais  aos   usuários   (e-mails,   mensagens instantâneas, alertas e notificações). 

Ainda, é importante  que todos tenham conhecimento que  a  Lei  afasta  a necessidade de  consentimento  específico  do  usuário  para  fins  de  tratamento  de  dados pessoais sensíveis nas seguintes hipóteses:
1. Cumprimento de obrigação legal ou regulatória por parte da plataforma;
2. Execução de políticas públicas por parte da administração pública;
3. Realização  de  estudos  por  órgão  de  pesquisa;  nesta  hipótese,  caso  aplicável, será dada prioridade para compartilhamento de dados anonimizados, ou seja, em que não é possível vincular a informação ao seu titular;
4. Exercício regular  de  direitos  em  âmbito contratual,  judicial,  administrativo  ou arbitral;
5. Proteção da vida ou da incolumidade física do titular ou de terceiros;
6. Tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária;

Por fim, a LGPD, em seu artigo 18, apresenta o rol de direitos dos titulares de dados:
  Art.  18.  O  titular  dos  dados  pessoais  tem  direito  a  obter  do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:
  I - confirmação da existência de tratamento;
  II - acesso aos dados;
  III - correção     de     dados     incompletos,     inexatos     ou desatualizados;
  IV - anonimização,    bloqueio    ou    eliminação    de    dados desnecessários,  excessivos  ou  tratados  em  desconformidade com o disposto nesta Lei;
  V - portabilidade  dos  dados  a  outro  fornecedor  de  serviço  ou produto,   mediante   requisição   expressa   e   observados   os segredos    comercial    e    industrial,    de acordo    com    a regulamentação do órgão controlador;
  VI - portabilidade dos dados  a  outro  fornecedor  de  serviço  ou produto,   mediante   requisição   expressa,   de   acordo   com   a regulamentação    da    autoridade    nacional,    observados    os segredos comercial e industrial;
  VII - eliminação    dos    dados    pessoais    tratados    com    o consentimento do titular,  exceto  nas  hipóteses  previstas  no art. 16 desta Lei;
  VIII - informação  das  entidades  públicas  e  privadas  com  as quais o controlador realizou uso compartilhado de dados;
  IX - informação   sobre   a   possibilidade   de   não   fornecer consentimento e sobre as consequências da negativa;
  X - revogação do consentimento.

Ao dar aceite neste Termo, consideramos que você está de acordo com a nossa Política de Privacidade, disponível em https://conectadoc.com.br/PoliticaPrivacidade.aspx.

Estamos  à  disposição  de  todos  os  usuários  para  auxiliá-los  no  tocante  aos  direitos elencados na Lei e também sobre eventuais dúvidas.
  
Equipe ConectaDoc`}
          </div>
        </Modal.Body>
        <Modal.Footer className="position-sticky bottom-0 bg-body">
          <Button
            type="button"
            className="btn-primary rounded-pill"
            onClick={() => {
              setValue("isTermsOfUseAccepted", true);
              setTermsOfUseModalShow(false);
              trigger("isTermsOfUseAccepted");
            }}
          >
            Aceitar e Fechar
          </Button>
        </Modal.Footer>
      </ModalComponent>
    </>
  );
};

export default PersonalDataForm;
