import { useState } from "react";
import {
  Handle,
  Position,
} from "@xyflow/react";
import { LoopNodeProps } from "../utils/nodeTypes";

const LoopNode = ({ data, onDelete, ...props }: LoopNodeProps) => {

  return (
    <div className={`card loop-node ${props.selected ? 'border-primary shadow' : ''}`}>
      <div className="rounded-top bg-light border-bottom py-1 px-2 position-relative">
        <Handle id={`from-${props.id}`} type="target" position={Position.Left} className={`${props.selected ? 'border-primary' : ''}`} />
        <div className="row gx-2">
          <div className="col col-auto">
            <i className="uil uil-repeat text-primary"></i>
          </div>
          <div className="col fw-medium">
            <strong>Tentativas</strong>
          </div>
          {props.selected && (
            <div className="col col-auto">
              <button
                onClick={() => onDelete(props.id)} // Chama a função onDelete ao clicar
                className="btn btn-light text-danger btn-sm rounded p-1 lh-1 my-n1"
              >
                <i className="uil uil-trash"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="px-2 py-3 position-relative">
        <p className="small lh-sm text-center mb-0">Após tentar <b>{data.times}</b> {data.times == 1 ? 'vez' : 'vezes'}</p>
      </div>
      <div className="rounded-bottom bg-light border-top py-1 px-2 d-flex justify-content-between position-relative">
        <Handle
          id={`prev-${props.id}`}
          type="source"
          position={Position.Left}
          className={`${props.selected ? 'border-primary' : ''}`}
        />
        <p className="mb-0 text-center small">Voltar para</p>
        <p className="mb-0 text-center small">Prosseguir para</p>
        <Handle
          id={`to-${props.id}`}
          type="source"
          position={Position.Right}
          className={`${props.selected ? 'border-primary' : ''}`}
        />
      </div>
    </div>
  );
};

export default LoopNode;