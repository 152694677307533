import { useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppointmentInterface } from "types/patient";
import { api } from "services";

const Queue = () => {
  const navigation = useNavigate();
  const location = useLocation()
  const teleCRef = useRef<NodeJS.Timeout>();

  const enteredOnRoom = async () => {
    try {
      await api.put(`Attendance/waitRoom/${appointment.legacyId}`);
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetData = async () => {
    try {
      const response = await api.get(`/Attendance/teleCheck/${appointment.id}`)
      
      if (response.data.medicEntered) {
        navigation("/atendimento", {state: appointment})
      } 
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (location.state) {
      enteredOnRoom();
      teleCRef.current = setInterval(handleGetData, 5000);
      return () => {
        clearInterval(teleCRef.current);
      };
    }
  }, []);
  
  // Essa condição deve ficar após o useEffect e antes da desestruturação de location.state
  // para não dar erro quando usuário tentar acessar essa página pela url
  if (!location.state) {
    return <Navigate to="/" />
  }
  
  const {appointment} = location.state as AppointmentInterface
  
  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="text-center mb-5">Atendimento</h1>

                <div className="card text-body bg-light p-3 px-md-5 py-md-4">
                  <div className="text-center">
                    <h3 className="mb-4">
                      <span className="text-primary">Aguarde</span>, seu atendimento é o <span className="bold text-primary">próximo</span> da fila!
                    </h3>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-window-close text-primary"></i>
                    </div>
                    <div className="col">
                      <b className="d-block">Aguarde com esta janela aberta!</b>
                      Ao fecha-la, você encerrará o atendimento.
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-sync text-primary"></i>
                    </div>
                    <div className="col">
                      Assim que o profissional de saúde estiver disponível você
                      será redirecionado automaticamento para a teleorientação!
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-microphone text-primary"></i>
                    </div>
                    <div className="col">
                      Quando o sistema solicitar <b>acesso a sua câmera e microfone</b>, por favor, <b>aceite</b> para que a tela orientação possa ocorrer;
                    </div>
                  </div>
                  <div className="text-center pt-4">
                    <button
                      onClick={() => navigation(-1)}
                      className="btn btn-outline-secondary"
                    >
                      <i className="las la-arrow-left me-2"></i>Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Queue;
