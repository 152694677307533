import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, useSensor, useSensors, KeyboardSensor, TouchSensor, MouseSensor } from "@dnd-kit/core";
import ScrollContainer from 'react-indiana-drag-scroll';
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import { createPortal } from "react-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "utils/toast";
import { coordinateGetter } from "./multipleContainersKeyboardPreset";

import Button from "components/Button";
import Input from "components/Input";
import ModalComponent from 'components/ModalComponent';
import ModalTask from "pages/Boards/components/ModalTask";
import ModalPatient from "pages/Boards/components/ModalPatient";
import ModalForward from "pages/Boards/components/ModalForward";
import ModalColumn from "pages/Boards/components/ModalColumn";
import ModalTag from "pages/Boards/components/ModalTag";
import { useDarkMode } from "providers/DarkMode";
import { usePatient } from "providers/Patient";
import { useProfessional } from "providers/Professional";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { Swatch } from '@uiw/react-color';
import { CircularProgress } from "@mui/material";

import { Id, Column, Task } from "types/board";
import { PatientData } from "types/patient";

import ColumnContainer from "./ColumnContainer";
import TaskCard from "./TaskCard";
import { cpfMask } from "utils/formMasks";
import logger from "utils/logger";
import 'react-indiana-drag-scroll/dist/style.css'
import Swal from "sweetalert2";

interface PropsKanbanBoard {
  zoom: any;
  tasks: Task[];
  columns: Column[];
}

function KanbanBoard({
  zoom,
  tasks,
  columns
}: PropsKanbanBoard) {
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [dragStartColumn, setDragStartColumn] = useState<Column | null>(null);
  const [dragEndColumn, setDragEndColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const { professionalSearchData, setProfessionalSearchData } = useProfessional();
  const { patients,
    setPatients,
    patientData,
    setPatientData,
    patientLoading,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();
  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
  } = useBoard();

  const { userData } = useUser();
  const { darkMode } = useDarkMode();

  const columnsId = useMemo(() => columnsData.sort((a, b) => (a.order < b.order ? -1 : 1)).map((col) => col.id), [columnsData]);
  // const columnsId = columnsData.sort((a, b) => (a.order < b.order ? -1 : 1)).map((col) => col.id);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter,
    })
  );

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <ScrollContainer id="scrollParent" style={{ zoom: zoom }} className='kanban-parent' hideScrollbars={false} mouseScroll={{ ignoreElements: '.drag-handle, .dropdown' }}>
          <div className="kanban-container flex-grow-1">
            {columnsData.length <= 0 ? (
              <>
                <div className="kanban-board rounded">
                  <div className="kanban-drag">
                    <div className="row pt-3">
                      <div className="col">
                        <b className="d-block mb-2">Nenhuma coluna definida.</b>
                        <span className="opacity-50">Comece adicionando uma!</span>
                      </div>
                      <div className="col col-auto">
                        <i className="uil uil-arrow-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (<></>)}
            <SortableContext items={columnsId}>
              {columnsData.sort((a, b) => (a.order < b.order ? -1 : 1)).map((col) => (
                <ColumnContainer
                  key={col.id}
                  column={col}
                  tasks={tasks.filter((task) => task.columnId === col.id)}
                />
              ))}
            </SortableContext>
            <div>
              {/* coluna vazia por conta do layout : não apagar esta div */}
            </div>
          </div>
        </ScrollContainer>

        {createPortal(
          <div style={{ zoom: zoom }}>
            <DragOverlay className="dragOverlay" zIndex={1030}>
              {activeColumn && (
                <ColumnContainer
                  column={activeColumn}
                  tasks={tasks.filter(
                    (task) => task.columnId === activeColumn.id
                  )}
                />
              )}
              {activeTask && (
                <TaskCard
                  task={activeTask}
                  setColumnId={setColumnId}
                />
              )}
            </DragOverlay></div>,
          document.getElementById("kanban") ?? document.body
        )}
      </DndContext>

      {/* modal coluna */}
      <ModalComponent
        title={(columnId != "" ? 'Editar' : 'Adicionar') + " Coluna"}
        show={showAddColumnModal}
        size="sm"
        onHide={() => {
          setShowAddColumnModal(false);
          setStopAutoUpdate(false);
        }}
      >
        <ModalColumn onHide={() => {
          setShowAddColumnModal(false);
          setStopAutoUpdate(false);
        }} />
      </ModalComponent>

      {/* modal task */}
      <ModalTask />
    </>
  );

  function onDragStart(event: DragStartEvent) {
    setStopAutoUpdate(true);
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      setDragStartColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      let dragStartColumnId = event.active.data.current.task.columnId;
      let columnKey = Number(Object.keys(columnsData).find((x: any) => columnsData[x].id === dragStartColumnId));
      setDragStartColumn(columnsData[columnKey]);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setStopAutoUpdate(false);
    setActiveColumn(null);
    setActiveTask(null);
    const { active, over, collisions } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    // // // // // // // // CHANGE COLOR // // // // // // // // // // // // // 
    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      if (dragStartColumn && dragEndColumn && dragStartColumn.id != dragEndColumn.id) {
        moveTaskBetweenBoardColumn(boardData.id, dragStartColumn.id, activeId, {
          columnId: dragEndColumn.id
        });
      } else if (dragEndColumn) {
        var updateTasks: Task[] = [];
        tasksData.map((t) => {
          if (t.columnId === dragEndColumn.id) {
            updateTasks.push(t);
          }
        });
        updateTasksOrder(updateTasks);
      }

      // setTasksData((tasksData) => {
      //   const activeIndex = tasksData.findIndex((t) => t.id === activeId);
      //   const overIndex = tasksData.findIndex((t) => t.id === overId);
      //   const overColumnIndex = columnsData.findIndex((c) => c.id === tasksData[overIndex].columnId);
      //   if (columnsData[overColumnIndex]?.color) {
      //     tasksData[activeIndex].color = columnsData[overColumnIndex].color;
      //   } else {
      //     tasksData[activeIndex].color = "";
      //   }
      //   // tasksData.forEach(function(task, index){
      //   //   task.order = index;
      //   // });
      //   if (columnsData[overColumnIndex].type == 3 && dragStartColumn && dragEndColumn && dragStartColumn.id != dragEndColumn.id) {
      //     const task = tasksData[activeIndex];
      //     logger.log("Abrir modal task 1");
      //     if (task) {
      //       handleTaskModal(true, task);
      //     }
      //   }
      //   return tasksData;
      // });

    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {

      if (dragStartColumn && dragEndColumn && dragStartColumn.id != dragEndColumn.id) {
        moveTaskBetweenBoardColumn(boardData.id, dragStartColumn.id, activeId, {
          columnId: dragEndColumn.id
        });
      }

      console.log("editar order");

      // setTasksData((tasks) => {
      //   const activeIndex = tasksData.findIndex((t) => t.id === activeId);
      //   const overIndex = columnsData.findIndex((c) => c.id === overId);
      //   if (columnsData[overIndex]?.color) {
      //     tasks[activeIndex].color = columnsData[overIndex].color;
      //   } else {
      //     tasks[activeIndex].color = "";
      //   }
      //   // tasks.forEach(function(task, index){
      //   //   task.order = index;
      //   // });
      //   if (columnsData[overIndex].type == 3 && dragStartColumn && dragEndColumn && dragStartColumn.id != dragEndColumn.id) {
      //     const task = tasksData[activeIndex];
      //     logger.log("Abrir modal task");
      //     if (task) {
      //       handleTaskModal(true, task);
      //     }
      //   }
      //   return tasks;
      // });
    }
    // // // // // // // // // // // // // // // // // // // // // 

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;

    // moving a column
    const overIndex = columnsData.findIndex((c) => c.id === overId);
    //nao permite mover sobre '1-start' & '2-end'
    if ([1, 2].includes(columnsData[overIndex].type)) {
      // 0 = normal, 1 = start, 2 = end, 3 = appointment
      return;
    }

    const activeColumnIndex = Number(columnsData.findIndex((col) => col.id === activeId));
    const overColumnIndex = Number(columnsData.findIndex((col) => col.id === overId));

    const tempColumns = arrayMove(columnsData, activeColumnIndex, overColumnIndex);
    logger.log(columnsData);
    tempColumns.map((column, index) => {
      // logger.log(column.title, index);
      if ([0, 3].includes(column.type)) {
        column.order = index;
        editColumnFromBoard(boardData.id, column.id, column);
      }
    });

  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event;
    if (!over) return;

    if (over) {
      if (over.data.current?.type === "Column") {
        setDragEndColumn(over.data.current.column);
      }
      if (over.data.current?.type === "Task") {
        let dragEndColumnId = over.data.current.task.columnId;
        let columnKey = Number(Object.keys(columnsData).find((x: any) => columnsData[x].id === dragEndColumnId));
        let columnDrag = columnsData[columnKey];
        setDragEndColumn(columnDrag);
      }
    }

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";
    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over another Task in diff column
    if (isActiveATask && isOverATask) {
      // setOverColumn();

      setTasksData((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);

        if (tasks[activeIndex].columnId != tasks[overIndex].columnId) {
          tasks[activeIndex].columnId = tasks[overIndex].columnId;
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setTasksData((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        tasks[activeIndex].columnId = overId;
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }

    // movendo colunas
    logger.log(columnsData);
  }
}

export default KanbanBoard;
