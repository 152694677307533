import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ButtonGroup, ToggleButton, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import RadioInput from "components/RadioInput";
import { useProfessional } from "providers/Professional";
import { useBoard } from "providers/Board";
import { api } from "services";
import { Professional, ProfessionalSearchData } from "types/professional";
import userIcon from "assets/user.png";
import {
  handleAccountInput,
  handleCepInput,
  handleCnpjInput,
  handleCpfOrCnpjInput,
  handleDateInput,
  handleIeInput,
  handleMobilePhoneInput,
  handlePhoneInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";
import { Id, Task } from "types/board";

interface ModalForwardProps {
  onHide: () => void;
  task: Task
}

const ModalForward = ({ onHide, task }: ModalForwardProps) => {

  const {
    professionalSearchData,
    setProfessionalSearchData,
    searchActiveProfessionals,
    activeProfessionalsFound,
    setActiveProfessionalsFound,
    isLoadingProfessionals,
    setIsLoadingProfessionals } = useProfessional();

  const {
    boards,
    boardData,
    moveTaskBetweenBoards
  } = useBoard();

  const schema = yup.object().shape({
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
  });

  const [radioValue, setRadioValue] = useState(boards.length > 1 ? 'board' : 'professional');
  const [selectedBoard, setSelectedBoard] = useState('' as Id);
  const [selectedObs, setSelectedObs] = useState(true);

  const handleChangeObs = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event);
    setSelectedObs(!selectedObs);
  };

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<ProfessionalSearchData>({
    resolver: yupResolver(schema),
    defaultValues: {
      // priceTable: {
      //   value: company?.priceTable?.id,
      //   label: company?.priceTable?.description,
      // },
      // enableCheckList: company?.enableCheckList,
      // minLimitDateProcedure: company?.minLimitDateProcedure ?? 5,
    },
  });

  const onSubmit = (
    data: ProfessionalSearchData
  ) => {
    // searchActiveProfessionals(data);
  };

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    setActiveProfessionalsFound({} as Professional[]);
    setProfessionalSearchData({} as Professional);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Body className="bg-light">
        {boards.length > 1 && (<>
          <p className="text-center">Para onde deseja encaminhar este paciente?</p>
          <div className="mb-4 text-center">
            <ButtonGroup>
              <ToggleButton
                id={`radio-board`}
                type="radio"
                variant={'outline-primary'}
                name="radio"
                value={'board'}
                checked={radioValue === 'board'}
                style={{ borderRadius: 'var(--bs-border-radius-xxl) 0 0 var(--bs-border-radius-xxl)' }}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                Outro Board
              </ToggleButton>
              <ToggleButton
                id={`radio-professional`}
                type="radio"
                variant={'outline-primary'}
                name="radio"
                value={'professional'}
                checked={radioValue === 'professional'}
                style={{ borderRadius: '0 var(--bs-border-radius-xxl) var(--bs-border-radius-xxl) 0' }}
                onChange={(e) => { setRadioValue(e.currentTarget.value); setSelectedBoard("") }}
              >
                Outro professional
              </ToggleButton>
            </ButtonGroup>
          </div>
        </>)}
        <p className="text-center">Selecione o <b>{radioValue === 'board' ? 'board' : 'profissional'}</b> para qual você deseja encaminhar <br /><b>{task.title}</b>.</p>
        <label className="form-check d-flex justify-content-center form-switch mb-4">
          <input className="form-check-input" type="checkbox" role="switch" onChange={handleChangeObs} defaultChecked={selectedObs} />
          <span className="form-check-label ps-2">
            Encaminhar com suas observações?
          </span>
        </label>
        {professionalSearchData.id ? (
          <div className="bg-white border py-2 px-3 small lh-1 rounded">

            <div className="row g-3 align-items-center">
              <div className="col col-auto">
                <img src={professionalSearchData.image ?? userIcon} alt={professionalSearchData.name} className="avatar rounded-circle" />
              </div>
              <div className="col">
                <span className="fw-semibold d-block mb-1">{professionalSearchData.name}</span>
                {professionalSearchData.specialty && professionalSearchData.specialty.length > 0 && (
                  <small className="opacity-50 fw-semibold d-block">{professionalSearchData.specialty[0].name}</small>
                )}
                <small className="opacity-50">CRM: {professionalSearchData.crm}</small>
              </div>
              <div className="col col-auto">
                <a role="button" className="btn btn-link p-1 text-danger" onClick={() => {
                  setProfessionalSearchData({} as Professional);
                }}>
                  <i className="uil uil-trash"></i>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <>
            {radioValue == 'board' ? (<>
              <div className="list-group mt-2 overflow-auto mb-3" style={{ maxHeight: "30vh" }}>
                {boards.map((board) => {
                  if (board.id !== boardData.id)
                    return <label role="button" key={board.id} className="list-group-item list-group-item-action lh-1 py-3">
                      <div className="row g-0 flex-nowrap align-items-center">
                        <div className="col col-auto">
                          <span className="control control--radio mb-1">
                            <span className="caption">&nbsp;</span>
                            <input name="tipo" type="radio" defaultChecked={board.id == selectedBoard} onChange={() => {
                              setSelectedBoard(board.id);
                              console.log(selectedBoard);
                            }} />
                            <div className="control__indicator"></div>
                          </span>
                        </div>
                        <div className="col">
                          <span className="fw-semibold d-block">{board.name}</span>
                        </div>
                      </div>
                    </label>
                })}
              </div>
            </>) : (<>
              <div className="px-3 mx-n3 mb-3 position-relative">
                <div className="border rounded-3 position-relative">
                  <label htmlFor="search-professional" className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}>
                    {isLoadingProfessionals ? (
                      <CircularProgress
                        color="inherit"
                        size={12}
                      />
                    ) : (
                      <i className="uil uil-search"></i>
                    )}
                  </label>
                  <Input
                    id="search-professional"
                    type="search"
                    placeholder="Buscar Professional por Nome ou CRM"
                    defaultValue={professionalSearchData.name}
                    autoComplete="off"
                    autoFocus
                    className="ps-5 border"
                    register={register("name")}
                    error={errors?.name?.message}
                    onChange={(e) => {
                      setTimeout(() => {
                        setProfessionalSearchData({ "name": e.target.value });
                        if (e.target.value != "") {
                          searchActiveProfessionals({ "name": e.target.value });
                        } else {
                          setActiveProfessionalsFound({} as Professional[]);
                        }
                      }, 300);
                    }}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setTimeout(() => {
                      setIsSearchFocused(false);
                    }, 200)}
                  />
                </div>
                {isSearchFocused && (<>
                  <div className="position-absolute top-100 start-0 end-0 px-3 z-index-1">
                    <div className="list-group mt-2 overflow-auto mb-3 shadow" style={{ maxHeight: "30vh" }}>
                      {activeProfessionalsFound && activeProfessionalsFound.length > 0 ? (<>
                        {activeProfessionalsFound.map((professional) => (
                          <a role="button" key={professional.id} className="list-group-item list-group-item-action small lh-1"
                            onClick={() => {
                              setProfessionalSearchData(professional);
                              console.log(professional);
                            }}>
                            <div className="row g-3 align-items-center">
                              <div className="col col-auto">
                                <img src={professional.image ?? userIcon} alt={professional.name} className="avatar rounded-circle" />
                              </div>
                              <div className="col">
                                <span className="fw-semibold d-block mb-1">{professional.name}</span>
                                {professional.specialty && professional.specialty.length > 0 && (
                                  <small className="opacity-50 fw-semibold d-block">{professional.specialty[0].name}</small>
                                )}
                                <small className="opacity-50">CRM: {professional.crm}</small>
                              </div>
                            </div>
                          </a>
                        ))}
                      </>) : (<>
                        {!isLoadingProfessionals && professionalSearchData && professionalSearchData.name && professionalSearchData.name != "" && (<>
                          <div className="list-group-item text-center small py-4">
                            <p className="mb-0">Nenhum profissional encontrado para <b>{professionalSearchData.name}</b></p>
                          </div>
                        </>)}
                      </>)}
                    </div>
                  </div>
                </>)}
              </div>
            </>)}
            <p className="small text-center">*O paciente permanecerá no board de origem.</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* {((professionalSearchData && professionalSearchData.id) || selectedBoard != "") && ( */}
        <Button
          type="button"
          disabled={isLoadingProfessionals || (!professionalSearchData.id && selectedBoard == "")}
          className="btn-dark rounded-pill"
          onClick={() => {
            moveTaskBetweenBoards(boardData.id, task.columnId, task.id, {
              destinationBoardId: selectedBoard,
              destinationUserId: professionalSearchData?.id,
              sendDescriptionFlag: selectedObs
            }, onHide);
          }}
        >
          Encaminhar<i className="uil uil-arrow-right ms-2"></i>
        </Button>
        {/* )} */}
      </Modal.Footer>
    </form>
  );
};

export default ModalForward;
