import { Navigate, useLocation } from "react-router-dom";
import { Appointment } from "types/patient";
import { useEffect, useRef, useState } from "react";

// $(document).ready(function(){
//   setTimeout(function(){
//       $(".botoes-video").removeClass("active");
//   }, 5000);
// });
  
// function playSound(url) {
//   const audio = new Audio(url);
//   audio.play();
// };

// function alterarclasse(id) {
//   var element = document.getElementById(id);

//   if (element.classList) {
//       element.classList.toggle("d-none");
//   } else {
//       // For IE9
//       var classes = element.className.split(" ");
//       var i = classes.indexOf("d-none");

//       if (i >= 0)
//           classes.splice(i, 1);
//       else
//           classes.push("d-none");
//       element.className = classes.join(" ");
//   }
// }

// function turnOffLocalCamera() {
//   webRTCAdaptor.turnOffLocalCamera();
//   alterarclasse("turnOffLocalCamera");
//   alterarclasse("turnOnLocalCamera");
// }

// function turnOnLocalCamera() {
//   webRTCAdaptor.turnOnLocalCamera();
//   alterarclasse("turnOffLocalCamera");
//   alterarclasse("turnOnLocalCamera");
// }

// function muteLocalMic() {
//   webRTCAdaptor.muteLocalMic();
//   alterarclasse("muteLocalMic");
//   alterarclasse("unmuteLocalMic");
// }

// function unmuteLocalMic() {
//   webRTCAdaptor.unmuteLocalMic();
//   alterarclasse("muteLocalMic");
//   alterarclasse("unmuteLocalMic");
// }


// function telacheia() {
  //   jQuery("body").addClass("full");
  //   alterarclasse("TelaCheia");
  //   alterarclasse("SairTelaCheia");
// }
// function sairtelacheia() {
//   jQuery("body").removeClass("full");
//   alterarclasse("TelaCheia");
//   alterarclasse("SairTelaCheia");
// }

const Call = () => {
  const location = useLocation()
  const localVideoRef = useRef<any>(null)
  const [hasPermission, setHavePermission] = useState(false)
  const [isCamOn, setIsCamOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    if (location.state) {
      startCam()
    }
  }, [])
  
  // Essa condição deve ficar após o useEffect e antes da desestruturação de location.state
  // para não dar erro quando usuário tentar acessar essa página pela url
  if (!location.state) {
    return <Navigate to="/" />
  }
  
  const {specialistDoctor } = location.state as Appointment
  const {gender, name} = specialistDoctor
  
  const startCam = () => {
    setIsCamOn(true);
    navigator.mediaDevices.getUserMedia(
      {
        video: {aspectRatio: 16/9},
        audio: true,
      }
      ).then((stream) => {
          setHavePermission(true)
          let video = localVideoRef.current
          if (video) {
            video.srcObject = stream
          }
      }
    ).catch(() => setHavePermission(false))
  }

  const stopCam = () => {
    setIsCamOn(false);
    let video = localVideoRef.current
      video.srcObject.getVideoTracks()[0].stop();
  }

  const startMic = () => {
    setIsMicOn(true)
  }

  const stopMic = () => {
    setIsMicOn(false)
  }
  
  const reloadVideo = () => {
    window.location.reload()
  }
  
  const toggleScreenSize = () => {
    setIsFullScreen(!isFullScreen)
    document.body.classList.toggle("full")
  }

  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="text-center mb-5">
                  Atendimento
                </h1>

                <div className="card text-body bg-light p-3 px-md-5 py-md-4">
                  <div className="text-center">
                    <h3 className="mb-4 text-primary">{gender === 0 ? "Dra." : "Dr."} {name}</h3>
                  </div>
                  <div className="bg-white border rounded small text-center p-3 mb-5">
                    <small className="mb-2"><b>Atenção!</b> Caso perca a conexão com o profissional recarregue a página e aguarde alguns segundos para que a transmissão seja reiniciada.</small>
                    <small className="mb-0">Para o correto funcionamento da teleconsulta, utilize os navegadores Google Chrome, Firefox ou Edge.</small>
                  </div>
                  <div className="video-screen">
                    <video id="localVideo" ref={localVideoRef} muted={!isMicOn} autoPlay></video>
                    <video id="remoteVideo"></video>
                    <div className="botoes-video d-flex justify-content-center gap-2 active">
                      {isCamOn
                        ? <button className="btn btn-light" id="turnOffLocalCamera" title="Desativar Câmera" onClick={stopCam}><i className="las la-fw la-video"></i></button>
                        : <button className="btn btn-light" id="turnOnLocalCamera" title="Ativar Câmera" onClick={startCam}><i className="las la-fw la-video-slash"></i></button>
                      }

                      {isMicOn 
                        ? <button className="btn btn-light" id="muteLocalMic"  title="Desativar Microfone" onClick={stopMic}><i className="las la-fw la-microphone"></i></button>
                        : <button className="btn btn-light" id="unmuteLocalMic" title="Ativar Microfone" onClick={startMic}><i className="las la-fw la-microphone-slash"></i></button> 
                      }  

                      <button className="btn btn-success" id="RecarregarVideo" title="Recarregar vídeo" onClick={reloadVideo}><i className="las la-fw la-redo-alt"></i></button>

                      {isFullScreen
                        ? <button className="btn btn-light" id="SairTelaCheia" title="Desativar Tela Cheia" onClick={toggleScreenSize}><i className="las la-fw la-compress"></i></button>
                        : <button className="btn btn-light" id="TelaCheia" title="Ativar Tela Cheia" onClick={toggleScreenSize}><i className="las la-fw la-expand"></i></button>
                      }
                    </div>
                  </div>
                  <div className="bg-white border rounded pt-3 px-3 mt-4 text-center">
                    <h6 className="mb-3">Informações de áudio, vídeo e conexão.</h6>
                    <div className="row">
                      <div className="col-6 col-lg-3 px-0 border-top border-end">
                        <div className="border-right py-3">
                          <i className="las la-video text-20"></i>
                          <p className="small pt-2 mb-0">Câmera</p>
                          {hasPermission 
                            ? <span className="small text-success"><i className="las la-check-circle me-1"></i>Ok</span>
                            : <span className="small text-danger"><i className="las la-times-circle me-1"></i>Sem Permissão</span>
                          }
                          </div>
                      </div>
                      <div className="col-6 col-lg-3 px-0 border-top border-lg-end">
                        <div className="border-lg-right py-3">
                          <i className="las la-microphone text-20"></i>
                          <p className="small pt-2 mb-0">Microfone</p>
                          {hasPermission
                            ? <span className="small text-success"><i className="las la-check-circle me-1"></i>Ok</span>
                            : <span className="small text-danger"><i className="las la-times-circle me-1"></i>Sem Permissão</span>
                          }
                        </div>
                      </div>
                      <div className="col-6 col-lg-3 px-0 border-top border-end">
                        <div className="border-right py-3">
                          <i className="las la-volume-up text-20"></i>
                          <p className="small pt-2 mb-0">Fone/Alto Falante</p>
                          <span className="small text-success"><i className="las la-check-circle me-1"></i>OK</span>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3 px-0 border-top">
                        <div className="py-3">
                          <i className="las la-wifi text-20"></i>
                          <p className="small pt-2 mb-0">Velocidade de Conexão</p>
                          <span className="badge bg-secondary">3,56mbs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Call;
