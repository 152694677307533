import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState, useRef } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "moment/locale/pt-br";
import moment from "moment";

// import { Id, Task } from "../types";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, ToTaskForward } from "types/board";
import { useBoard } from "providers/Board";
import { usePatient } from "providers/Patient";
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';
import { useDarkMode } from "providers/DarkMode";
import { numberValue } from "utils/formMasks";
import logger from "utils/logger";
import { color } from "@uiw/react-color";
import Swal from "sweetalert2";
import { PatientData } from "types/patient";
import { Background } from "@xyflow/react";
import { TaskBadges } from "./TaskTabs/TaskBadges";

interface PropsTaskCard {
  task: Task;
  column?: Column;
  setColumnId: (title: any) => void;
}

function TaskCard({
  task,
  column,
  setColumnId,
}: PropsTaskCard) {

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    }
    // disabled: editMode,
  });

  const { darkMode } = useDarkMode();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    tags,
    leadOrigin,
    OTHERS_LEAD_ORIGIN
  } = useBoard();

  var rgbColor = rgbaToRgb(hexToRgba(((!task.color || task.color == "") ? "#FFFFFF" : task.color)));
  var bgColor = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.5`;
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    backgroundColor: (darkMode ? bgColor : task.color)
    // color: (task.color ? getContrastingColor(task.color) : '')
  };

  const checkTaskDuplicates = (id: Id) => {
    var addClass = "";
    if (keepDuplicates && keepDuplicates.length > 0 && !keepDuplicates.includes(task.id)) {
      addClass += " opacity-25 ";
    }

    if (showDuplicates && showDuplicates.length > 0 && !showDuplicates.includes(task.id) && keepDuplicates && keepDuplicates.length === 0) {
      addClass += " opacity-25 ";
    }
    return addClass;
  }

  return (
    <div
      key={task.id}
      id={task.id.toString()}
      ref={setNodeRef}
      style={style}
      className={`${checkTaskDuplicates(task.id)} kanban-item shadow-sm rounded position-relative ${isDragging ? 'opacity-50' : ''} ${task.type === 1 ? ' border-start border-dark border-3' : ''}`}
      onDoubleClick={() => { handleTaskModal(true, task); }}
    >
      <div>
        <div className="row g-0">
          <div className="col col-auto drag-handle"
            {...listeners}
            {...attributes}>
            <i className="uil uil-draggabledots me-2"></i>
          </div>
          <div className="col">
            <p className="m-0 lh-sm text-break">
              {task.title}
            </p>
            {task.description && (<>
              <p className="m-0 small opacity-50 text-break text-limit">
                {task.description}
              </p>
            </>)}
          </div>
          <div className="col col-auto position-relative">
            <Dropdown>
              <Dropdown.Toggle variant="light" size="sm" className="no-arrow p-2 lh-1 rounded-circle position-absolute top-0 end-0 my-n1">
                <i className="uil uil-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align='end'>
                <Dropdown.Item as="button" onClick={() => { handleTaskModal(true, task); }}>
                  <i className="uil uil-edit-alt me-2"></i>Editar
                </Dropdown.Item>
                {task.type === 0 && (
                  <Dropdown.Item as="button" onClick={() => { handleTaskModal(false, task); setShowForwardPatientModal(true); }}>
                    <i className="uil uil-user-arrows me-2"></i>Encaminhar
                  </Dropdown.Item>
                )}
                <Dropdown.Item as="button" className='text-danger' onClick={() => { handleDeleteTask(task.id); }}>
                  <i className="uil uil-trash me-2"></i>Remover
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {(task.taskVal && task.taskVal > 0) ? (
          <div className="row g-0 algin-items-center flex-nowrap mx-n2 px-2 border-icons border-top border-dark mt-2 pt-2 lh-1">
            <div className="col col-auto">
              <i className="uil uil-usd-circle me-2"></i>
            </div>
            <div className="col col-auto small">
              <span className="opacity-50">R$</span> {numberValue(task.taskVal)}
            </div>
          </div>
        ) : (<></>)}
        {task.tags && task.tags.length > 0 && (
          <div className="row g-0 algin-items-center flex-nowrap mx-n2 px-2 border-icons border-top border-dark mt-2 pt-2 lh-1">
            <div className="col col-auto">
              <i className="uil uil-tag-alt me-2"></i>
            </div>
            <div className="col col-auto">
              <div className="d-flex algin-items-center flex-wrap gap-1 lh-1">
                {tags.filter(item1 => task.tags?.some(item2 => item2.id === item1.id)).map((tag: any, index: number) => (
                  <span key={index} className="badge fw-semibold px-1" style={{ background: tag.color, color: getContrastingColor(tag.color) }}>{tag.tagName}</span>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="row g-0 mx-n2 px-2 align-items-end flex-nowrap border-icons border-top border-dark mt-2 pt-2">
          <div className="col lh-1 d-flex gap-1">
            <TaskBadges task={task} />
          </div>
          <div className="col col-auto lh-1">
            <span className="small opacity-50 text-end">
              {moment(task.updatedDate).format("DD/MM/YYYY HH:mm") == moment(task.createdDate).format("DD/MM/YYYY HH:mm") ? (<>
                <small>{moment(task.updatedDate).format("DD/MM/YYYY HH:mm")}</small>
              </>) : (<>
                <OverlayTrigger
                  container={document.body}
                  placement="bottom"
                  overlay={<Tooltip className="small">Criado em:<br />{moment(task.createdDate).format("DD/MM/YYYY HH:mm")}<hr className="my-1" />Modificado em:<br />{moment(task.updatedDate).format("DD/MM/YYYY HH:mm")}</Tooltip>}
                >
                  <small>{moment(task.updatedDate).isSame(moment(), 'day') ? moment(task.updatedDate).fromNow() : moment(task.updatedDate).format("DD/MM/YYYY HH:mm")}</small>
                </OverlayTrigger>
              </>)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskCard;
