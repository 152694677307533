import { useState } from "react";
import VerificationInput from "react-verification-input";
import Swal from "sweetalert2";

import Button from "components/Button";
import WppAttendance from "components/WppAttendance";
import {
  autoCloseValidationMessage,
  pwdRecoverySteps2and3,
  pwdRecoveryStep4,
} from "pages/Login/utils/pwdRecoveryPopUp";
import { api } from "services/index";
import { PwdRecoveryStepOneResult } from "types/auth";

interface CodeValidationFormProps {
  emailOrCpf: string;
  inputOption: string;
  resultStepOne: PwdRecoveryStepOneResult;
}

const CodeValidationForm = ({
  emailOrCpf,
  inputOption,
  resultStepOne,
}: CodeValidationFormProps) => {
  const [code, setCode] = useState<string>("");
  const [wasResent, setWasResent] = useState<boolean>(false);
  const codeLength = 8;

  const handleOnChange = (res: string) => {
    setCode(res);
  };

  const validateCode = async () => {
    Swal.resetValidationMessage();

    if (code.length === 8) {
      Swal.showLoading();
      try {
        await api.post("/Auth/recoverycodevalidate", {
          code,
          emailOrCpf,
        });
        Swal.hideLoading();

        // Step 4 - New Password
        pwdRecoveryStep4(code, emailOrCpf);
      } catch (error) {
        Swal.hideLoading();
        autoCloseValidationMessage(
          "Código inválido. Verifique se o código foi digitado corretamente.",
          7000
        );
      }
    } else {
      autoCloseValidationMessage(
        "Por favor, preencha os campos de modo correto.",
        6000
      );
    }
  };

  const resendCode = async () => {
    Swal.showLoading();

    try {
      await api.post(`/Auth/recoveryBy${inputOption}`, resultStepOne.request);
      Swal.hideLoading();
      setWasResent(true);
      setTimeout(() => {
        setWasResent(false);
      }, 4000);
    } catch (error) {
      Swal.hideLoading();
      autoCloseValidationMessage("Ocorreu um erro, tente mais tarde", 4000);
    }
  };

  const backToStep2 = () => {
    pwdRecoverySteps2and3(emailOrCpf, <WppAttendance />, resultStepOne);
  };

  return (
    <div>
      <p className="mb-0">
        Enviamos um código de verificação para o {inputOption === "Email" ? `e-mail:` : `telefone:`}
      </p>
      <p className="fw-bold">
        {inputOption === "Email" ? resultStepOne.response.email : resultStepOne.response.phone}
      </p>

      <div className="d-flex justify-content-between align-items-end mb-2">
        <span className="d-inline-block text-start">Insira o código:</span>
      </div>

      <VerificationInput
        onChange={handleOnChange}
        length={codeLength}
        placeholder="-"
        autoFocus
        removeDefaultStyles
        classNames={{
          container: "gap-1 gap-md-3 mb-4",
          character:
            "character border rounded-1 text-primary fw-bolder text-uppercase fs-2",
          characterInactive: "bg-secondary opacity-50 border-dark text-muted",
          characterSelected: "border-primary",
        }}
      />

      <Button type="button" className="btn btn-primary rounded-pill" onClick={validateCode}>
        Validar código
      </Button>


      <div className="d-flex justify-content-between mt-4">
        <button
          className="btn btn-link p-0 text-sm-end text-decoration-none fw-normal fs-6"
          onClick={resendCode}
        >
          <small>Reenviar código</small>
        </button>
        <button
          className="btn btn-link p-0 text-sm-end text-decoration-none fw-normal fs-6"
          onClick={backToStep2}
        >
          <small>Tentar de outro jeito</small>
        </button>
      </div>

      {wasResent && (
        <div className="swal2-validation-message animate__animated animate__fadeInDown">
          <i className="uil uil-check-circle me-1 text-success fs-1" />
          Código reenviado!
        </div>
      )}
    </div>
  );
};

export default CodeValidationForm;
