import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "providers/Auth";
import { useTutorial } from "providers/Tutorial";
import Footer from "components/Footer";
import Header from "components/Header";
import Joyride, { ACTIONS, CallBackProps, EVENTS, Events, STATUS, Step } from 'react-joyride';
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import ModalComponent from 'components/ModalComponent';
import { ErrorBoundary } from "react-error-boundary";
import { isDev } from 'utils/isDev';

const Site = () => {
  const { pathname } = useLocation();
  const { token } = useAuth();
  const navigate = useNavigate();
  const { steps, setSteps, stepIndex, setStepIndex, run, setRun, tourActive, setTourActive } = useTutorial();
  const handleTutorial = (data: CallBackProps) => {
    console.log(data);
    const {
      status,
      action,
      index,
      step,
      type,
    } = data;
    const isPreviousAction = action === 'prev';
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      setRun(false);
      setTourActive(false);
      setStepIndex(0);
      return;
    }
    if (type === 'step:after' && step.data) {
      var next = step.data.next ?? false;
      var previous = step.data.previous ?? false;
      if (next || previous) {
        navigate(isPreviousAction && previous ? previous : next);
        setStepIndex(nextStepIndex);
      }
    }

  };

  const fallbackRender = ({ error, resetErrorBoundary }: any) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <ModalComponent
        title="Ops... aconteceu um erro inesperado."
        show={true}
        size="sm"
        hasCloseButton={false}
      >
        <Modal.Body className="bg-light">
          <div className="mb-3 text-danger">{error.message}</div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <p className="mb-0 small text-center"><b className="d-block mb-2">Pedimos desculpas pelo transtorno.</b>Caso o erro persista em ocorrer, entre em contato com nossa equipe.</p>
          <a role="button" className="btn btn-primary rounded-pill" onClick={()=>{window.location.reload();}}>Recarregar a página</a>
        </Modal.Footer>
      </ModalComponent>
    );
  }

  const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API
    if(isDev()){
      console.error(error, info);
    }
  };

  return (
    <>
      <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
          <Outlet />
      </ErrorBoundary>
    </>
  );
};

export default Site;
