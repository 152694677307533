import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppointmentInterface } from "types/patient";

const CallInfo = () => {
  const navigation = useNavigate();
  const location = useLocation()

  // Essa condição deve ficar antes da desestrutuação de location.state
  // para não dar erro quando usuário tentar acessar essa página pela url
  if (!location.state) {
    return <Navigate to="/" />
  }

  const { appointment } = location.state as AppointmentInterface

  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h1 className="text-center mb-5">Atendimento</h1>

                <div className="card text-body bg-light p-3 px-md-5 py-md-4">
                  <div className="text-center">
                    <h3 className="mb-2">
                      Olá, <span className="text-primary">{appointment.patient.name}</span>
                    </h3>
                    <p className="mb-4 text-muted">
                      ORIENTAÇÕES PARA O SUCESSO NO ATENDIMENTO
                    </p>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-window-close text-primary"></i>
                    </div>
                    <div className="col">
                      <b>Feche todos os aplicativos abertos</b> em seu smartphone, tablet ou computador, inclusive Whats App, mantendo apenas o seu navegador aberto na página do atendimento;
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-volume-up text-primary"></i>
                    </div>
                    <div className="col">
                      <b>Aumente o volume</b> do seu smartphone, tablet ou computador;
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 mb-0 lab la-fw la-bluetooth text-primary"></i>
                    </div>
                    <div className="col">
                      <b>Desconecte dispositivos bluetooth</b>;
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-wifi text-primary"></i>
                    </div>
                    <div className="col">
                      Sempre que possível, <b>conecte-se a uma rede Wifi</b> para garantir a qualidade de seu atendimento;
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-microphone text-primary"></i>
                    </div>
                    <div className="col">
                      Quando o sistema solicitar <b>acesso a sua câmera e microfone</b>, por favor, <b>aceite</b> o mais breve possível; o atendimento será realizado após este passo;
                    </div>
                  </div>
                  <div className="row align-items-center py-3">
                    <div className="col col-auto">
                      <i className="h1 fw-bold mb-0 las la-fw la-exclamation-triangle text-primary"></i>
                    </div>
                    <div className="col">
                      Por fim, se em algum momento a <b>conexão falhar</b>, acesse a plataforma e <b>solicite um novo atendimento</b>.
                    </div>
                  </div>
                  <div className="text-center pt-4">
                    <button
                      onClick={() => navigation("/fila", { state: { appointment } })}
                      className="btn btn-lg btn-primary mt-3"
                    >
                      <i className="fas fa-video mr-2"></i>Iniciar Teleconsulta
                    </button>
                    <br />
                    <button
                      onClick={() => navigation(-1)}
                      className="btn btn-outline-secondary mt-4"
                    >
                      <i className="las la-arrow-left me-2"></i>Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallInfo;
