import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useVersionNotes } from "providers/VersionNotes";

interface VersionNotesContent {
  title: string,
  description: string,
}
interface VersionNotes {
  version: string,
  content: VersionNotesContent[],
}

const Notes = () => {
  useEffect(() => { document.title = 'Notas de Versão | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const navigate = useNavigate();
  const { versionNotes } = useVersionNotes();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
  }, [token]);

  return (

    <ContainerAnimation className="content">
      <div id="top" className="d-print-none">
        <div className="container">
          <div className="row justify-content-md-between align-items-center gy-2 gx-2">
            <div className="col">
              <h1 className="mb-0 fw-semibold lh-1">Notas de Versão</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {versionNotes.map((v:VersionNotes, index:number) => (
          <div key={index} className="mb-4">
            <h5 className="ps-3 fw-bold">v.{v.version}</h5>
            <div className="card border mb-2">
              <ul className="list-group list-group-flush">
                {v.content.map((item, k) => (
                  <li key={k} className="list-group-item p-3">
                    {item.title && (
                      <h6 className={`${(!item.description || item.description == "") ? 'mb-0' : ''} d-flex`} dangerouslySetInnerHTML={{ __html: item.title }}></h6>
                    )}
                    {item.description && (
                      <div dangerouslySetInnerHTML={{ __html: item.description }} className="text-muted ps-4" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

    </ContainerAnimation>
  );
};

export default Notes;
