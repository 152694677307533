import axios, { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import { createContext, ReactNode, useContext, useState } from "react";
import { NavigateFunction } from "react-router-dom";

import { api } from "services";
import { UserLoginData } from "types/auth";
import { RoleDescription } from "types/enums";
import { sessStorage } from "utils/storage";
import { toast } from "utils/toast";

import { API_URL } from "../../config";
import { appBarClasses } from "@mui/material";
import logger from "utils/logger";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthProviderData {
  token: string;
  userId: string;
  userRole: string;
  isLoading: boolean;
  login: (data: UserLoginData, navigate: NavigateFunction) => void;
  tokenLogin: (token: string, navigate: NavigateFunction) => void;
  logout: () => Promise<void>;
  refreshToken: (id: string) => Promise<void>;
  loginQuick: (id: number, tempToken: string) => Promise<string>;
}

const roleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
const defaultRole = "Admin";
interface JwtDecoded {
  sid: string;
  nome: string;
  [roleKey]: string;
  exp: number;
  iss: string;
  aud: string;
}

interface AbaLogin {
  ativo : boolean;
  idMultiAgendaAbasConfig: number;
  idPaciente: any;
  nomeAba: string;
}

export const AuthContext = createContext<AuthProviderData>(
  {} as AuthProviderData
);

export const AuthProvider = ({ children }: AuthProviderProps) => {

  const [token, setToken] = useState<string>(sessStorage.getToken() || "");
  const [userId, setUserId] = useState<string>(sessStorage.getUserId() || "");

  // const [profileId, setProfileId] = useState<string>(sessStorage.getProfileId() || "");

  const [userRole, setUserRole] = useState<string>(() => {
    if (token) {
      const decoded: JwtDecoded = jwt_decode(token);
      return defaultRole;
      // return decoded[roleKey];
    }
    return "";
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = async (data: UserLoginData, navigate: NavigateFunction) => {
    setIsLoading(true);

    const { email, password } = data;
    const body = { email, password };

    try {
      const response = await api.post("/SpecialistDoctor/login", body);

      var allowLogin = false;
      
      logger.log(response.data);
      if(response.data.abas){
        response.data.abas.forEach((aba: AbaLogin) => {
          if(aba.nomeAba == "CRM" && aba.ativo === true){
            allowLogin = true;
          }
        });
      }

      if(!allowLogin){
        toast.fire({
          icon: "error",
          title: "Profissional não autorizado!",
        });
        return;
      }
        
      const decoded: JwtDecoded = jwt_decode(response.data.token);
      setToken(response.data.token);
      setUserId(decoded.sid);
      // setUserRole(decoded[roleKey]);
      setUserRole(defaultRole);

      sessStorage.setToken(response.data.token);
      sessStorage.setUserId(Number(decoded.sid));
      // sessStorage.setUserRole(decoded[roleKey]);
      sessStorage.setUserRole(defaultRole);

      toast.fire({
        icon: "success",
        title: "Login realizado com sucesso!",
      });

      navigate("/");
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 404) {
        message = !!error.response.data.title
          ? "Usuário ou senha inválidos. Verifique seus dados e tente novamente."
          : error.response.data;
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }finally {
      setIsLoading(false);
    }
  };

  const loginQuick = async (id: number, tempToken: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post( 
        `${API_URL}SpecialistDoctor/loginQuick/${id}`,
        { },
        {headers: { "auth-key": `${tempToken}` }}
      )
      setIsLoading(false);

      var allowLogin = false;
      logger.log(response.data);
      if(response.data.abas){
        response.data.abas.forEach((aba: AbaLogin) => {
          if(aba.nomeAba == "CRM" && aba.ativo === true){
            allowLogin = true;
          }
        });
      }

      if(!allowLogin){
        toast.fire({
          icon: "error",
          title: "Profissional não autorizado!",
        });
        return;
      }

      if(response && response.data.token){
        return response.data.token;
      }
      
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  
  const tokenLogin = async (token: string, navigate: NavigateFunction) => {
    setIsLoading(true);
    const decoded: JwtDecoded = jwt_decode(token);
    
    setToken(token);
    setUserId(decoded.sid);
    // setUserRole(decoded[roleKey]);
    setUserRole(defaultRole);

    sessStorage.setToken(token);
    sessStorage.setUserId(Number(decoded.sid));
    sessStorage.setUserRole(defaultRole);
    // sessStorage.setUserRole(decoded[roleKey]);

    setIsLoading(false);
    navigate("/boards");
  };

  const refreshToken = async (id: string) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}Auth/refreshToken/${id}`);
      const decoded: JwtDecoded = jwt_decode(response.data.token);

      setToken(response.data.token);
      setUserId(decoded.sid);
      setUserRole(defaultRole);
      // setUserRole(decoded[roleKey]);

      sessStorage.setToken(response.data.token);
      sessStorage.setUserId(Number(decoded.sid));
      sessStorage.setUserRole(defaultRole);
      // sessStorage.setUserRole(decoded[roleKey]);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 404) {
        message = !!error.response.data.title
          ? "Usuário ou senha inválidos. Verifique seus dados e tente novamente."
          : error.response.data;
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  const logout = async () => {
    
    setToken("");
    setUserId("");
    // setProfileId("");
    setUserRole("");

    sessionStorage.clear();

    // try {
    //   const response: AxiosResponse = await api.post("/Auth/logout");
    //   // const response: AxiosResponse = await api.post("/Patient/logout");
    //   toast.fire({
    //     icon: "success",
    //     title:
    //       response.status !== 203
    //         ? response.data
    //         : "Logout efetuado com sucesso",
    //   });

    //   setToken("");
    //   setUserId("");
    //   // setProfileId("");
    //   setUserRole("");

    //   sessionStorage.clear();

    // } catch (error) {
    //   toast.fire({
    //     icon: "error",
    //     title: "Ocorreu um erro, tente novamente.",
    //   });
    // }
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        userId,
        // profileId,
        userRole,
        isLoading,
        login,
        tokenLogin,
        logout,
        refreshToken,
        loginQuick
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
