import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import ContainerAnimation from "components/ContainerAnimation";
import 'animate.css';
import { useForm } from "react-hook-form";

// Components
import Button from "components/Button";
import Input from "components/Input";
import KanbanBoard from "./components/KanbanBoard";
import ModalComponent from 'components/ModalComponent';
import Swal from "sweetalert2";
import SelectTag, { SelectOptions } from 'components/SelectTag';
// import { Board, Column, Task } from "./types";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, Tag } from "types/board";
import { toast } from "utils/toast";
import logger from "utils/logger";
import boradsImg from "assets/boards.png";
import moment from "moment";
import "./utils/kanban.css";

export default function Boards() {
  useEffect(() => { document.title = 'Boards | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const {
    boards,
    getBoards,
    setBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    columnsData,
    setColumnsData,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    addColumnToBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    delColumnFromBoard,
    editColumnFromBoard,
    tags,
    filterTags, 
    setFilterTags,
    getTagsFromBoard, 
    handleTagChange
  } = useBoard();

  const defaultCols: ColumnDefault[] = [
    {
      title: "Leads",
      type: 1,
      order: 0
    },
    {
      title: "Consulta",
      color: "#ecefaf",
      type: 3,
      order: 1
    },
    {
      title: "Cirurgia",
      type: 0,
      order: 2
    },
    {
      title: "Pós Cirurgia",
      color: "#ffe5d0",
      type: 0,
      order: 3
    },
    {
      title: "Fim do Processo",
      color: "#c1e1c5",
      type: 2,
      order: 999999
    },
  ];

  const [editBoardData, setEditBoardData] = useState({} as Board);
  const [showAddBoardModal, setShowAddBoardModal] = useState(false);
  const [kanbanZoom, setKanbanZoom] = useState((sessionStorage.getItem(`kanbanZoom`) ? Number(sessionStorage.getItem(`kanbanZoom`)) : 1));

  const handleBoardName = (name: string) => {
    if (name.trim() != "") {
      setEditBoardData({ ...editBoardData, name: name });
    }
  }
  const handleDeleteBoard = (id: Id) => {
    Swal.fire({
      title: "Tem certeza que deseja remover este board?",
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, excluir",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          title: "Esta ação é irreversível, deseja mesmo continuar?",
          icon: "question",
          iconColor: 'var(--bs-danger)',
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "Sim, excluir",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          reverseButtons: true,
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            confirmButton: "btn btn-outline-light",
            icon: "text-white border-white",
            cancelButton: "btn btn-light",
            popup: "bg-danger text-white"
          },
        }).then((res) => {
          if (res.isConfirmed) {
            deleteBoard(id);
          }
        });
      }
    });
  }

  const zoom = (type: string) => {
    var newZoom = 1;
    if (type == "in") {
      newZoom = kanbanZoom + 0.2;
      if (newZoom > 1) {
        newZoom = 1;
      }
    } else if (type == "out") {
      newZoom = kanbanZoom - 0.2;
      if (newZoom <= 0.2) {
        newZoom = kanbanZoom;
      }
    }
    setKanbanZoom(newZoom);
    sessionStorage.setItem(`kanbanZoom`, newZoom.toString());
  };

  const handleTabClick = (id: Id) => {
    logger.log(id);
    getBoardById(id);
    sessionStorage.setItem(`crm-board`, id.toString());
    setSelectedNavTab(id);
  };

  const updateRef = useRef<number | NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);
  const autoUpdateFunction = () => {
    console.log("AUTOUPDATE");
    getTasksByBoard(selectedNavTab);
  }

  const [selectedNavTab, setSelectedNavTab] = useState(sessionStorage.getItem(`crm-board`) ?? "" as Id);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (token && userData && userData.name) {
      getBoards();
    }
  }, [token, userData]);

  useEffect(() => {
    if (isLoadingBoards) {
      return;
    }
    if (boards.length > 0) {
      logger.log(boards);
      if (selectedNavTab != "" && boards.some(item => item.id === selectedNavTab)) {
        logger.log("Get tab Board: " + selectedNavTab);
        getBoardById(selectedNavTab);
      } else {
        logger.log("Get first Board: " + boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))[0].id);
        getBoardById(boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault))[0].id);
      }
      return;
    }
  }, [boards]);

  useEffect(() => {
    if (boardData.id && boardData.columns) {
      setBoardDefault(boardData.isDefault);
      logger.log("Atualizou boardData.");
      getTagsFromBoard(boardData);
      if (boardData.columns.length <= 0) {
        logger.log("Criando Columns Default.");
        (async () => {
          try {
            // Criar um array de promessas de criação
            const createPromises = defaultCols.map((column, index) => {
              return addColumnToBoard(boardData.id, column, true);
            });
            // Aguardar que todas as promessas sejam resolvidas
            await Promise.all(createPromises);
            console.log("finalizou");
            // Executar a função após todas as colunas serem deletadas
            getBoardById(boardData.id);
          } catch (error: any) {
            console.error(error);
          }
        })();
      } else {
        logger.log("Set Board Columns.", boardData.columns);
        setColumnsData(boardData.columns);
      }
    }
  }, [boardData]);

  const [reminders, setReminders] = useState({} as Task[]);
  useEffect(() => {
    logger.log("atualizou as Tasks");
    handleSearchChange(searchTerm);

    // Função para verificar o tempo faltante para cada agendamento
    const checkReminders = () => {
      const nowPlusFiveMinutes = moment().add(5, 'minutes');
      const newReminders = tasksData.filter((task) => {
        if (task.appointment?.date) {
          const taskTime = moment(task?.appointment.date);
          return taskTime.isBetween(moment(), nowPlusFiveMinutes);
        }
      });
      // Verifica se há uma diferença entre os lembretes anteriores e os novos
      if (JSON.stringify(newReminders) !== JSON.stringify(reminders)) {
        setReminders(newReminders);
      }
    };

    // Intervalo de 1 minuto para verificar os agendamentos
    const intervalReminders = setInterval(checkReminders, 1000);

    // Limpeza do intervalo ao desmontar o componente
    return () => clearInterval(intervalReminders);
  }, [tasksData, reminders]);

  const handleReminderClick = useCallback((event: Event) => {
    const target = (event.target as HTMLElement).closest('li');
    if (target) {
      const itemId = target.dataset.id;
      if (itemId) {
        const element = document.getElementById(itemId);
        if (element) {
          element.classList.add('animate__animated', 'animate__flash');
          element.addEventListener('animationend', () => {
            element.classList.remove('animate__animated', 'animate__flash');
          }, { once: true });
          element.scrollIntoView({
            behavior: 'smooth', // Faz o scroll de forma suave
            block: 'center',    // Centraliza verticalmente
            inline: 'center'    // Centraliza horizontalmente
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log(reminders);
    if (reminders.length > 0) {
      var textReminders = `<ul id="tasks-reminder" class='mb-0 ms-n2'>`;
      reminders.map((task) => {
        console.log(task);
        textReminders += `<li role="button" class="p-2 rounded-3" data-id="${task.id}">
                            ${task.title}
                            <small class="opacity-50 d-block"><small>${moment(task.appointment?.date).format("HH:mm")}</small></small>
                          </li>`;
      });
      textReminders += `</ul>`;
      toast.fire({
        icon: "warning",
        title: "Em alguns minutos:",
        html: textReminders,
        timer: undefined,
        position: 'top-end',
        didOpen: () => {
          // Adiciona o eventListener ao container <ul> após o toast abrir
          const reminderList = document.querySelector('#tasks-reminder');
          if (reminderList) {
            reminderList.addEventListener('click', handleReminderClick);
          }
        },
        didClose: () => {
          // Cleanup do event listener
          const reminderList = document.querySelector('#tasks-reminder');
          if (reminderList) {
            reminderList.removeEventListener('click', handleReminderClick);
          }
        }
      });
    }
  }, [reminders]);

  useEffect(() => {
    const handleMouseMove = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (updateRef.current) {
        clearInterval(updateRef.current);
        updateRef.current = null;
      }

      setAutoUpdate(false);

      if (!stopAutoUpdate) {
        timeoutRef.current = setTimeout(() => {
          setAutoUpdate(true);
        }, 60000);
      }
    };

    if (selectedNavTab !== "") {
      document.addEventListener('mousemove', handleMouseMove);
    }
  }, [selectedNavTab]);

  useEffect(() => {
    if (!stopAutoUpdate && autoUpdate && selectedNavTab !== "") {
      updateRef.current = setInterval(() => {
        autoUpdateFunction();
      }, 60000);
    }

    // Limpar intervalo se autoUpdate for falso
    return () => {
      if (updateRef.current) {
        clearInterval(updateRef.current);
        updateRef.current = null;
      }
    };
  }, [autoUpdate, stopAutoUpdate, selectedNavTab]);

  const [boardDefault, setBoardDefault] = useState(false);
  const handleChangeBoardDefault = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBoardDefault(event.target.checked);
  };

  const handleExportData = (boardData: any) => {
    Swal.fire({
      title: "Como deseja exportar o board?",
      icon: "question",
      iconColor: 'var(--bs-dark)',
      position: "center",
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: "<i class='uil uil-grids me-2'></i>Apenas Estrutura",
      showCancelButton: false,
      showDenyButton: true,
      denyButtonText: "<i class='uil uil-users-alt me-2'></i>Estrutura e Dados",
      buttonsStyling: false,
      // reverseButtons: true,
      allowOutsideClick: true,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-dark",
        denyButton: "btn btn-dark",
      },
    }).then((res) => {
      const { author, createdDate, updatedDate, isDefault, ...newObject } = boardData;
      // apenas estrutura
      if (res.isConfirmed) {
        newObject.columns = newObject.columns.map((column: Column) => {
          const { id, author, createdDate, updatedDate, ...rest } = column;  // Remover `author..`
          return {
            ...rest,  // Copiar o restante das propriedades
            tasks: [] // Definir `tasks` como um array vazio
          };
        });
        exportData(newObject);
        // estrutura e dados
      } else if (res.isDenied) {
        newObject.columns = newObject.columns.map((column: Column) => {
          const { id, author, createdDate, updatedDate, ...rest } = column;  // Remover `author..`
          return { ...rest };
        });
        exportData(newObject);
      }
    });
  }

  const exportData = (boardData: any) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(boardData)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${boardData.name}.json`;
    link.click();
    link.remove();
  };

  // upload de board
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const jsonFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return; // Verifica se há arquivos

    const file = files[0];
    if (!file.name.endsWith(".json")) { // Verifica se é um arquivo .json
      toast.fire({
        icon: "error",
        title: "Por favor, selecione um arquivo <b>.json</b>.",
      });
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");

    fileReader.onload = (e) => {
      const result = e.target?.result; // Verifica se 'result' não é nulo

      if (typeof result === "string") { // Garante que 'result' é uma string
        try {
          const newData = JSON.parse(result); // Tenta fazer o parse do JSON
          console.log("Json Data", newData); // Exibe os dados no console
          if (!newData.columns || newData.columns.length <= 0) {
            toast.fire({
              icon: "error",
              title: "Nenhuma coluna encontrada no arquivo enviado.",
            });
          }
          // verifica se o board atual tem colunas
          if (columnsData.length <= 0) {
            // importa os dados
            importData(newData);
          } else {
            Swal.fire({
              title: "Deseja sobrescrever os dados do board atual?",
              icon: "question",
              iconColor: 'var(--bs-danger)',
              position: "center",
              showCloseButton: true,
              showConfirmButton: true,
              confirmButtonText: "Sim, sobrescrever",
              showCancelButton: false,
              showDenyButton: true,
              denyButtonText: "Cancelar",
              buttonsStyling: false,
              reverseButtons: true,
              allowOutsideClick: true,
              focusConfirm: false,
              customClass: {
                confirmButton: "btn btn-outline-danger",
                denyButton: "btn btn-danger",
              },
            }).then((res) => {
              if (res.isConfirmed) {
                // Aguardar que todas as promessas sejam resolvidas
                (async () => {
                  try {
                    // Criar um array de promessas de exclusão
                    const deletePromises = columnsData.map((col) => {
                      return delColumnFromBoard(boardData.id, col.id);
                    });

                    // Aguardar que todas as promessas sejam resolvidas
                    await Promise.all(deletePromises);

                    // Executar a função após todas as colunas serem deletadas
                    importData(newData);

                  } catch (error: any) {
                    console.error(error);
                  }
                })();
              }
            });
          }
        } catch (error) {
          toast.fire({
            icon: "error",
            title: "Verifique a estrutura do seu arquivo e tente novamente.",
          });
          console.error("Erro ao fazer o parse do arquivo JSON:", error);
        }
      } else {
        toast.fire({
          icon: "error",
          title: "Falha ao ler o arquivo.",
        });
        console.error("Falha ao ler o arquivo como texto.");
      }
      // Limpa o valor do input após o upload
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    };

    fileReader.onerror = () => {
      toast.fire({
        icon: "error",
        title: "Erro ao carregar o arquivo.",
      });
      console.error("Erro ao carregar o arquivo.");
      // Limpa o valor do input após o upload
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    };

    const importData = (newData: any) => {
      (async () => {
        try {
          // Processar as colunas sequencialmente com Promise.all
          await Promise.all(newData.columns.map(async (col: Column) => {
            var newColumn = await addColumnToBoard(boardData.id, col, true);

            if (newColumn && newColumn.id && col.tasks && col.tasks.length > 0) {
              var columnId = newColumn.id;

              // Processar as tasks na coluna com Promise.all
              await Promise.all(col.tasks.map(async (task: Task) => {
                try {
                  await addTaskToBoardColumn(boardData.id, columnId, task);
                } catch (error: any) {
                  console.error(error);
                }
              }));
            }
          }));

          // Após todas as colunas e tasks terem sido processadas
          getBoardById(boardData.id);
        } catch (error: any) {
          console.error(error);
        }
      })();
    }
  };

  const {
    control,
  } = useForm();

  useEffect(() => {
    handleSearchChange(searchTerm);
  }, [filterTags]);

  return (
    <ContainerAnimation className="content">
      <div id="top">
        <div className="container">
          <div className="row justify-content-md-between align-items-center gy-2 gx-2">
            <div className="col order-0">
              <h1 className="mb-0 fw-semibold lh-1">Boards</h1>
            </div>
            {(boardData && boardData.id) ? (<>
              <div className="col-12 col-md-6 col-lg order-5 order-lg-1">
                <div className="border rounded-3 position-relative">
                  <span className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}><i className="uil uil-search"></i></span>
                  <Input
                    type="search"
                    placeholder="Busca por Nome / Título"
                    onChange={(e) => { handleSearchChange(e.target.value); }}
                    className="ps-5 border"
                    defaultValue={searchTerm}
                  />
                </div>
              </div>
              {filterTags.length > 0 && (
                <div className="col-12 col-md-6 col-lg order-4 order-lg-2 position-relative z-2">
                  <SelectTag
                    control={control}
                    name="tags"
                    // defaultValue={tags.filter(item1 => editTaskData.tags?.some(item2 => item2.id === item1.id)).map((item: any) => ({ value: item.id, label: item.tagName, color: item.color }))}
                    isMulti
                    placeholder="Filtrar por TAG"
                    options={filterTags.map((tag) => ({
                      value: tag.id,
                      label: tag.tagName,
                      color: tag.color
                    }))}
                    handleChange={(e) => { handleTagChange(e); }}
                    closeMenuOnSelect={false}
                  />
                </div>
              )}
              <div className="col col-auto order-2">
                <Dropdown>
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip>Configurações do Board</Tooltip>}
                  >
                    <Dropdown.Toggle variant="outline-dark" className="no-arrow rounded-circle">
                      <i className="uil uil-cog"></i>
                    </Dropdown.Toggle>
                  </OverlayTrigger>
                  <Dropdown.Menu align='end'>
                    <Dropdown.Item as="button" onClick={() => {
                      setShowAddBoardModal(true);
                      setStopAutoUpdate(true);
                      setEditBoardData(boardData);
                      setBoardDefault(boardData.isDefault);
                    }}>
                      <i className="uil uil-edit-alt me-2"></i>Editar Board
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => { navigate('qr-code'); }}>
                      <i className="uil uil-qrcode-scan me-2"></i>Ver Qr-Code
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => { handleExportData(boardData); }}>
                      <i className="uil uil-file-export me-2"></i>Exportar Board
                    </Dropdown.Item>
                    <Dropdown.Item as="button" onClick={handleUploadClick}>
                      <i className="uil uil-file-import me-2"></i>Importar Board
                    </Dropdown.Item>
                    <input ref={hiddenFileInput} type="file" accept="application/json" hidden onChange={jsonFileUpload} />
                    <Dropdown.Item as="button" className='text-danger' onClick={() => { handleDeleteBoard(boardData.id); }}>
                      <i className="uil uil-trash me-2"></i>Excluir Board
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </>) : (<>
              <div className="col col-auto order-2">
                <a className="btn btn-outline-primary rounded-pill"
                  onClick={() => {
                    setShowAddBoardModal(true);
                    setStopAutoUpdate(true);
                    setEditBoardData({} as Board);
                    setBoardDefault(boards.length <= 0 ? true : false);
                  }}>Criar Board</a>
              </div>
            </>)}
            <div className="col col-auto order-3">
              <div className="btn-group">
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip>Diminuir Zoom</Tooltip>}
                >
                  <a className={`btn btn-outline-secondary mr-2`} style={{ borderRadius: 'var(--bs-border-radius-xxl) 0 0 var(--bs-border-radius-xxl)' }} onClick={() => {
                    zoom("out");
                  }}><i className="uil uil-search-minus"></i></a>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip>Aumentar Zoom</Tooltip>}
                >
                  <a className={`btn btn-outline-secondary mr-2 ${kanbanZoom >= 1 ? 'disabled' : ''}`} style={{ borderRadius: '0 var(--bs-border-radius-xxl) var(--bs-border-radius-xxl) 0' }} onClick={() => {
                    zoom("in");
                  }}><i className="uil uil-search-plus"></i></a>
                </OverlayTrigger>
              </div>
            </div>
            <div className="w-100 order-3 d-lg-none"></div>
          </div>
          <ul className="nav nav-tabs scrollable pt-3">
            {(boards && boards.length > 0) && (<>
              {boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault)).map((b, index) => (
                <li key={b.id} className="nav-item border-bottom">
                  <button
                    type="button"
                    className={`nav-link link-primary ${(boardData.id === (b.id).toString()) ? "active" : (selectedNavTab == "" && index === 0) ? "active" : ""
                      }`}
                    onClick={() => { handleTabClick(b.id) }}
                  >
                    {b.isDefault && (<i className="uil uil-star text-warning me-2 small"></i>)}{b.name}
                  </button>
                </li>
              ))}
              <OverlayTrigger
                placement="top-start"
                overlay={<Tooltip>Adicionar Board</Tooltip>}
              >
                <li className="position-sticky end-0 bg-body">
                  <button
                    type="button" className={`nav-link link-primary`}
                    onClick={() => {
                      setShowAddBoardModal(true);
                      setStopAutoUpdate(true);
                      setEditBoardData({} as Board);
                      setBoardDefault(boards.length <= 0 ? true : false);
                    }}
                  >
                    <i className="uil uil-plus"></i>
                  </button>
                </li>
              </OverlayTrigger>
            </>)}
          </ul>
        </div>
      </div>
      <div id="kanban">
        {isLoadingBoards ? (<>
          <div className="kanban-parent d-flex" style={{ zoom: kanbanZoom }}>
            <div className="kanban-container flex-grow-1">
              <div className="kanban-board rounded placeholder-wave"></div>
              <div className="kanban-board rounded placeholder-wave"></div>
              <div className="kanban-board rounded placeholder-wave"></div>
              <div className="kanban-board rounded placeholder-wave"></div>
            </div>
          </div>
        </>) : (<>
          {(boards && boards.length > 0) ? (<>
            {(boardData && boardData.id) && (<>
              <KanbanBoard zoom={kanbanZoom} tasks={filteredTasks} columns={columnsData} />
            </>)}
          </>) : (<>
            <div style={{ zoom: kanbanZoom }}>
              <h4 className="text-center">
                Você não possui nenhum board cadastrado.
              </h4>
              <div className="text-center pt-3 pb-5 d-print-none">
                <a className="btn btn-primary rounded-pill"
                  onClick={() => {
                    setShowAddBoardModal(true);
                    setStopAutoUpdate(true);
                    setEditBoardData({} as Board);
                    setBoardDefault(boards.length <= 0 ? true : false);
                  }}>Criar Board</a>
              </div>
              <div className="text-center mb-4">
                <img src={boradsImg} style={{ maxHeight: '60vh', maxWidth: "100%" }} />
              </div>
            </div>
          </>)}
        </>)}
      </div>
      {/* MODAL BOARD */}
      <ModalComponent
        title={(editBoardData.id && editBoardData.id != "" ? 'Editar' : 'Adicionar') + " Board"}
        show={showAddBoardModal}
        size="sm"
        onHide={() => {
          setShowAddBoardModal(false);
          setStopAutoUpdate(false);
          setBoardData(boardData);
        }}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          if (boardData && boardData.id && editBoardData.id == boardData.id) {
            updateBoard(editBoardData?.id, { name: editBoardData?.name, isDefault: boardDefault });
          } else {
            createBoard({ name: editBoardData?.name, isDefault: boardDefault });
            setSelectedNavTab("");
          }
          setShowAddBoardModal(false);
          setStopAutoUpdate(false);
        }}>
          <Modal.Body className="bg-light">
            <Input
              type="text"
              placeholder="Nome do Board"
              name="boardName"
              defaultValue={editBoardData?.name}
              autoComplete="off"
              autoFocus={true}
              className=""
              onChange={(e) => handleBoardName(e.target.value)}
            />
            {/* {editBoardData.id && editBoardData.id != "" && (<> */}
            <label className="mt-4 form-check d-flex gap-3 align-items-center form-switch">
              <input className="form-check-input" type="checkbox" role="switch" disabled={boards.length <= 0} onChange={handleChangeBoardDefault} defaultChecked={boardDefault} />
              <span className="form-check-label lh-1">
                Board padrão para encaminhamentos.
                <OverlayTrigger
                  placement="bottom-end"
                  overlay={<Tooltip>Os pacientes encaminhados por outros profissionais cairão na primeira coluna deste board.</Tooltip>}
                >
                  <i className="uil uil-info-circle opacity-50 ms-2"></i>
                </OverlayTrigger>
              </span>
            </label>
            {/* </>)} */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="btn-primary rounded-pill"
            >
              {(boardData && boardData.id && editBoardData.id == boardData.id) ? 'Salvar' : 'Adicionar'}
            </Button>
          </Modal.Footer>
        </form>
      </ModalComponent>

    </ContainerAnimation>
  );
}
