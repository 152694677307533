import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import iconConecta from "assets/icon.png";

import { useBoard } from "providers/Board";
import { Task, ToTaskForward } from "types/board";

interface PropsTaskBadges {
  task: Task;
}

export function TaskBadges({
  task,
}: PropsTaskBadges) {
  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    tags,
    leadOrigin,
    OTHERS_LEAD_ORIGIN
  } = useBoard();

  return (
    <>
      {task.taskOrigin ? (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">Origem: {task.taskOrigin === OTHERS_LEAD_ORIGIN ? task.othersTaskOrigin : leadOrigin.find((item) => (item.value === task.taskOrigin))?.label}</Tooltip>}
        >
          {task.taskOrigin === 6 ? (
            <span className={`badge text-pure-white bg-${leadOrigin.find((item) => (item.value === task.taskOrigin))?.icon}`} style={{padding:2}}>
              <img src={iconConecta} width={16} height={16} />
            </span>
          ) : (
            <span className={`badge px-1 text-pure-white bg-${leadOrigin.find((item) => (item.value === task.taskOrigin))?.icon}`}>
              <i className={`uil uil-${leadOrigin.find((item) => (item.value === task.taskOrigin))?.icon}`}></i>
            </span>
          )}
        </OverlayTrigger>
      </>) : (<></>)}
      {task.duplicated && (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">Este paciente está duplicado.</Tooltip>}
        >
          <span className="badge px-1 text-white bg-dark"
            onClick={() => handleClick && handleClick(task)}
            onMouseEnter={() => handleMouseEnter && handleMouseEnter(task)}
            onMouseLeave={handleMouseLeave && handleMouseLeave}
          ><i className="uil uil-layers"></i></span>
        </OverlayTrigger>
      </>)}
      {(!task.patient || task.patient && task.patient.id === 0) && task.type === 0 && (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">Não é um paciente Conectadoc</Tooltip>}
        >
          <span className="badge px-1 bg-info"><i className="uil uil-user-times"></i></span>
        </OverlayTrigger>
      </>)}
      {task.attachmentsQuantity && task.attachmentsQuantity > 0 ? (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">{task.attachmentsQuantity} anexo{task.attachmentsQuantity === 1 ? "" : "s"}</Tooltip>}
        >
          <span className="badge px-1 text-body">{task.attachmentsQuantity} <i className="uil uil-paperclip"></i></span>
        </OverlayTrigger>
      </>) : (<></>)}
      {task.fromTaskForward && !task.fromTaskForward.sameUserForward && (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">
            <div className="text-start">
              Encaminhado por:
              <ul className="ps-4 small mb-1">
                <li className="lh-1 py-1">{task.fromTaskForward.originUser.name ? task.fromTaskForward.originUser.name : task.fromTaskForward.originUser.id} <br /> <span className="small opacity-50">{moment(task.fromTaskForward.createdDate).format("DD/MM/Y HH:mm")}</span></li>
              </ul>
            </div>
          </Tooltip>}
        >
          <span className="badge px-1 bg-success"><i className="uil uil-arrow-to-bottom"></i></span>
        </OverlayTrigger>
      </>)}
      {task.toTaskForward && task.toTaskForward.filter((item) => { return !item.sameUserForward }).length > 0 && (<>
        <OverlayTrigger
          container={document.body}
          overlay={
            <Tooltip className="small">
              <div className="text-start">Encaminhado para:
                <ul className="ps-4 small mb-1">
                  {task.toTaskForward.filter((item) => { return !item.sameUserForward }).map((item: ToTaskForward, index) => (
                    <li key={index} className="lh-1 py-1">
                      {item.destinationUser.name ? item.destinationUser.name : item.destinationUser.id}<br /><span className="small opacity-50">{moment(item.createdDate).format("DD/MM/Y HH:mm")}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </Tooltip>
          }
        >
          <span className="badge px-1 bg-orange"><i className="uil uil-arrow-from-right"></i></span>
        </OverlayTrigger>
      </>)}
      {(!task.appointment || !task.appointment?.date) && columnsData[task.columnKey] && [3,4].includes(columnsData[task.columnKey].type) && (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">Sem data definida</Tooltip>}
        >
          <span className="badge px-1 bg-danger"><i className="uil uil-calendar-alt"></i></span>
        </OverlayTrigger>
      </>)}
      {task.appointment && task.appointment.date && columnsData[task.columnKey] && [3,4].includes(columnsData[task.columnKey].type) && (<>
        <OverlayTrigger
          container={document.body}
          overlay={<Tooltip className="small">Agendado para: <br /> <b>{moment(task.appointment.date).format("dddd")}</b> <br /> {moment(task.appointment.date).format("HH:mm") == "00:00" ? moment(task.appointment.date).format("DD/MM/Y") : moment(task.appointment.date).format("DD/MM/Y HH:mm")}</Tooltip>}
        >
          <span className="badge px-1 bg-secondary"><i className="uil uil-calendar-alt"></i></span>
        </OverlayTrigger>
      </>)}
    </>
  );
};
