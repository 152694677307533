import { useState, useEffect } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import { useVersionNotes } from "providers/VersionNotes";
import { useUser } from "providers/User";
import { RoleDescription } from "types/enums";
import user from "assets/user.png";
import VersionModal from "components/VersionModal";

const DropdownMenu = () => {
  const navigate = useNavigate();
  const { logout, userRole } = useAuth();
  const { userData } = useUser();
  const { toggleDarkMode } = useDarkMode();
  const { darkMode, menuShow, setMenuShow, handleMenuShow } = useDarkMode();
  const { pathname } = useLocation();
  const {
    versionNotes,
    localVersion,
    showNotesModal,
    setShowNotesModal,
    showNoShow
  } = useVersionNotes();


  const [hideTooltip, setHideTooltip] = useState(false);

  const handleResize = () => {
    if (document.body.clientWidth < 576) {
      setHideTooltip(true);
    } else {
      setHideTooltip(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <OverlayTrigger
        key={pathname}
        placement="right"
        overlay={!menuShow && !hideTooltip ? <Tooltip>{userData?.socialName || userData?.name}</Tooltip> : <></>}
      >
        <Dropdown className="user-menu">
          <Dropdown.Toggle variant="">
            <div className="img rounded-circle border border-pure-white">
              <img src={userData?.image ? userData?.image : user} alt={userData?.socialName || userData?.name} className="rounded-circle" />
            </div>
            <span>{userData?.socialName || userData?.name}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Header className="lh-sm">
              <b className="d-block text-body">{userData?.socialName || userData?.name}</b>
              <span className="d-block">
                {userData?.email}
              </span>
              <span className="d-block fw-bold small">
                {userRole === RoleDescription.Admin && "Administrador"}
                {userRole === RoleDescription.Professional && "Profissional"}
                {userRole === RoleDescription.Secretary && "Secretária"}
                {userRole === RoleDescription.Patient && "Paciente"}
              </span>
            </Dropdown.Header>

            <Dropdown.Divider />

            {/* <Dropdown.Item
              role="button"
              className="dark-mode"
              as="a"
              onClick={()=>{navigate("/meus-dados")}}
            >
            <i className="uil uil-user-square me-2"></i>
              Meus Dados
            </Dropdown.Item> */}

            <Dropdown.Item
              role="button"
              className="dark-mode"
              as="a"
              onClick={toggleDarkMode}
            >
              <i className="uil uil-moon me-2"></i>
              Alterar Tema
            </Dropdown.Item>

            <Dropdown.Item
              role="button"
              className="dark-mode"
              as="a"
              onClick={() => { setShowNotesModal(true) }}
            >
              <i className="uil uil-notes me-2"></i>
              Notas de Versão
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item as="a" role="button" onClick={() => { logout(); setMenuShow(false); }} className="text-danger">
              Sair
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </OverlayTrigger>

      <VersionModal versionNotes={versionNotes} localVersion={localVersion} show={showNotesModal} setShow={setShowNotesModal} showNoShow={(versionNotes && localVersion && versionNotes[0].version == localVersion[0]) ? false : true} />
    </>
  );
};
export default DropdownMenu;
