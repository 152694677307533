import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, MenuItem } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ButtonGroup, ToggleButton, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectInput from "components/SelectInput";
import RadioInput from "components/RadioInput";
import { useProfessional } from "providers/Professional";
import { useBoard } from "providers/Board";
import { api } from "services";
import { Professional, ProfessionalSearchData } from "types/professional";
import { toast } from "utils/toast";
import userIcon from "assets/user.png";
import {
  handleAccountInput,
  handleCepInput,
  handleCnpjInput,
  handleCpfOrCnpjInput,
  handleDateInput,
  handleIeInput,
  handleMobilePhoneInput,
  handlePhoneInput,
  handlePhoneOrMobilePhoneInput,
} from "utils/handleInput";
import { Board, Id, Task } from "types/board";
import { Chat } from "types/whatsapp";
import ModalTask from "pages/Boards/components/ModalTask";

interface ModalLeadProps {
  onHide: () => void;
  chat: Chat
}

interface leadFormData {
  selectedBoard: string
}

const ModalLead = ({ onHide, chat }: ModalLeadProps) => {

  const {
    getBoards,
    boards,
    isLoading,
    isLoadingBoards,
    setShowAddTaskModal,
    getBoardById,
    setColumnId,
    boardData,
    setBoardData,
    clearData,
    setTaskType,
    showLeadModal,
    setShowLeadModal
  } = useBoard();

  const schema = yup.object().shape({
    selectedBoard: yup
      .string()
      .required("Por favor, selecione um board."), // Valida se um board foi selecionado
  });

  const [selectedBoard, setSelectedBoard] = useState('' as Id);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<leadFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (
    data: leadFormData
  ) => {
    getBoardById(selectedBoard);
  };

  useEffect(() => {
    if (selectedBoard && boardData.id == selectedBoard && boardData.columns && boardData.columns.length > 0) {
      setShowAddTaskModal(true);
      const colId = boardData.columns.find(column => column.type === 1)?.id;
      if (colId) {
        setColumnId(colId);
      } else {
        toast.fire({
          icon: "error",
          title: `Ops...ocorreu um erro`,
          text: "Não foi possível localizar a coluna de Lead no board informado."
        });
      }
    }
  }, [selectedBoard, boardData]);

  useEffect(() => {
    clearData();
    setTaskType(0);//0 patient, 1 task
    setBoardData({} as Board);
    getBoards();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="bg-light">
          {isLoadingBoards ? (<>
            <div className="text-center py-5">
              <CircularProgress size={40} />
            </div>
          </>) : (<>
            <p className="text-center">Selecione o <b>board</b> que deseja colocar <br /><b>{chat.sentOrReceived ? (chat.toName && chat.toName != "" ? chat.toName : chat.toId) : (chat.fromName && chat.fromName != "" ? chat.fromName : chat.fromId)}</b>.</p>
            <div className="list-group mt-2 overflow-auto mb-3" style={{ maxHeight: "30vh" }}>
              {boards.map((board) => (
                <label role="button" key={board.id} className="list-group-item list-group-item-action lh-1 py-3">
                  <div className="row g-0 flex-nowrap align-items-center">
                    <div className="col col-auto">
                      <span className="control control--radio mb-1">
                        <span className="caption">&nbsp;</span>
                        <input
                          {...register("selectedBoard")}
                          name="selectedBoard"
                          type="radio"
                          value={board.id}
                          defaultChecked={board.id == selectedBoard}
                          onChange={() => {
                            setSelectedBoard(board.id);
                            console.log(board.id);
                          }} />
                        <div className="control__indicator"></div>
                      </span>
                    </div>
                    <div className="col">
                      <span className="fw-semibold d-block">{board.name}</span>
                    </div>
                    {board.isDefault && (<>
                      <div className="col col-auto">
                        <i className="uil uil-star text-warning ms-2"></i>
                      </div>
                    </>)}
                  </div>
                </label>
              ))}
              {errors.selectedBoard && (
                <div className="text-danger">
                  <small>{errors.selectedBoard.message}</small> {/* Mensagem de erro */}
                </div>
              )}
            </div>
          </>)}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            disabled={(isLoading || selectedBoard == "")}
            className="btn-dark rounded-pill"
            onClick={() => {

            }}
          >
            Prosseguir<i className="uil uil-arrow-right ms-2"></i>
          </Button>
        </Modal.Footer>
      </form>

      {/* modal task */}
      <ModalTask searchPatientPhone={(chat.sentOrReceived ? (chat.toId ?? "") : chat.fromId)} />
    </>
  );
};

export default ModalLead;
