import {
  Handle,
  Position,
} from "@xyflow/react";
import { StartNodeProps } from "../utils/nodeTypes";

const StartNode = ({ data, onDelete, ...props }: StartNodeProps) => {
  return (
    <div className={`start-node card text-bg-success rounded-pill px-2 d-flex flex-row position-relative ${props.selected ? 'border-primary shadow' : ''}`}>
      {props.selected && (
        <div className="position-absolute bottom-100 start-50 translate-middle-x mb-2">
          {/* Botão de excluir o MessageNode */}
          <button
            onClick={() => onDelete(props.id)} // Chama a função onDelete ao clicar
            className="btn btn-light text-danger btn-sm rounded p-1 lh-1 my-n1"
          >
            <i className="uil uil-trash"></i>
          </button>
        </div>
      )}
      <i className="uil uil-play me-1"></i>
      Início
      <Handle id="start" type="source" position={Position.Right} className="top-50 start-100 translate-middle" />
    </div>
  );
};

export default StartNode;