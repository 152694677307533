import { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import Footer from "components/Footer";
import { useUser } from "providers/User";
import { useDarkMode } from "providers/DarkMode";
import logger from "utils/logger";
import Aside from "components/Aside";
import Scripts from "pages/Chats/components/Scripts";
import user from "assets/user.png";
import chatImg from "assets/chat.png";
import moment from "moment";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import Input from "components/Input";
import FlowBuilder from "../FlowBuilder";

interface LocationState {
  id?: string;
}


interface Flow {
  id: string;
  title: string;
  userId: number;
  createdDate: string;
  updatedDate: string;
  status: string;
}

const Flows = () => {
  useEffect(() => { document.title = 'Fluxos | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const flowId = state?.id ?? null;
  const { darkMode } = useDarkMode();

  const [flowsData, setFlowsData] = useState([{
    id: "1",
    title: "Primeiro contato",
    userId: 1538,
    createdDate: "02/10/2024 18:55",
    updatedDate: "02/10/2024 20:32",
    status: "read",
  }, {
    id: "2",
    title: "Agendamento de consulta",
    userId: 1538,
    createdDate: "10/03/2024 18:55",
    updatedDate: "10/03/2024 18:55",
    status: "read",
  }, {
    id: "3",
    title: "Apresentação da clínica",
    userId: 1538,
    createdDate: "05/02/2024 18:55",
    updatedDate: "05/02/2024 18:55",
    status: "unread",
  }, {
    id: "4",
    title: "Confirmação de consulta",
    userId: 1538,
    createdDate: "02/10/2024 18:55",
    updatedDate: "02/10/2024 18:55",
    status: "read",
  }, {
    id: "5",
    title: "Cancelamento de Consulta",
    userId: 1538,
    createdDate: "02/10/2024 18:55",
    updatedDate: "02/10/2024 18:55",
    status: "read",
  }] as Flow[]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filtered, setFiltered] = useState<Flow[]>(flowsData);
  const handleSearch = (q: string) => {
    setSearchTerm(q);
    const term = q.toLowerCase().trim();
    if (term === '') {
      setFiltered(flowsData);
    } else {
      const terms = term.split(' ').filter(t => t);
      var newItems = flowsData.filter(flow => {
        return terms.every(t => flow.title.toLowerCase().includes(t))
      });
      setFiltered(newItems);
    }
  }

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    console.log(userData);
  }, [token, userData]);

  return (
    <>
      <ContainerAnimation className="content">
        <div id="top">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Fluxos</h1>
                <span className="badge bg-secondary">{flowsData.length}</span>
              </div>
              <div className="col-12 col-md-auto order-5 order-md-1">
                <div className="border rounded-3 position-relative">
                  <span className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}><i className="uil uil-search"></i></span>
                  <Input
                    type="search"
                    placeholder="Buscar Item"
                    onChange={(e) => { handleSearch(e.target.value); }}
                    className="ps-5 border"
                    defaultValue={searchTerm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {searchTerm != "" && (
            <>
              {filtered.length > 0 ? (
                <p className="text-center">Exibindo <b>{filtered.length}</b> de <b>{flowsData.length}</b> fluxo{flowsData.length != 1 ? 's' : ''} na busca por <b>{searchTerm}</b></p>
              ) : (
                <p className="text-center">Nenhum fluxo encontrado na busca por <b>{searchTerm}</b></p>
              )}
            </>
          )}
          <div className="row g-3">
            {filtered.map((flow, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-xl-3">
                <div className="card h-100 card-hover mb-2">
                  <div className="bg-light card-img-top border-bottom">
                    <div className="ratio ratio-16x9 position-relative">
                      <FlowBuilder mini={true} id={flow.id} />
                    </div>
                  </div>
                  <div className="card-body p-3">
                    <div className="row gx-3 content-hover">
                      <div className="col">
                        <div className="row gx-3">
                          <div className="col">
                            <span className="text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">TÍTULO:</span></span>
                            <p className="fw-semibold small mb-0" role="button">{flow.title}</p>
                          </div>
                          <div className="col col-auto">
                            <span className="text-10 d-block lh-1">&nbsp;</span>
                            <a role="button" onClick={() => { navigate(`/fluxos/${flow.id}`); }} className="stretched-link">
                              <i className="uil uil-angle-right"></i>
                            </a>
                          </div>
                          {flow.createdDate == flow.updatedDate ? (
                            <div className="col-12 small">
                              <span className="text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">CRIADO EM:</span></span>
                              {moment(flow.createdDate).format("DD/MM/YYYY HH:mm")}
                            </div>
                          ) : (
                            <div className="col-12 small">
                              <span className="text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">ATUALIZADO EM:</span></span>
                              {flow.updatedDate ? moment(flow.updatedDate).format("DD/MM/YYYY HH:mm") : '-'}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ContainerAnimation >
    </>
  );
};

export default Flows;
