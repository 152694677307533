import { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import { useUser } from "providers/User";
import { useDarkMode } from "providers/DarkMode";
import { useWhatsapp } from "providers/Whatsapp";
import { useBoard } from "providers/Board";
import Image from "components/Image";
import userImg from "assets/user.png";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Chat, Message, GroupedMessages, NewMessage } from "types/whatsapp";
import { TextareaAutosize } from "@mui/material";
import { isDev } from 'utils/isDev';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

interface ChatInputProps {
  chatId: string | null;
}

const ChatInput = ({ chatId }: ChatInputProps) => {

  const {
    showLeadModal,
    setShowLeadModal
  } = useBoard();

  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages,
    deleteChat,
    isSendingMessages
  } = useWhatsapp();

  const { darkMode } = useDarkMode();
  const [textAreaValue, setTextAreaValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleScriptSelection = (scriptContent: string) => {
    setTextAreaValue(scriptContent); // Atualiza o textarea com o conteúdo do script selecionado
    // setAsideRightOpen(false);
    textAreaRef.current?.focus();
  };

  const onEmojiClick = (emoji: any) => {
    if (textAreaRef.current) {
      const cursorPosition = textAreaRef.current?.selectionStart || 0;
      // concat the emoji to the string
      const text =
        textAreaValue.slice(0, cursorPosition) +
        emoji.native +
        textAreaValue.slice(cursorPosition);
      setTextAreaValue(text);
      const newCursorPosition = cursorPosition + emoji.native!.length;
      // allow to add multiple emojis in the same position of string
      setTimeout(() => {
        textAreaRef.current?.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }, 10);
    }
  };


  let messageQueue: NewMessage[] = [];
  let isProcessingQueue = false;

  const processQueue = async () => {
    if (isProcessingQueue) return; // Evita que processQueue seja chamado enquanto já está em execução
    isProcessingQueue = true;

    while (messageQueue.length > 0) {
      const message = messageQueue.shift(); // Pega a primeira mensagem da fila
      if (message) {
        await sendMessage(message); // Envia a mensagem e espera a Promise resolver
      }
    }

    isProcessingQueue = false;
  };

  const handleSendMessage = () => {
    if (textAreaValue.trim() == "") {
      textAreaRef.current?.focus();
      return;
    }
    const newMsg: NewMessage = {
      senderId: (activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId),
      message: textAreaValue.trim(),
    };

    const appendMessage: Message = {
      message: textAreaValue.trim(),
      sentOrReceived: true,
      messageId: "",
      fromId: (!activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId),
      fromName: (!activeChat.sentOrReceived ? (activeChat.toName ?? "") : activeChat.fromName),
      toId: (activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId),
      toName: (activeChat.sentOrReceived ? (activeChat.toName ?? "") : activeChat.fromName),
      date: (new Date()),
      origin: 'WA',
      isRead: false,
    }

    messageQueue.push(newMsg); // Adiciona a nova mensagem à fila
    processQueue(); // Processa a fila para enviar mensagens em ordem

    setTextAreaValue("");
    textAreaRef.current?.focus();

    const updatedChats = chats.map(chat => {
      if ((chat.sentOrReceived ? chat.toId : chat.fromId) === chatId) {
        return {
          ...chat, // Copia os dados do chat
          message: newMsg.message,
        };
      }
      return chat; // Retorna o chat sem alterações se não for o correto
    });

    // Atualize o estado com a nova cópia
    setChats(updatedChats);
    setMessages([...messages, appendMessage]);
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Se Shift + Enter, permite a quebra de linha
        return;
      } else {
        // Se apenas Enter, impede a quebra de linha e submete o formulário
        event.preventDefault();
        handleSendMessage();
      }
    }
  };

  return (
    <>
      <div className="d-flex px-3 m-0">
        <Dropdown drop="up">
          <Dropdown.Toggle variant="link" className="no-arrow p-2 lh-1">
            <i className="uil uil-smile text-muted"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="start" className="p-0" style={{ maxHeight: "initial" }}>
            <Picker emojiSize={16} theme={darkMode ? 'dark' : 'light'} searchPosition="none" locale="pt" skinTonePosition="search" previewPosition="none" data={data} onClickOutside={() => { textAreaRef.current?.focus() }} onEmojiSelect={onEmojiClick} />
          </Dropdown.Menu>
        </Dropdown>
        {isDev() && (
          <Dropdown drop="up">
            <Dropdown.Toggle variant="link" className="no-arrow p-2 lh-1">
              <i className="uil uil-paperclip text-muted"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu align="start" className="p-0" style={{ maxHeight: "initial" }}>
              <Dropdown.Item role="button" as="a" onClick={() => { }} >
                <i className="uil uil-file-upload-alt me-2"></i>Anexar Arquivo
              </Dropdown.Item>
              <Dropdown.Item role="button" as="a" onClick={() => { }} >
                <i className="uil uil-image me-2"></i>Anexar Imagem
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <TextareaAutosize
        className="flex-grow-1 form-control my-3 mx-0 rounded shadow-sm"
        id="mensagem"
        placeholder="Escreva a mensagem"
        minRows={1}
        disabled={isLoadingMessages}
        ref={textAreaRef}
        value={textAreaValue}
        onChange={(e) => setTextAreaValue(e.target.value)}
        onKeyDown={handleKeyPress}
      ></TextareaAutosize>
      <button onClick={handleSendMessage} disabled={isLoadingMessages} className="btn btn-link px-3 py-2 lh-1 m-0"><i className="uil uil-message text-muted"></i></button>
    </>
  );
};

export default ChatInput;
