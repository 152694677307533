import moment from "moment";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { usePatient } from "providers/Patient";
import ConsultaCardSkeleton from "./components/ConsultaCardSkeleton";
import ConsultaCard from "./components/ConsultaCard";
import { Appointment } from "types/patient";

const Appointments = () => {

  const { appointments, setAppointments, patientLoading, getPatientAppointments } = usePatient();

  const sortAppointmentsByAscendingDate = (appointments: Appointment[]) =>
    appointments.sort((firstItem: Appointment, secondItem: Appointment) =>
      moment(firstItem.date)
        .add(firstItem.scheduleTime.initialTimeHours, "hour")
        .add(firstItem.scheduleTime.initialTimeMinutes, "minute") >
        moment(secondItem.date)
          .add(secondItem.scheduleTime.initialTimeHours, "hour")
          .add(secondItem.scheduleTime.initialTimeMinutes, "minute")
        ? 1
        : -1
    );

  useEffect(() => {
    getPatientAppointments(3983);
  }, []);

  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <h1 className="text-center mb-5">Minhas Consultas</h1>
            <div className="card text-body bg-light p-3 px-md-5 py-md-4 mb-5">
              <h3 className="fw-bold mb-4 text-color">
                <i className="las la-calendar-alt me-2"></i>Consultas
              </h3>
              <div className="listagem">
                {!patientLoading && !appointments.length && (
                  <div className="text-center text-muted">
                    <p className="mb-0">
                      <i className="las la-calendar-times display-4"></i>
                    </p>
                    <h6 className="mb-3">Nenhuma consulta agendada</h6>
                    <p>
                      <Link to="/" className="btn btn-primary">
                        Listar Profissionais
                      </Link>
                    </p>
                  </div>
                )}

                {!patientLoading && !!appointments.length && sortAppointmentsByAscendingDate(appointments).map((appointment) => (
                  <ConsultaCard key={appointment.id} appointment={appointment} />
                ))}

                {patientLoading && [...Array(3)].map((_, index) => <ConsultaCardSkeleton key={index} />)}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Appointments;
