import moment from "moment";

interface FileToDownloadInterface {
  id: number;
  nome: string;
  tipo: string;
  url: string;
  tipoDocumento: number;
  dataUpload: string;
}

export const FileToDownload = ({
  dataUpload,
  tipo,
  url,
  tipoDocumento,
}: FileToDownloadInterface) => {
  let tipoDocumentoName = "";
  switch (tipoDocumento) {
    case 1:
      tipoDocumentoName = "receita";
      break;
    case 2:
      tipoDocumentoName = "atestado";
      break;
    case 3:
      tipoDocumentoName = "orientação";
      break;
    case 4:
      tipoDocumentoName = "pedido";
      break;
    case 5:
      tipoDocumentoName = "resultado";
      break;
    case 6:
      tipoDocumentoName = "recibo";
      break;
  }

  return (
    <a href={url} className="arquivo text-body d-block" download>
      <span className="icone-file me-2 text-primary">
        <span>.{tipo}</span>
        <i className="las la-file h2 mb-0"></i>
      </span>
      <span>
        {tipoDocumentoName} | {moment(dataUpload).format("DD-MM-YYYY")}
      </span>
    </a>
  );
};
