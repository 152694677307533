import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppointmentInterface } from "types/patient";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import { api } from "services";
import { toast } from "utils/toast";
import { useEffect, useState } from "react";
import AppointmentFilesSkeleton from "./components/AppointmentSkeleton";
import AppointmentFiles from "./components/AppointmentFiles";

interface File {
  id: number;
  nome: string;
  tipo: string;
  url: string;
  tipoDocumento: number;
  dataUpload: string;
}

interface FileType {
  title: string;
  files: File[];
}

interface ListFiles {
  sucesso: boolean;
  listFiles: File[];
}

enum FileTypeDefinition {
  Prescription = 1,
  Certificate,
  Advice,
  ExamRequest,
  ExamResult,
  Receipt,
}

const CallDetails = () => {
  const navigation = useNavigate();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [fileTypes, setFileTypes] = useState<FileType[]>([]);

  const handleFileSubmit = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("files", (event.currentTarget.files as FileList)[0]);

    try {
      await api.post("/Exams", formData, {
        params: { title: `A-${appointment.id}`, attendanceId: appointment.id },
      });

      setIsLoading(false);
      toast.fire({
        title: "Arquivo enviado com sucesso",
        icon: "success",
      });

      getFiles();
    } catch (error) {
      setIsLoading(false);
      toast.fire({
        title: "Algo deu errado",
        text: "Por favor, tente novamente mais tarde",
        icon: "warning",
      });
    }
  };

  const getFiles = async () => {
    try {
      const { data } = await api.get("/FileImmediate/GetListFileImmediate", {
        params: {
          IdAgendamento: appointment.id,
        },
      });

      setFileTypes([
        {
          title: "Receitas",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.Prescription
          ),
        },
        {
          title: "Atestados",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.Certificate
          ),
        },
        {
          title: "Orientações",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.Advice
          ),
        },
        {
          title: "Pedidos de Exames",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.ExamRequest
          ),
        },
        {
          title: "Resultados de Exames",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.ExamResult
          ),
        },
        {
          title: "Recibos",
          files: (data as ListFiles).listFiles.filter(
            (item) => item.tipoDocumento === FileTypeDefinition.Receipt
          ),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFiles().then(() => setIsLoading(false));
  }, []);

  if (!location.state) {
    return <Navigate to="/" />;
  }

  const { appointment } = location.state as AppointmentInterface;
  const { date, address, price, scheduleType } = appointment;
  const { gender, name, image, crm } = appointment.specialistDoctor;
  const { initialTimeHours, initialTimeMinutes } = appointment.scheduleTime;

  return (
    <>
      <div id="wrapper">
        <div className="page-header flex-fill justify-content-start">
          <div className="container py-5">
            <h1 className="text-center mb-5">Atendimento {appointment.id}</h1>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-5">
                <div className="card text-body bg-light p-3 px-md-5 py-md-4 sticky-xl-top mb-5">
                  <h5>Informações da Consulta</h5>
                  <h6 className="text-muted text-uppercase small">
                    Profissional:
                  </h6>
                  <div className="bg-white rounded-4 border p-3 pb-0 mb-4">
                    <div className="row align-items-center">
                      <div className="col col-auto pb-3 e-0">
                        <div className="img-wrap" style={{ width: "60px" }}>
                          <img
                            alt={`${gender === 0 ? "Dra." : "Dr."} ${name}`}
                            src={
                              image
                                ? image
                                : gender === 0
                                  ? "https://conectadoc.com.br/ImgMedico/Thumbs/ThumbFemale.jpg"
                                  : "https://conectadoc.com.br/ImgMedico/Thumbs/ThumbMale.jpg"
                            }
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm pb-3">
                        <h6 className="text-primary mb-1 small">CRM: {crm}</h6>
                        <h5 className="mb-1">
                          {gender === 0 ? "Dra." : "Dr."} {name}
                          <i
                            className="las la-check-circle text-primary ms-2"
                            title="Profissional Verificado"
                          ></i>
                        </h5>
                        {/* <h6 className="mb-0">Especialty</h6> */}
                      </div>
                    </div>
                  </div>
                  <h6 className="text-muted text-uppercase small">
                    Data e hora da consulta:
                  </h6>
                  <div className="bg-white rounded-4 border p-3 pb-0 mb-4">
                    <h5 className="text-center mb-3">
                      {moment(new Date(date)).format("L")} - <span className="text-primary">{moment(`${initialTimeHours}:${initialTimeMinutes}`, "HH:mm").format("HH:mm")}</span>
                    </h5>
                  </div>
                  <h6 className="text-muted text-uppercase small">
                    Local da consulta:
                  </h6>
                  <div className="bg-white rounded-4 border p-3 mb-4">
                    <h5 className="text-center mb-0">
                      {scheduleType === 0 ? address.name : "Teleconsulta"}
                    </h5>
                    {scheduleType === 0 && (
                      <p className="text-center mb-0 small">
                        {address.addressName}, {address.number}
                      </p>
                    )}
                  </div>

                  <h6 className="text-muted text-uppercase small">
                    Valor da consulta:
                  </h6>
                  <div className="bg-white rounded-4 border p-3 pb-0 mb-4">
                    <h4 className="text-center mb-3">
                      R$ <span className="text-primary">{price}</span>
                    </h4>
                  </div>

                  <div className="row gap-4">
                    <div className="col-12 text-center">
                      {!!isLoading ? (
                        <button className="btn btn-sm btn-primary" disabled>
                          <CircularProgress size={21} color="inherit" />
                        </button>
                      ) : (
                        <Button
                          variant="contained"
                          className="rounded-4"
                          component="label"
                        >
                          <i className="las la-file-upload me-2 fs-4"></i>Enviar resultados de exames
                          <input
                            hidden
                            multiple
                            type="file"
                            onChange={handleFileSubmit}
                          />
                        </Button>
                      )}
                    </div>
                    <div className="col-12">
                      <button
                        onClick={() => navigation(-1)}
                        className="btn btn-outline-secondary"
                      >
                        <i className="las la-arrow-left me-2"></i>Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-7">
                <div className="card text-body bg-light p-3 px-md-5 py-md-4">
                  <h5>Arquivos da Consulta</h5>
                  {!isLoading
                    ? fileTypes?.map((file, index) => (
                      <AppointmentFiles
                        key={index}
                        title={file.title}
                        specificFiles={file.files}
                      />
                    ))
                    : [...Array(6)].map((_, index) => (
                      <AppointmentFilesSkeleton key={index} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallDetails;
