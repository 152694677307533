import { Dispatch, SetStateAction } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalComponent from 'components/ModalComponent';
import { useVersionNotes } from "providers/VersionNotes";

import Button from "components/Button";

interface VersionNotesContent {
  title: string,
  description: string,
}
interface VersionNotes {
  version: string,
  content: VersionNotesContent[],
}

interface VersionModalProps {
  versionNotes: VersionNotes[],
  localVersion: string,
  size?: "sm" | "lg" | "xl";
  showNoShow: boolean,
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}


const VersionModal = ({
  versionNotes,
  localVersion,
  showNoShow,
  size = "sm",
  show,
  setShow,
  ...rest
}: VersionModalProps) => {
  return (<>
    {(versionNotes) && (<>
      <Modal
        show={show}
        size={size}
        {...rest} 
        backdrop="static" 
        centered 
        onHide={() => {
          setShow(false);
        }}>
        <Modal.Header
          className="bg-white sticky-top modal-header p-3 m-0"
          closeButton
        >
          <Modal.Title className="h5 m-0"><i className="uil uil-notes me-2"></i>Notas de Versão</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light small">
          {versionNotes.map((v, index) => {
            if(index == 0){
              return <div key={index} className="mb-4">
              <h5 className="ps-3 fw-bold">v.{v.version}</h5>
              <div className="card border mb-2">
                <ul className="list-group list-group-flush">
                  {v.content.map((item, k) => (
                    <li key={k} className="list-group-item p-3">
                      {item.title && (
                        <h6 className={`${(!item.description || item.description == "") ? 'mb-0' : ''} d-flex`}  dangerouslySetInnerHTML={{ __html: item.title }}></h6>
                      )}
                      {item.description && (
                        <div dangerouslySetInnerHTML={{ __html: item.description }} className="text-muted ps-4" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            }
          })}
          {versionNotes.length > 1 && (
            <>
              <p className="text-center mb-2">
                <Link to="/notas-de-versao" className="btn btn-primary" onClick={() => {
                setShow(false);
              }}>
                  <span>Exibir todas as versões</span>
                </Link>
              </p>
            </>
          )}
        </Modal.Body>
        { showNoShow && (
        <Modal.Footer className="justify-content-center bg-white sticky-bottom">
            <Button
              type="button"
              className="btn-link small px-0 text-decoration-hover text-primary rounded-pill"
              onClick={() => {
                setShow(false);
                localStorage.setItem(`versionNotes`, (versionNotes[0].version).toString()+"|true");
              }}
            >
              Não exibir novamente
            </Button>
        </Modal.Footer>
        )}
      </Modal>
    </>)}
  </>);
};

export default VersionModal;
