const Footer = () => (
  <footer id="footer">
    <div className="container">
      <div className="small py-3 text-center">
        {`Copyright © ${new Date().getFullYear()}. Todos os direitos reservados. — `} <a href="https://conectadoc.com.br" className="text-body text-decoration-hover" target="_blank"><b>Conecta<span className="text-blue">Doc</span></b></a>
      </div>
    </div>
  </footer>
);

export default Footer;
