import { Node, NodeProps } from "@xyflow/react";

// componentes

export const components = [{
  type: 'start',
  title: 'Início',
  description: 'Defina por onde o fluxo vai começar.',
  icon: 'play',
  color: 'success',
}, {
  type: 'end',
  title: 'Fim',
  description: 'Defina onde o fluxo vai terminar.',
  icon: 'square',
  color: 'danger',
}, {
  type: 'message',
  title: 'Mensagem',
  description: 'Envie uma mensagem ao passar por esta etapa do fluxo.',
  icon: 'comment-alt-lines',
  color: 'primary'
}, {
  type: 'waitTime',
  title: 'Tempo de Espera',
  description: 'Aguarde um período de tempo nesta etapa do fluxo.',
  icon: 'stopwatch',
  color: 'primary'
}, {
  type: 'loop',
  title: 'Tentativas',
  description: 'Tenta realizar uma ação N vezes para depois prosseguir.',
  icon: 'repeat',
  color: 'primary'
}, {
  type: 'action',
  title: 'Ação',
  description: 'Defina o que irá acontecer ao passar por esta etapa do fluxo.',
  icon: 'bolt',
  color: 'primary'
}];

// Interface base para todos os tipos de nós
export interface BaseNodeData extends Record<string, unknown> {
  type: string;
  onDelete: (nodeId: string) => void;
}

// START | END
export interface StartNodeProps extends BaseNodeData, NodeProps {
  type: 'start';
}
export interface EndNodeProps extends BaseNodeData, NodeProps {
  type: 'end';
}

// MESSAGE
export interface Button {
  id: string;
  label: string;
}
export interface MessageNodeData extends Record<string, any> {
  text: string;
  image: string;
  buttons: Button[];
}
export interface MessageNodeProps extends BaseNodeData, NodeProps {
  type: 'message';
  data: MessageNodeData;
}

// WAIT TIME
export const units = ['segundos', 'minutos', 'horas', 'dias'] as const;
export type Units = typeof units[number];
export interface WaitTimeNodeData extends Record<string, any> {
  interval: number;
  unit: Units;
}
export interface WaitTimeNodeProps extends BaseNodeData, NodeProps {
  type: 'waitTime';
  data: WaitTimeNodeData;
}

// LOOP
export interface LoopNodeData extends Record<string, any> {
  times: number;
}
export interface LoopNodeProps extends BaseNodeData, NodeProps {
  type: 'loop';
  data: LoopNodeData;
}

// União de todos os tipos de nós
export type NodesProps = StartNodeProps | EndNodeProps | MessageNodeProps | WaitTimeNodeProps | LoopNodeProps;
export type NodeData = MessageNodeData | WaitTimeNodeData | LoopNodeData;

// Tipo para nós React Flow com dados personalizados
export type CustomNode = Node<NodesProps>;