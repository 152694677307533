import React, { useState, useEffect } from 'react';
import userImg from "assets/user.png";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string; // URL da imagem principal
  placeholder?: string; // URL da imagem de placeholder
}

const Image = ({
  src,
  placeholder = userImg,
  ...rest
}: ImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleError = () => {
    setImageSrc(placeholder); // Define a imagem de fallback caso a principal falhe
  };

  return <img src={imageSrc} onError={handleError} {...rest} />;
};

export default Image;