import { CircularProgress, Skeleton } from "@mui/material";
import { Box } from "@mui/system";

const AppointmentFilesSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" width={120} className="mb-1" />

      <Box className="bg-white rounded-4 border p-3 mb-4">
        <div className="text-center">
          <CircularProgress size={21} />
        </div>
      </Box>
    </>
  );
};

export default AppointmentFilesSkeleton;
