import { useState, useEffect, useRef } from "react";
import { Modal, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav, SelectCallback } from "react-bootstrap";
import { CircularProgress } from "@mui/material";

import ModalComponent from 'components/ModalComponent';
import { useAuth } from "providers/Auth";
import { usePatient } from "providers/Patient";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { api } from "services";
import { ProfessionalCard } from "components/ProfessionalCard";
import { DoctorDataInterface, doctorSpecialtyInterface, allDoctorsResponseInterface } from "types/schedule";
import { SubTaskTab } from "./TaskTabs/SubTaskTab";
import { FilesTab } from "./TaskTabs/FilesTab";
import ModalObservations from "./TaskTabs/ModalObservations";
import TaskTab from "./TaskTabs/TaskTab";
import { ChatsTab } from "./TaskTabs/ChatsTab";
import ModalForward from "./ModalForward";
import ModalPatient from "./ModalPatient";
import ModalTag from "./ModalTag";
import { isDev } from 'utils/isDev';
import { AppointmentTab } from "./TaskTabs/AppointmentTab";

interface PropsModalTask {
  searchPatientPhone?: string;
}

function ModalTask({
  searchPatientPhone,
}: PropsModalTask) {
  const { token, userRole } = useAuth();
  const { userData } = useUser();


  const { patients,
    setPatients,
    patientData,
    setPatientData,
    patientLoading,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();

  const subtasksData = [
    { id: 1, name: "Subtask 1", completed: false },
    { id: 2, name: "Subtask 2", completed: true },
  ];

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    tags,
    setTags,
    getTags,
  } = useBoard();

  // const [doctors, setDoctors] = useState<DoctorDataInterface[]>([]);
  // const [isLoadingDoctors, setIsLoadingDoctors] = useState<boolean>(false);

  // const getDoctor = async (id: number) => {
  //   try {
  //     setIsLoadingDoctors(true);
  //     const { data } = (await api.get(`/SpecialistDoctor/list?page=1&size=1&idSpecialistDoctor=${id}`)) as {
  //       data: allDoctorsResponseInterface;
  //     };
  //     setDoctors(data.specialistDoctors);
  //     setIsLoadingDoctors(false);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const [key, setKey] = useState('dados');
  const [fullscreen, setFullscreen] = useState<true | "">('');

  const handleSelect: SelectCallback = (eventKey: string | null) => {
    if (eventKey) {
      setKey(eventKey);
    }
  };

  return (
    <>
      <Modal
        show={showAddTaskModal}
        backdrop="static"
        // centered
        size={`${editTaskData.id ? 'xl' : 'lg'}`}
        id="modal-task"
        className="bg-transparent border-0"
        onHide={() => {
          setShowAddTaskModal(false);
          setStopAutoUpdate(false);
          setKey('dados');
        }}
      >
        <Modal.Body className="p-0">
          <div className="row g-3 justify-content-center">
            <div className={`col-12 ${editTaskData.id ? 'col-lg-7' : ''}`}>
              <div className="modal-dialog modal-xl my-0 mx-auto position-sticky top-0">
                <div className="modal-content">
                  <Tab.Container defaultActiveKey="dados">
                    <Modal.Header closeButton={false} className="d-block">
                      <div className="row">
                        <div className="col">
                          <Modal.Title className="h5 m-0">{(editTaskData.id ? 'Editar' : 'Adicionar') + (taskType === 0 ? ' Paciente' : ' Task')}</Modal.Title>
                        </div>
                        <div className="col col-auto">
                          <button type="button" className="btn-close" aria-label="Close"
                            onClick={() => {
                              setShowAddTaskModal(false);
                              setStopAutoUpdate(false);
                              setKey('dados');
                            }}></button>
                        </div>
                      </div>
                      {editTaskData.id && (<>
                        <Nav variant="tabs" className="pt-2 align-items-center border-0 small scrollable" style={{ marginBottom: "calc(-1rem - 1px)" }} onSelect={handleSelect}>
                          <Nav.Item>
                            <Nav.Link className="light-tab" eventKey="dados">Dados do Card</Nav.Link>
                          </Nav.Item>
                          {editTaskData && columnsData[editTaskData.columnKey] && [4].includes(columnsData[editTaskData.columnKey].type) && (<>
                            <OverlayTrigger
                              placement="top"
                              overlay={(!editTaskData.patient || (editTaskData.patient && editTaskData.patient.id == 0)) && editTaskData.type === 0 ? <Tooltip>{'Só é possível agendar consultas para pacientes Conectadoc.'}</Tooltip> : <></>}
                            >
                              <Nav.Item>
                                <Nav.Link
                                  className={`light-tab ${(!editTaskData.patient || (editTaskData.patient && editTaskData.patient.id == 0)) && editTaskData.type === 0 ? 'disabled' : ''}`}
                                  eventKey="consulta"
                                // onClick={() => {  userData.id && getDoctor(userData.id) }}
                                >Consulta</Nav.Link>
                              </Nav.Item>
                            </OverlayTrigger>
                          </>)}
                          {isDev() && (
                            <>
                              <Nav.Item>
                                <Nav.Link className="light-tab" eventKey="atividades">Atividades <span className="badge text-bg-primary me-n2 mt-n1">{subtasksData.length}</span></Nav.Link>
                              </Nav.Item>
                            </>
                          )}
                          <Nav.Item>
                            <Nav.Link className="light-tab" eventKey="anexos">Anexos  <span className="badge text-bg-primary me-n2 mt-n1">{editTaskData.attachmentsQuantity}</span></Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link className="light-tab" eventKey="conversas">Conversas</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </>)}
                    </Modal.Header>
                    <Tab.Content>
                      <Tab.Pane eventKey="dados">
                        <TaskTab task={editTaskData} searchPatientPhone={searchPatientPhone} />
                      </Tab.Pane>
                      {editTaskData.id && (<>
                        {(columnsData[editTaskData.columnKey] && [4].includes(columnsData[editTaskData.columnKey].type) && key === 'consulta') && (
                          <Tab.Pane eventKey="consulta">
                            <Modal.Body className="bg-light px-3 pb-1">
                              <AppointmentTab id={editTaskData.appointment?.scheduleId} />

                              {/* {(editTaskData.appointment?.scheduleId && editTaskData.appointment?.date) ? (
                                <>
                                  <AppointmentTab id={editTaskData.appointment?.scheduleId} />
                                </>
                              ) : (
                                <>
                                  {isLoadingDoctors ? (<>
                                    <div className="text-center py-5">
                                      <CircularProgress size={40} />
                                    </div>
                                  </>) : (
                                    <>
                                      {doctors.map((doctor, i) => (
                                        <ProfessionalCard key={i} {...doctor} />
                                      ))}
                                    </>
                                  )}
                                </>
                              )} */}

                            </Modal.Body>
                          </Tab.Pane>
                        )}
                        {isDev() && (
                          <>
                            {key === 'atividades' && (
                              <Tab.Pane eventKey="atividades">
                                <SubTaskTab subtasksData={subtasksData} />
                              </Tab.Pane>
                            )}
                          </>
                        )}
                        {key === 'anexos' && (
                          <Tab.Pane eventKey="anexos">
                            <FilesTab />
                          </Tab.Pane>
                        )}
                        {key === 'conversas' && (
                          <Tab.Pane eventKey="conversas">
                            <ChatsTab />
                          </Tab.Pane>
                        )}
                      </>)}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
            {editTaskData.id && (<>
              <div className="col-12 col-lg-5">
                <Tab.Container defaultActiveKey={`obs-${editTaskData.id}`}>
                  <ModalObservations />
                </Tab.Container>
              </div>
            </>)}
          </div>
        </Modal.Body>
      </Modal>

      {/* modal paciente */}
      < ModalComponent
        title={(editTaskData.id ? 'Editar' : 'Cadastrar') + " Paciente"
        }
        show={showAddPatientModal}
        onHide={() => {
          setShowAddPatientModal(false);
          setStopAutoUpdate(false);
        }}
      >
        <ModalPatient
          columnId={columnId}
          updateTask={updateTask}
          setShowAddPatientModal={setShowAddPatientModal}
        />
      </ModalComponent>

      {/* modal encaminhar */}
      < ModalComponent
        title={"Encaminhar Paciente"}
        show={showForwardPatientModal}
        onHide={() => {
          setShowForwardPatientModal(false);
          setStopAutoUpdate(false);
        }}
      >
        <ModalForward
          task={editTaskData}
          onHide={() => {
            setShowForwardPatientModal(false);
            setStopAutoUpdate(false);
          }} />
      </ModalComponent>

      {/* modal tag */}
      < ModalComponent
        title={"Tag"}
        show={showModalTag}
        size="sm"
        onHide={() => {
          setShowModalTag(false);
          setStopAutoUpdate(false);
        }}
      >
        <ModalTag onHide={() => {
          setShowModalTag(false);
          setStopAutoUpdate(false);
        }} />
      </ModalComponent>
    </>
  );
};

export default ModalTask;
