import { FileToDownload } from "../components/FileToDownload";

interface File {
  id: number;
  nome: string;
  tipo: string;
  url: string;
  tipoDocumento: number;
  dataUpload: string;
}

interface AppointmentFilesProps {
  title: string;
  specificFiles: File[];
}

const AppointmentFiles = ({ title, specificFiles }: AppointmentFilesProps) => {
  return (
    <>
      <h6 className="text-muted text-uppercase small">
        <span>{title}: </span>
        {!!specificFiles?.length && (
          <span className="text-muted small">
            ({specificFiles.length} arquivo
            {specificFiles.length > 1 && "s"})
          </span>
        )}
      </h6>

      <div className="bg-white rounded-4 border p-3 mb-4 overflow-files">
        {!specificFiles?.length ? (
          <p className="mb-0">Nenhum anexo encontrado</p>
        ) : (
          specificFiles.map((file) => (
            <FileToDownload key={file.id} {...file} />
          ))
        )}
      </div>
    </>
  );
};

export default AppointmentFiles;
