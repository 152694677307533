import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomNode, WaitTimeNodeData, WaitTimeNodeProps, units, type Units } from "../utils/nodeTypes";

interface WaitTimeFormProps {
  node: CustomNode;
  onUpdate: (id: string, data: WaitTimeNodeData) => void;
}

const validationSchema = Yup.object().shape({
  interval: Yup.string().required('O intervalo é obrigatório'),
  unit: Yup.mixed<Units>().oneOf([...units], 'Unidade inválida'),
});

const WaitTimeForm = ({ node, onUpdate }: WaitTimeFormProps) => {

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<WaitTimeNodeData>({
    resolver: yupResolver(validationSchema),
    defaultValues: node.data
  });

  // UseEffect para pré-carregar os valores de `node` quando o componente é montado ou o `node` muda
  useEffect(() => {
    if (node) {
      console.log(node.data);

      reset({
        interval: node.data.interval || '',
        unit: node.data.unit || '',
      } as WaitTimeNodeData);
    }
  }, [node.data, reset]);

  // const onSubmit = useCallback((data: WaitTimeNodeData) => {
  //   onUpdate(node.id, data);
  // }, [node.id, onUpdate]);

  const onSubmit = (data: WaitTimeNodeData) => {
    onUpdate(node.id, data);
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3">
        <div className="mb-3">
          <label className="form-label">Intervalo</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            min={1}
            {...register('interval')}
          />
          {errors.interval && <div className="text-start text-danger"><small>{errors.interval.message}</small></div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Unidade</label>
          <select className="form-select" {...register("unit")}>
            {units.map((unit) => (
              <option key={unit} value={unit}>
                {unit}
              </option>
            ))}
          </select>
          {errors.unit && <div className="text-start text-danger"><small>{errors.unit.message}</small></div>}
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-primary rounded-pill">Salvar</button>
        </div>
      </div>

    </form>
  );
};

export default WaitTimeForm;