import { useState } from "react";
import {
  Handle,
  Position,
  NodeProps,
} from "@xyflow/react";
import { CustomNode, MessageNodeProps, Button } from "../utils/nodeTypes";

const MessageNode = ({ data, onDelete, ...props }: MessageNodeProps) => {
// const MessageNode = ({ data, ...props }: { data: MessageNodeProps, onDelete: (nodeId: string) => void }) => {
  const { text, image, buttons } = data; // Desestruturando a propriedade 'data' de 'MessageNodeProps'

  return (
    <div className={`card message-node ${props.selected ? 'border-primary shadow' : ''}`}>
      <div className="rounded-top bg-light border-bottom py-1 px-2 position-relative">
      <Handle id={`from-${props.id}`} type="target" position={Position.Left} className={`${props.selected ? 'border-primary' : ''}`} />
        <div className="row gx-2">
          <div className="col col-auto">
            <i className="uil uil-comment-alt-lines text-primary"></i>
          </div>
          <div className="col fw-medium">
            <strong>Mensagem</strong>
          </div>
          {props.selected && (
            <div className="col col-auto">
              {/* Botão de excluir o MessageNode */}
              <button
                onClick={() => onDelete(props.id)} // Chama a função onDelete ao clicar
                className="btn btn-light text-danger btn-sm rounded p-1 lh-1 my-n1"
              >
                <i className="uil uil-trash"></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="p-2">
        {data.image && (
          <>
          <div className="border-bottom mb-2 pb-2 mx-n2 px-2">
            <img src={data.image} alt="Message" className="img-fluid" />
          </div>
          </>
        )}
        <div className="p-2 rounded bg-light mb-2">
          <p className="small message-body mb-0 lh-sm">{data.text}</p>
        </div>
        <div className="d-flex gap-2 flex-column align-items-end me-n2">
          {buttons.map((btn: Button, index: number) => (
            <div key={index} className="position-relative pe-2">
              <div className="rounded-pill border px-2 small">
                {btn.label}
              </div>
              <Handle
                type="source"
                position={Position.Right}
                id={`button-${btn.id}`}
                className={`${props.selected ? 'border-primary' : ''}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="rounded-bottom bg-light border-top py-1 px-2">
        <p className="mb-0 text-center small">Não respondeu</p>
        <Handle id={`no-answer-${props.id}`} type="source" position={Position.Bottom} className={`${props.selected ? 'border-primary' : ''}`}/>
      </div>
    </div>
  );
};

export default MessageNode;