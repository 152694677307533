import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import Select, { StylesConfig, Theme } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { hsvaToHex, hexToRgba, rgbaToRgb, getContrastingColor } from '@uiw/color-convert';


export interface SelectOptions {
  value: number | string;
  label: string;
  color?: string;
  isFixed?: boolean;
  isDisabled?: boolean;
  icon?: string;
}

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
    * multiValue(remove)/color:hover
    */
    danger: "var(--bs-danger)",
    /*
    * multiValue(remove)/backgroundColor(focused)
    * multiValue(remove)/backgroundColor:hover
    */
    dangerLight: "var(--danger-red-100)",
    /*
    * control/backgroundColor
    * menu/backgroundColor
    * option/color(selected)
    */
    neutral0: "var(--neutral-0)",
    /*
    * control/backgroundColor(disabled)
    */
    neutral5: "var(--neutral-5)",
    /*
    * control/borderColor(disabled)
    * multiValue/backgroundColor
    * indicators(separator)/backgroundColor(disabled)
    */
    neutral10: "var(--neutral-10)",
    /*
    * control/borderColor
    * option/color(disabled)
    * indicators/color
    * indicators(separator)/backgroundColor
    * indicators(loading)/color
    */
    neutral20: "var(--neutral-20)",
    /*
    * control/borderColor(focused)
    * control/borderColor:hover
    */
    neutral30: "var(--neutral-30)",
    /*
    * menu(notice)/color
    * singleValue/color(disabled)
    * indicators/color:hover
    */
    neutral40: "var(--neutral-40)",
    /*
    * placeholder/color
    */
    neutral50: "var(--neutral-50)",
    /*
    * indicators/color(focused)
    * indicators(loading)/color(focused)
    */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
    * input/color
    * multiValue(label)/color
    * singleValue/color
    * indicators/color(focused)
    * indicators/color:hover(focused)
    */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
    * control/boxShadow(focused)
    * control/borderColor(focused)
    * control/borderColor:hover(focused)
    * option/backgroundColor(selected)
    * option/backgroundColor:active(selected)
    */
    primary: "var(--primary)",
    /*
    * option/backgroundColor(focused)
    */
    primary25: "var(--primary-25)",
    /*
    * option/backgroundColor:active
    */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

interface SelectTagProps {
  control?: any;
  name: string;
  options?: any;
  label?: string;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isMulti?: true;
  defaultValue?: any;
  closeMenuOnSelect?: boolean;
  // onCreateOption?:(inputValue:string) => void;
  error?: string;
  handleChange?: (e: any) => void;
}

const SelectTag = ({
  control,
  label,
  name,
  error,
  options,
  handleChange,
  isDisabled,
  closeMenuOnSelect = false,
  ...rest
}: SelectTagProps, ref: any) => {

  const isWhite = (color: string) => {
    return (color == "#ffffff" || color == "#FFFFFF" || color == "#fff" || color == "#FFF")
  }

  const tagsStyles: StylesConfig<any, true> = {
    control: (styles, state) => ({ ...styles, backgroundColor: 'var(--bs-body-bg)', borderColor: state.isFocused ? 'var(--bs-primary)' : 'var(--bs-border-color)', boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25)' : '', }),
    menu: (styles, state) => ({ ...styles, backgroundColor: 'var(--bs-body-bg)', borderColor: 'var(--bs-border-color)' }),
    clearIndicator: (styles, state) => ({ ...styles, color: 'var(--neutral-50)' }),
    dropdownIndicator: (styles, state) => ({ ...styles, color: 'var(--neutral-50)' }),
    indicatorSeparator: (styles, state) => ({ ...styles, backgroundColor: 'var(--bs-border-color)' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      var rgbString = undefined;
      var rgbColor = color ? rgbaToRgb(hexToRgba(color)) : undefined;
      if (rgbColor) {
        rgbString = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b},.1)`;
      }
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? (color && !isWhite(color) ? color : 'var(--bs-primary)')
            : isFocused
              ? (rgbString && !isWhite(color) ? rgbString : 'var(--bs-indigo-100)')
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? getContrastingColor(color)
            : (color && !isWhite(color) ? color : 'var(--bs-body-color)'),
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? color
              : rgbString ? rgbString.replace(',.1', ',.3') : undefined
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: (color ? color : 'var(--bs-gray-200)'),
      };
    },
    multiValueLabel: (styles, { data }) => {
      const color = data.color;
      return {
        ...styles,
        color: getContrastingColor(color),
      }
    },
    multiValueRemove: (styles, { data }) => {
      const color = data.color;
      var rgbString = `0,0,0`;
      return {
        ...styles,
        color: 'rgba(255,255,255,.5)',
        ':hover': {
          backgroundColor: `rgba(${rgbString},.1)`,
          color: 'white',
        },
        ':focus': {
          backgroundColor: `rgba(${rgbString},.4)`,
          color: 'white',
        },
      }
    },
  };

  return (
    <>
      {label && label != "" && (
        <label>{label}</label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...rest}
            theme={customTheme}
            styles={tagsStyles}
            closeMenuOnSelect={closeMenuOnSelect}
            value={field.value}
            isDisabled={isDisabled}
            options={options}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "Nenhum resultado encontrado para: " + inputValue : null
            }
            onChange={(e) => {
              field.onChange(e);
              if (handleChange) {
                if (e) {
                  handleChange(e);
                } else {
                  handleChange(-1);
                }
              }
            }}
          />
        )}
      />

      {!!error && (
        <div className="text-start text-danger">
          <small>{error}</small>
        </div>
      )}
    </>
  );
};

export default forwardRef(SelectTag);
