import { Box, Skeleton, Typography } from "@mui/material";

const ConsultaCardSkeleton = () => {
  return (
    <Box className="card p-3 mb-2">
      <Box className="row align-items-center">
        <Box className="col-4 col-md-1 col-lg-1 text-end border-end">
          <Skeleton variant="text" className="text-muted mb-0 lh-1" />
          <Skeleton variant="text" className="fw-bold h5 mb-0 lh-1" />
          <Skeleton variant="text" className="small mb-0 lh-1" />
        </Box>

        <Box className="col-8 col-md-2 col-lg-1 text-center">
          <Skeleton variant="text" className="fw-medium mb-0" />
        </Box>

        <Box className="col-12 col-md-4 col-lg-3 mt-3 mt-md-0 border-top border-md-top-0">
          <Box className="row align-items-center justify-content-center pt-3 pt-md-0">
            <Box className="col-4 text-end">
              <Skeleton variant="circular" className="img-wrap" />
            </Box>
            <Box className="col-8 pl-0 text-left text-md-left">
              <Typography variant="h5">
                <Skeleton variant="text" className="fw-medium mb-0" />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="col-12 col-md-5 col-lg-3">
          <Box className="row align-items-center justify-content-center pt-3 pt-md-0">
            <Box className="col-4 col-lg-3 text-end pe-0">
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="ms-auto"
              />
            </Box>
            <Box className="col-8 col-lg-9 pl-0 text-left text-md-left">
              <Skeleton variant="text" className="fw-medium mb-0" />
              <Skeleton variant="text" className="small lh-1 mb-0" />
            </Box>
          </Box>
        </Box>

        <Box className="col-12 col-lg-4 pt-3 pt-lg-0 text-center border-top mt-3 border-lg-top-0 mt-lg-0 d-flex flex-wrap gap-2 justify-content-center">
          <Skeleton
            variant="text"
            width={120}
            height={50}
            sx={{ bgcolor: "primary.main", borderRadius: "1.2rem" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ConsultaCardSkeleton;
