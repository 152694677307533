import axios, { AxiosResponse } from "axios";
import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { RoleDescription } from "types/enums";
import { UserEditData, User, UserAdminEditData } from "types/user";
import { toast } from "utils/toast";
import { API_URL } from "../../config";

interface UserProviderProps {
  children: ReactNode;
}

interface UserProviderData {
  userData: User;
  isLoading: boolean;
}

export const UserContext = createContext<UserProviderData>(
  {} as UserProviderData
);

export const UserProvider = ({ children }: UserProviderProps) => {
  const { token, userId, userRole } = useAuth();
  const [userData, setUserData] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = async () => {

    try {
      // const response = await api.get(`/Professional/${userId}`);
      const response = await axios.get( 
        `${API_URL}SpecialistDoctor/id?id=${userId}`,
        {headers: { Authorization: `Bearer ${token}` }}
      );

      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  return (
    <UserContext.Provider
      value={{
        userData,
        isLoading,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);