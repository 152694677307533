import ContainerAnimation from "components/ContainerAnimation";
import { useEffect } from "react";
import { useAuth } from "providers/Auth";
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";

import LoginForm from "./components/LoginForm";
import Footer from "components/Footer";
import imgGirl from "assets/medico.png";
import imgComputer from "assets/computer.png";
import imgLinha from "assets/gestao.png";

const Login = () => {
  useEffect(() => { document.title = 'Login | CRM - Conectadoc'; }, []);

  const navigate = useNavigate();
  const { isLoading, tokenLogin } = useAuth();
  
  const { token } = useParams();
  if(token){
    tokenLogin(token, navigate);
  }

  return (
    <ContainerAnimation className="content p-0">
      <div className="row g-0 min-vh-100">
        <div className="col-12 col-lg-6 bg-small-login d-flex flex-column">
          <div className="flex-grow-1 d-flex align-items-center justify-content-center px-3 py-5">
            <LoginForm />
          </div>
          <Footer/>
        </div>
        <div className="col-lg-6 bg-gradient-login px-5 d-none d-lg-flex align-items-center justify-content-center">
          <div className="bg-image-login position-relative d-flex ps-5 pt-5 shadow-lg">
            <div className="col col-2 pb-5">
              <img src={imgLinha} className="img-fluid animated slideInLeft" alt="Linhas de Cuidado"/>
            </div>
            <div className="col d-flex flex-column">
              <div className="icon-animation">
                <div className="circle shadow-lg">
                  <i className="uil uil-lock fs-1 lh-1 text-primary"></i>
                </div>
              </div>
              <div className="ps-4">
                <h3 className="mb-4 pe-4 text-pure-white fw-light animated slideInDown">Centralize todas as informações dos pacientes em uma única plataforma.</h3>
              </div>
              <div className="flex-grow-1">
                <img src={imgComputer} className="position-absolute animated slideInUp" style={{width:'90%',left: '26%'}}/>
                {/* <img src={imgGirl} className="position-absolute animated slideInUp" style={{width:'65%',left: '30%'}}/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerAnimation>
  );
};

export default Login;
