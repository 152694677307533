import {
  Handle,
  Position,
} from "@xyflow/react";
import { EndNodeProps } from "../utils/nodeTypes";

const EndNode = ({ data, onDelete, ...props }: EndNodeProps) => {
  return (
    <div className={`end-node card text-bg-danger rounded-pill px-2 d-flex flex-row position-relative ${props.selected ? 'border-primary shadow' : ''}`}>
      {props.selected && (
        <div className="position-absolute bottom-100 start-50 translate-middle-x mb-2">
          {/* Botão de excluir o MessageNode */}
          <button
            onClick={() => onDelete(props.id)} // Chama a função onDelete ao clicar
            className="btn btn-light text-danger btn-sm rounded p-1 lh-1 my-n1"
          >
            <i className="uil uil-trash"></i>
          </button>
        </div>
      )}
      <i className="uil uil-square me-1"></i>
      Fim
      <Handle id={`end-${props.id}`} type="target" position={Position.Left} className="top-50 start-0 translate-middle" />
    </div>
  );
};

export default EndNode;