import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomNode, MessageNodeData, MessageNodeProps } from "../utils/nodeTypes";
import { TextareaAutosize } from '@mui/material';

interface MessageFormProps {
  node: CustomNode;
  onUpdate: (id: string, data: MessageNodeData) => void;
}

const validationSchema = Yup.object().shape({
  text: Yup.string().required('A mensagem é obrigatória'),
  image: Yup.string().url('Deve ser uma URL válida'),
  buttons: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required('O texto do botão é obrigatório'),
    })
  )
});

const MessageForm = ({ node, onUpdate }: MessageFormProps) => {

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<MessageNodeData>({
    resolver: yupResolver(validationSchema),
    defaultValues: node.data
  });

  // useFieldArray para manipular o array de buttons
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'buttons',
  });

  // UseEffect para pré-carregar os valores de `node` quando o componente é montado ou o `node` muda
  useEffect(() => {
    if (node) {
      console.log(node.data);

      reset({
        text: node.data.text || '',
        image: node.data.image || '',
        buttons: node.data.buttons || [{ label: '' }],
      } as MessageNodeData);
    }
  }, [node.data, reset]);

  // const onSubmit = useCallback((data: MessageNodeData) => {
  //   onUpdate(node.id, data);
  // }, [node.id, onUpdate]);

  const onSubmit = (data: MessageNodeData) => {
    onUpdate(node.id, data);
    console.log(data);
  };

  // Função para adicionar um novo botão, até o limite de 3
  const handleAddButton = () => {
    if (fields.length < 3) {
      append({ label: `` });
    }
  };

  // Função para remover um botão pelo índice
  const handleRemoveButton = (index: number) => {
    remove(index);
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3">
        <div className="mb-3">
          <label className="form-label">Mensagem</label>
          <TextareaAutosize
            className="form-control"
            placeholder="Texto da mensagem que será enviada..."
            minRows={1}
            {...register('text')}
          ></TextareaAutosize>
          {errors.text && <div className="text-start text-danger"><small>{errors.text.message}</small></div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Imagem</label>
          <input {...register('image')} className='form-control' placeholder="URL da imagem" />
          {errors.image && <div className="text-start text-danger"><small>{errors.image.message}</small></div>}
        </div>
        <div className="mb-3">
          <div className="row g-2 align-items-end">
            <div className="col">
              <label className="form-label">Botões</label>
            </div>
            <div className="col col-auto pb-2">
              <button
                type="button"
                className="btn btn-sm lh-1 btn-outline-primary rounded-pill"
                onClick={handleAddButton} // Adicionar um novo btn
                disabled={fields.length < 3 ? false : true}
              >
                <i className="uil uil-plus"></i>Adicionar
              </button>
            </div>
          </div>
          {fields.map((item, index) => (
            <div key={item.id} className="row g-2 align-items-center mb-2">
              <div className="col">
                <Controller
                  name={`buttons.${index}.label`}
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      className="form-control"
                      placeholder="Texto do Botão"
                    />
                  )}
                />
              </div>
              <div className="col col-auto">
                <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => { handleRemoveButton(index) }}>
                  <i className="uil uil-trash"></i>
                </button>
              </div>
              {errors.buttons?.[index]?.label && (
                <div className="text-start text-danger"><small>{errors.buttons?.[index]?.label?.message}</small></div>
              )}

            </div>
          ))}
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-primary rounded-pill">Salvar</button>
        </div>
      </div>

    </form>
  );
};

export default MessageForm;