import Button from "components/Button";
import { useUser } from "providers/User";
import { useDarkMode } from "providers/DarkMode";
import { useWhatsapp } from "providers/Whatsapp";
import { useBoard } from "providers/Board";
import { Chat } from "types/whatsapp";
import Image from "components/Image";
import userImg from "assets/user.png";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

interface ChatHeaderProps {
  handleDeleteChat: (id: string) => void;
}

const ChatHeader = ({ handleDeleteChat }: ChatHeaderProps) => {

  const {
    showLeadModal, 
    setShowLeadModal
  } = useBoard();
  
  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages,
    deleteChat,
    isSendingMessages
  } = useWhatsapp();

  return (
    <>
      <div className="row py-2 px-3 align-items-center g-0 flex-nowrap rounded-top bg-primary">
        <div className="col col-auto">
          <Image src={activeChat.sentOrReceived ? (activeChat.senderImageURL ?? userImg) : (activeChat.senderImageURL ?? userImg)} alt={activeChat.sentOrReceived ? (activeChat.toName && activeChat.toName != "" ? activeChat.toName : activeChat.toId) : (activeChat.fromName && activeChat.fromName != "" ? activeChat.fromName : activeChat.fromId)} className="avatar img-fluid rounded-circle me-2" />
        </div>
        <div className="col d-flex flex-column">
          <div className="text-bg-primary text-truncate font-weight-bold">{activeChat.sentOrReceived ? (activeChat.toName && activeChat.toName != "" ? activeChat.toName : activeChat.toId) : (activeChat.fromName && activeChat.fromName != "" ? activeChat.fromName : activeChat.fromId)}</div>
          {/* <div className="text-bg-primary text-truncate small lh-1">Clique aqui para mais informações.</div> */}
        </div>
        <div className="col col-auto d-flex flex-row gap-3 align-items-center ms-auto">
          <Button type="button" onClick={() => { setShowLeadModal(true); }} className="btn-outline-light rounded-pill py-2 lh-1">
            Transformar em Lead
          </Button>
          <Dropdown>
            <Dropdown.Toggle variant="outline-light" className="no-arrow rounded-circle lh-1 p-2">
              <i className="uil uil-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="p-0" style={{ maxHeight: "initial" }}>
              <Dropdown.Item role="button" className="text-danger" as="a" onClick={() => { handleDeleteChat(activeChat.sentOrReceived ? activeChat.toId : activeChat.fromId); }} >
                <i className="uil uil-trash me-2"></i>Remover
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
