import { Skeleton } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { api } from "services";

interface File {
  id: number;
  fileName: string;
  addressSrc: string;
}

interface ExamDetails {
  id: number;
  legacyId: number;
  title: string;
  type: number;
  date: string;
  specialistDoctorName: string;
  attendanceId: number;
  files: File[];
}

interface ExameCardProps {
  exam: any;
}

const ExameCard = ({ exam }: ExameCardProps) => {
  const [examDetails, setExamDetails] = useState({} as ExamDetails);
  const [examTypeName, setExamTypeName] = useState("");

  useEffect(() => {
    const getExam = async () => {
      try {
        const { data } = await api.get(`/Exams/${exam.id}`);

        setExamDetails(data);
      } catch (error) {
        console.log(error);
      }
    };

    const examType = (type: number) => {
      switch (type) {
        case 0:
          setExamTypeName("Sem tipo");
          break;
        case 1:
          setExamTypeName("Exame de Sangue");
          break;
        case 2:
          setExamTypeName("Exame de Imagem");
          break;
        case 3:
          setExamTypeName("Exame de Urina");
          break;
        case 4:
          setExamTypeName("Exame de Fezes");
          break;
        default:
          setExamTypeName("Outros");
          break;
      }
    };

    getExam();
    examType(exam.type);
  }, []);

  return (
    <div className="card p-3 mb-2">
      <div className="row align-items-center">
        <div className="col-4 col-lg-3 text-muted small fw-medium">
          <span>
            {!!Object.keys(examDetails).length ? (
              moment(examDetails.date).format("DD/MM/YYYY")
            ) : (
              <Skeleton variant="text" width={"5rem"} />
            )}
          </span>
          <span className="d-block">
            {!!Object.keys(examDetails).length ? (
              moment(examDetails.date).format("HH:mm")
            ) : (
              <Skeleton variant="text" width={"3rem"} />
            )}
          </span>
        </div>
        <div className="col-4 col-lg-3 fw-medium text-muted small">
          <span>
            {!!Object.keys(examDetails).length ? (
              examTypeName
            ) : (
              <Skeleton variant="text" width={"5rem"} />
            )}
          </span>
        </div>
        <div className="col-4 col-lg-3 fw-medium text-muted small">
          <span>
            {!!Object.keys(examDetails).length ? (
              examDetails.files[0].fileName
            ) : (
              <Skeleton variant="text" width={"5rem"} />
            )}
          </span>
        </div>
        <div className="col-12 col-lg-3 border-top border-lg-top-0 text-center mt-3 mt-lg-0 pt-3 pt-lg-0">
          {!!Object.keys(examDetails).length ? (
            <a
              href={examDetails?.files[0].addressSrc}
              download
              className="text-nowrap btn btn-sm btn-primary"
            >
              <i className="las la-download me-2"></i>
              DOWNLOAD
            </a>
          ) : (
            <Skeleton
              variant="text"
              className="d-block mx-auto"
              width={90}
              height={50}
              sx={{ bgcolor: "primary.main", borderRadius: "1.2rem" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ExameCard;
