import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import { api } from "services";
import { toast } from "utils/toast";

interface FeedbackProviderProps {
  children: ReactNode;
}

interface FeedbackProviderData {
  showFeedbackModal: boolean;
  setShowFeedbackModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedbackContext = createContext<FeedbackProviderData>(
  {} as FeedbackProviderData
);

export const FeedbackProvider = ({
  children,
}: FeedbackProviderProps) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  return (
    <FeedbackContext.Provider
      value={{
        showFeedbackModal,
        setShowFeedbackModal,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => useContext(FeedbackContext);
