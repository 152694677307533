import { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { ButtonGroup, Modal, ToggleButton, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectTag from 'components/SelectTag';
import { ModalFile } from 'components/ModalFile';
import { useAuth } from "providers/Auth";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault } from "types/board";
import { api } from "services";
import Swal from "sweetalert2";
import { toast } from "utils/toast";
import logger from "utils/logger";
import { isDev } from 'utils/isDev';
import { useWhatsapp } from "providers/Whatsapp";
import { Chat, Message, GroupedMessages, NewMessage } from "types/whatsapp";
import ChatMessages from "pages/Chats/components/ChatMessages";
import ChatInput from "pages/Chats/components/ChatInput";
import Image from "components/Image";
import userImg from "assets/user.png";
import { handleMobilePhoneInput } from "utils/handleInput";

interface PropsChatsTab {
  // subtasksData: File[];
}
interface NewWhats {
  mobilePhone?: string;
  type?: string;
}

export function ChatsTab({
  // subtasksData,
}: PropsChatsTab) {
  const { token, userRole, loginQuick } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const {
    editTaskData,
    tasksData,
    isLoading,
    updateTask,
    handleTaskModal
  } = useBoard();

  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages,
    deleteChat,
    isSendingMessages
  } = useWhatsapp();

  useEffect(() => {

    checkInstance();

    if (conectedWhatsapp && editTaskData.patient?.whatsAppId) {
      getMessages(editTaskData.patient?.whatsAppId);
    }
  }, [conectedWhatsapp, editTaskData.patient?.whatsAppId]);

  const [selectedType, setSelectedType] = useState<string | null>(null);

  const schemaWhats = yup.object().shape({
    mobilePhone: yup.string().required("*Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setFocus,
    control,
    reset,
    formState: { errors },
  } = useForm<NewWhats>({
    resolver: yupResolver(schemaWhats),
    defaultValues: {
      mobilePhone: editTaskData.patient?.mobilePhone ?? ""
    }
  });

  const saveWhats = (whats: string) => {
    if (whats && whats != "") {
      var taskKey = Number(Object.keys(tasksData).find((x: any) => tasksData[x].id === editTaskData.id));

      var newPatient = tasksData[taskKey]?.patient ?? undefined;
      newPatient = {
        ...newPatient,
        whatsAppId: whats
      };

      var newTask = {
        ...tasksData[taskKey],
        patient: newPatient
      }

      updateTask(newTask, () => {
        handleTaskModal(true, tasksData[taskKey]);
      });

    } else {
      toast.fire({
        icon: "error",
        title: "Informe ou selecione o número corretamente.",
      });
    }
  }


  const onSubmit = (data: NewWhats) => {
    // Lógica de envio
    var whats = data.mobilePhone;
    if (whats) {
      whats = whats.replace(/[^\d]/g, '');
      if(whats.length === 11){
        whats = whats.slice(0, 2) + whats.slice(3)
      }
      debugger;
      saveWhats("55" + whats);
    }
  };

  return (
    <>

      {!conectedWhatsapp ? (
        <Modal.Body className="bg-light px-3 pb-1">
          <div className="text-center py-5">
            <h4>
              Você precisa conectar o WhastApp para conseguir receber e enviar mensagens.
            </h4>
            <p className="pt-3">
              Acesse o menu Integrações para conectar o WhatsApp!
            </p>
            <p className="pt-3"><a role="button" className="btn btn-primary rounded-pill" onClick={() => { navigate('/integracoes') }}><i className="uil uil-server-connection me-2"></i>Integrações</a></p>
          </div>
        </Modal.Body>
      ) : (
        <>
          {editTaskData.patient?.whatsAppId ? (
            <>
              <Modal.Body className="bg-light p-0">
                {isLoadingWhatsapp || isLoadingChats ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center h-100 py-5">
                      <CircularProgress size={40} />
                    </div>
                  </>
                ) : (
                  <>
                    <ChatMessages chatMessages={messages} className="overflow-y-auto" style={{ maxHeight: '60vh' }} />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer className="flex-nowrap p-0 gap-0">
                <ChatInput chatId={editTaskData.patient?.whatsAppId} />
              </Modal.Footer>
            </>
          ) : (
            <Modal.Body className="bg-light rounded-bottom">
              <div className="text-center py-5">
                {isLoading ? (
                  <>
                    <CircularProgress size={40} />
                  </>
                ) : (
                  <>
                    <p>Nenhum número de <b>WhatsApp</b> atrelado a este card.</p>
                    <p className="fw-bold">Selecione como deseja prosseguir:</p>
                    <ButtonGroup className="d-flex flex-row justify-content-center mb-3">
                      <ToggleButton
                        id={`radio-chat`}
                        type="radio"
                        variant={'outline-primary'}
                        className={`text-center col-4 p-2`}
                        value={'chat'}
                        {...register("type")}
                        checked={selectedType === 'chat'}
                        style={{ maxWidth: 180 }}
                        onChange={(e) => { getChats(); setSelectedType(e.currentTarget.value); }}
                      >
                        <i className="uil uil uil-comments-alt fs-4"></i>
                        <p className="mb-0 lh-1">Selecionar uma conversa</p>
                      </ToggleButton>
                      <ToggleButton
                        id={`radio-phone`}
                        type="radio"
                        variant={'outline-primary'}
                        className={`text-center col-4 p-2`}
                        value={'phone'}
                        {...register("type")}
                        checked={selectedType === 'phone'}
                        style={{ maxWidth: 180 }}
                        onChange={(e) => { setSelectedType(e.currentTarget.value); }}
                      >
                        <i className="uil uil-dialpad-alt fs-4"></i>
                        <p className="mb-0 lh-1">Inserir o número manualmente</p>
                      </ToggleButton>
                    </ButtonGroup>
                    {selectedType === "chat" && (<>
                      <div className="list-group overflow-auto shadow" style={{ maxHeight: "30vh" }}>
                        {isLoadingChats ? (<>
                          <div className="list-group-item text-center py-4">
                            <CircularProgress size={40} />
                          </div>
                        </>) : (<>
                          {chats && chats.length > 0 ? (<>
                            {chats.map((chat, index) => (
                              <li key={index} style={{ zIndex: "initial!important" }} className={`list-group-item list-group-item-action text-start`}>
                                <div className="position-relative content-hover">
                                  <a className="stretched-link" role="button"
                                    onClick={() => {
                                      saveWhats(chat.sentOrReceived ? chat.toId : chat.fromId);
                                    }}></a>
                                  <div className="row g-0">
                                    <div className="col col-auto position-relative">
                                      <span className={`badge bg-whatsapp px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-whatsapp`}></i></span>
                                      {/* <span className={`badge bg-${chat.origin == "WA" ? 'whatsapp' : 'instagram'} px-1 rounded-circle position-absolute bottom-0 end-0 me-2`}><i className={`uil uil-${chat.origin == "WA" ? 'whatsapp' : 'instagram'}`}></i></span> */}
                                      <Image src={chat.sentOrReceived ? (chat.senderImageURL ?? userImg) : (chat.senderImageURL ?? userImg)} alt={chat.sentOrReceived ? (chat.toName && chat.toName != "" ? chat.toName : chat.toId) : (chat.fromName && chat.fromName != "" ? chat.fromName : chat.fromId)} className="avatar img-fluid rounded-circle me-3" />
                                    </div>
                                    <div className="col">
                                      <div className="row">
                                        <div className="col">
                                          <div className="name text-truncate">{chat.sentOrReceived ? (chat.toName && chat.toName != "" ? chat.toName : chat.toId) : (chat.fromName && chat.fromName != "" ? chat.fromName : chat.fromId)}</div>
                                        </div>
                                        <div className="col col-auto">
                                          <div className="text-10 opacity-50">{moment(chat.date).format('DD/MM/YYYY HH:mm')}</div>
                                        </div>
                                      </div>
                                      <div className="small text-truncate last-message pe-4">
                                        {chat.message ? chat.message : "(imagem ou vídeo)"}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </li>
                            ))}
                          </>) : (<>
                            <div className="list-group-item text-center small py-4">
                              <p className="mb-0">Nenhuma conversa encontrada.</p>
                            </div>
                          </>)}
                        </>)}
                      </div>
                    </>)}
                    {selectedType === "phone" && (<>
                      <form onSubmit={handleSubmit(onSubmit)} className="d-flex gap-3">
                        <div className="col">
                          <Input
                            containerClassName="form-floating"
                            type="tel"
                            label="Telefone: (99) 99999-9999"
                            placeholder="(99) 99999-9999"
                            maxLength={15}
                            register={register("mobilePhone")}
                            error={errors?.mobilePhone?.message}
                            onChange={(e) =>
                              handleMobilePhoneInput(e, setValue, "mobilePhone")
                            }
                            autoComplete="off"
                          />
                        </div>
                        <button type="submit" className="btn btn-primary"><i className="uil uil-mobile-android me-2"></i>Salvar Telefone</button>
                      </form>
                    </>)}
                  </>
                )}
              </div>
            </Modal.Body>
          )}
        </>
      )}

    </>
  );
};
