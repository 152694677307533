import { InputHTMLAttributes, useState, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegisterReturn;
  label?: string;
  error?: string;
  isPasswordInput?: boolean;
  containerClassName?: string;
  type?: string;
  isPriceField?: boolean;
  isFeeField?: boolean;
  labelClassName?: string;
}

const Input = ({
  register,
  label,
  error,
  isPasswordInput,
  containerClassName,
  isPriceField,
  isFeeField,
  type = "text",
  labelClassName,
  ...rest
}: InputProps, ref: any) => {
  const [isShowingPassword, setIsShowingPassword] = useState(() =>
    isPasswordInput ? false : true
  );

  const togglePasswordVisiblity = () => {
    setIsShowingPassword(!isShowingPassword);
  };

  const containerClasses = containerClassName ? containerClassName.split(" ") : [];

  if (containerClasses.includes("form-floating")) {
    return (
      <div className={containerClassName}>
        <input
          {...register}
          {...rest}
          type={isShowingPassword ? type : "password"}
          className={`form-control ${error ? "is-invalid" : ""} ${rest.className ?? ""}`}
        />
        {label && (
          <label htmlFor={rest.name ?? ""} className={(labelClassName ?? "")}>
            {label}
          </label>
        )}
        {isPasswordInput && (
          <div
            role="button"
            className="fs-4 position-absolute top-50 end-0 translate-middle-y pointer-event"
            onClick={togglePasswordVisiblity}
          >
            {isShowingPassword ? (
              <i className="uil uil-eye-slash pe-3" />
            ) : (
              <i className="uil uil-eye pe-3" />
            )}
          </div>
        )}
        {!!error && (
          <div className="text-start text-danger">
            <small>{error}</small>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={containerClassName}>
        {label && (
          <label htmlFor={rest.name ?? ""} className={"mb-2 " + (labelClassName ?? "")}>
            {label}
          </label>
        )}

        <div className={isPriceField || isFeeField ? "row align-items-center g-0 flex-nowrap" : ""}>
          {!!isPriceField && <div className="col col-auto pe-2">R$</div>}

          <div className={`position-relative ${isPriceField || isFeeField ? "col" : ""}`}>
            <input
              {...register}
              {...rest}
              type={isShowingPassword ? type : "password"}
              className={`form-control ${rest.className ?? ""} ${error ? "is-invalid" : ""} ${type === "search" ? "border-0" : ""}`}
            />

            {isPasswordInput && (
              <div
                role="button"
                className="fs-4 position-absolute top-50 end-0 translate-middle-y pointer-event"
                onClick={togglePasswordVisiblity}
              >
                {isShowingPassword ? (
                  <i className="uil uil-eye-slash pe-3" />
                ) : (
                  <i className="uil uil-eye pe-3" />
                )}
              </div>
            )}
          </div>

          {!!isFeeField && <div className="col col-auto ps-2">%</div>}
        </div>

        {!!error && (
          <div className="text-start text-danger">
            <small>{error}</small>
          </div>
        )}
      </div>
    );
  }

};

export default forwardRef(Input);
