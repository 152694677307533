import { useState, useEffect, useRef } from "react";
import Button from "components/Button";
import { useUser } from "providers/User";
import { useDarkMode } from "providers/DarkMode";
import { useWhatsapp } from "providers/Whatsapp";
import { useBoard } from "providers/Board";
import Image from "components/Image";
import userImg from "assets/user.png";
import { Chat, Message, GroupedMessages, NewMessage } from "types/whatsapp";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import "moment/locale/pt-br";
import moment from "moment";

interface ChatMessagesProps extends React.HTMLAttributes<HTMLElement> {
  chatMessages: Message[];
}

const ChatMessages = ({ chatMessages, ...props }: ChatMessagesProps) => {

  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages,
    deleteChat,
    isSendingMessages
  } = useWhatsapp();
  const [groupedMessages, setGroupedMessages] = useState({} as GroupedMessages);
  const messagesRef = useRef<HTMLDivElement | null>(null);

  // Função para agrupar mensagens por data
  const groupMessagesByDate = (messages: Message[]): GroupedMessages => {
    // Agrupando mensagens por data
    const groupedMessages: GroupedMessages = messages.reduce((acc: GroupedMessages, message) => {
      const messageDate = moment(message.date).format("DD/MM/YYYY"); // Extrair a data
      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(message);
      return acc;
    }, {});

    // Ordena as chaves (datas) de forma decrescente
    const sortedKeys = Object.keys(groupedMessages).sort((a, b) => {
      return moment(a, "DD/MM/YYYY").valueOf() - moment(b, "DD/MM/YYYY").valueOf();
    });

    // Cria um novo objeto de GroupedMessages ordenado
    const sortedGroupedMessages: GroupedMessages = {};

    sortedKeys.forEach(date => {
      // Ordena as mensagens dentro de cada data
      const sortedMessages = groupedMessages[date].sort((a, b) => {
        return moment(a.date).valueOf() - moment(b.date).valueOf();
      });
      sortedGroupedMessages[date] = sortedMessages; // Adiciona ao objeto
    });

    return sortedGroupedMessages; // Retorna um objeto GroupedMessages
  };

  const scrollToBottom = () => {
    if (messagesRef.current && !hasScrolled) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }

  const [hasScrolled, setHasScrolled] = useState(false);
  const handleScroll = () => {
    if (messagesRef.current) {
      const scrollTop = messagesRef.current.scrollTop;
      // if (scrollTop == 0 && messagesRef.current) {
      //   if (messagesRef.current.clientHeight > messagesRef.current.scrollTop) {
      //     console.log("carregar mensagens");
      //     setIsLoadingMessages(true);
      //   }
      // }
      setHasScrolled(messagesRef.current.scrollHeight - messagesRef.current.scrollTop !== messagesRef.current.clientHeight)
    }
  }

  useEffect(() => {
    setGroupedMessages(groupMessagesByDate(messages));
  }, [messages]);

  useEffect(() => {
    setHasScrolled(false);
    scrollToBottom();
  }, [groupedMessages]);


  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (messagesRef.current) {
        messagesRef.current.removeEventListener('scroll', handleScroll);
      }
    };

  }, [messagesRef.current]);

  return (
    <div
      id="messages"
      ref={messagesRef}
      {...props}>
      {isLoadingMessages ? (
        <div className="text-center py-5">
          <CircularProgress size={40} />
        </div>
      ) : (<>
        {Object.keys(groupedMessages).map((date) => (
          <div key={date} className="d-flex flex-column">
            <div className="mx-auto my-2 bg-primary text-bg-primary small py-1 px-2 rounded sticky-top" style={{ top: 5 }}>
              {date}
            </div>
            {groupedMessages[date].map((message: Message, index: number) => (
              <div key={index} className={`${message.sentOrReceived ? 'align-self-end self' : 'align-self-start'} p-1 my-1 mx-3 rounded shadow-sm message-item`}>
                {/* 
                  <div className="options">
                    <Dropdown>
                      <Dropdown.Toggle variant="link" className="no-arrow lh-1 py-0 px-2">
                        <i className="uil uil-angle-down text-muted"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="start" className="p-0" style={{ maxHeight: "initial" }}>
                        <Dropdown.Item role="button" as="a" onClick={() => { }} >
                          <i className="uil uil-info-circle me-2"></i>Informações
                        </Dropdown.Item>
                        <Dropdown.Item role="button" className="text-danger" as="a" onClick={() => { }} >
                          <i className="uil uil-trash me-2"></i>Remover
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> 
                */}

                <div className="small font-weight-bold text-primary">
                  {message.fromName}
                </div>

                <div className="d-flex flex-row">
                  <div className="body m-1 me-2">
                    {message.message ? message.message : (<span className="opacity-50 small">(imagem ou vídeo ainda não são suportados nesta versão)</span>)}
                  </div>
                  <div className="time ms-auto small text-end flex-shrink-0 align-self-end text-muted">
                    <OverlayTrigger overlay={<Tooltip>{moment(message.date).format("DD/MM/YYYY HH:mm:ss")}</Tooltip>}>
                      <span className="small">
                        {moment(message.date).format("HH:mm")}
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </>)}
      <div className="mb-3" />
    </div>
  );
};

export default ChatMessages;
