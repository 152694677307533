import { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Modal, Accordion, Dropdown, OverlayTrigger, Tooltip, Tab, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import * as yup from "yup";

import Button from "components/Button";
import Input from "components/Input";
import SelectTag from 'components/SelectTag';
import { ModalFile } from 'components/ModalFile';
import { useAuth } from "providers/Auth";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault, FileInfo } from "types/board";
import { api } from "services";
import Swal from "sweetalert2";
import { toast } from "utils/toast";
import logger from "utils/logger";
import { isDev } from 'utils/isDev';

export const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "tiff", "svg"];
export const videoExtensions = [
  'mp4',  // .mp4 (MPEG-4 Part 14)
  'webm', // .webm (WebM)
  'ogv',  // .ogv (Ogg Video)
  'mov',  // .mov (QuickTime, limitado a Safari)
  'avi',  // .avi (Raramente suportado, não recomendado para a web)
  'mpeg'  // .mpeg (Antigo, pouco usado na web)
];

interface PropsFilesTab {
  // subtasksData: File[];
}

// Status possíveis do upload
export type FileUploadStatus = 'pending' | 'uploading' | 'completed' | 'error' | 'cancelled';

// Interface para o arquivo com informações de upload
export interface FileUploadInfo {
  id: string;
  file: File;
  progress: number;
  status: FileUploadStatus;
  errors: string[];
}

export const formatFileSize = (bytes: number): string => {
  if (bytes < 1024) return bytes + ' B';
  if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
  return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
};

const ALLOWED_TYPES = [
  // Imagens
  'image/jpeg',    // .jpeg, .jpg
  'image/png',     // .png
  'image/gif',     // .gif
  'image/bmp',     // .bmp
  'image/webp',    // .webp
  'image/tiff',    // .tif, .tiff
  'image/svg+xml', // .svg
  // 'image/heif',    // .heif
  // 'image/heic',    // .heic

  // PDF
  'application/pdf', // .pdf

  // Vídeos
  'video/mp4',      // .mp4
  'video/webm',     // .webm
  'video/ogg',      // .ogv
  'video/quicktime', // .mov (limitado ao Safari)
  'video/avi',      // .avi (raramente usado, opcional)
  'video/mpeg'      // .mpeg (antigo, opcional)
];


const MAX_SIZE = 35 * 1024 * 1024; // 15MB

export function FilesTab({
  // subtasksData,
}: PropsFilesTab) {
  const { token, userRole, loginQuick } = useAuth();
  const { userData } = useUser();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    tags,
    setTags,
    getTags,
  } = useBoard();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [currentFile, setCurrentFile] = useState(0);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [anexos, setAnexos] = useState([] as FileInfo[]);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileView = (index: number) => {
    setCurrentFile(index);
    setShowModalFile(true);
  }

  const [showModalFile, setShowModalFile] = useState(false);

  const [files, setFiles] = useState<FileUploadInfo[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [previews, setPreviews] = useState<{ [key: string]: string }>({});

  const getFileExtension = (fileName: string) => {
    return fileName.split('.').pop()?.toUpperCase() || '';
  };

  const validateFile = (file: File): string[] => {
    const errors = [];

    if (!ALLOWED_TYPES.includes(file.type)) {
      errors.push(`Formato de arquivo não permitido. Aceitos: ${ALLOWED_TYPES.join(', ')}`);
    }

    if (file.size > MAX_SIZE) {
      errors.push(`Arquivo muito grande. Tamanho máximo: ${MAX_SIZE / 1024 / 1024}MB`);
    }

    return errors;
  };

  const generatePreview = async (file: File, fileId: string) => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviews(prev => ({
          ...prev,
          [fileId]: e.target?.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);

    const newFiles: FileUploadInfo[] = selectedFiles.map(file => ({
      file,
      id: Math.random().toString(36).substr(2, 9),
      progress: 0,
      status: 'pending',
      errors: validateFile(file)
    }));

    // Gerar previews para as imagens
    newFiles.forEach(fileInfo => {
      generatePreview(fileInfo.file, fileInfo.id);
    });

    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const uploadFile = async (fileData: FileUploadInfo): Promise<boolean> => {
    if (fileData.errors.length > 0 || fileData.status !== 'pending') {
      return false;
    }

    const formData = new FormData();
    formData.append('Document', fileData.file);
    formData.append('Description', fileData.file.name);

    try {
      abortControllerRef.current = new AbortController();

      setFiles(prevFiles =>
        prevFiles.map(f =>
          f.id === fileData.id ? { ...f, status: 'uploading' } : f
        )
      );

      const response = await api.post(`/Crm/Board/${boardData.id}/Column/${editTaskData.columnId}/Task/${editTaskData.id}/PostFile`, formData, {
        signal: abortControllerRef.current.signal,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
          setFiles(prevFiles =>
            prevFiles.map(f => {
              if (f.id === fileData.id) {
                const updatedFile = { ...f, progress };
                return updatedFile;
              }
              return f;
            })
          );
        }
      });

      if (response.data) {
        setAnexos((prev) => [
          ...prev,
          response.data
        ]);
        removeFile(fileData.id);
      }

      return true;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Erro desconhecido';
      if (error instanceof Error && error.name === 'AbortError') {
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.id === fileData.id ? { ...f, status: 'cancelled' } : f
          )
        );
      } else {
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.id === fileData.id ? { ...f, status: 'error', errors: ['Erro no upload'] } : f
          )
        );
      }
      return false;
    }
  };

  // Efeito para iniciar uploads automaticamente quando novos arquivos são adicionados
  useEffect(() => {
    const pendingFiles = files.filter(f => f.status === 'pending' && f.errors.length === 0);

    if (pendingFiles.length > 0 && !uploading) {
      const uploadPendingFiles = async () => {
        setUploading(true);
        try {
          await Promise.all(pendingFiles.map(uploadFile));
          const allCompleted = files.every(f =>
            f.status === 'completed' || f.errors.length > 0 || f.status === 'cancelled'
          );

          if (allCompleted) {
            toast.fire({
              icon: 'success',
              title: 'Todos os uploads foram concluídos!',
            });
          }
        } catch (error) {
          toast.fire({
            icon: 'error',
            title: 'Ocorreu um erro durante o upload',
          });
        } finally {
          setUploading(false);
        }
      };

      uploadPendingFiles();
    }
  }, [files]);

  const cancelUpload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const removeFile = (fileId: string) => {
    // Se o arquivo estiver em upload, cancela primeiro
    const fileToRemove = files.find(f => f.id === fileId);
    if (fileToRemove?.status === 'uploading') {
      cancelUpload();
    }
    // Limpar o preview quando remover o arquivo
    setPreviews(prev => {
      const newPreviews = { ...prev };
      delete newPreviews[fileId];
      return newPreviews;
    });
    setFiles(prevFiles => prevFiles.filter(f => f.id !== fileId));
  };

  const getFiles = async () => {
    setLoadingFiles(true);
    try {
      logger.log("getFiles");
      const response = await api.get(`/Crm/Board/${boardData.id}/Column/${editTaskData.columnId}/Task/${editTaskData.id}/GetTaskFiles`);
      console.log(response.data);
      if (response.data) {
        setAnexos(response.data);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoadingFiles(false);
    }
  }

  const getFile = async (fileId: Id) => {
    try {
      const response = await api.get(`/Crm/Board/${boardData.id}/Column/${editTaskData.columnId}/Task/${editTaskData.id}/File/${fileId}`);
      if (response.data && response.data.url) {
        const newAnexos = anexos.map((anexo) => {
          if (anexo.id === fileId && !anexo.url) {
            anexo.url = response.data.url;
          }
          return anexo;
        })
        setAnexos(newAnexos);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  const deleteFile = async (fileId: Id) => {
    try {
      const response = await api.delete(`/Crm/Board/${boardData.id}/Column/${editTaskData.columnId}/Task/${editTaskData.id}/File/${fileId}`);
      if (response.status === 200) {
        const newAnexos = anexos.filter((anexo) => (anexo.id !== fileId));
        setAnexos(newAnexos);
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  const handleDeleteFile = (fileId: Id) => {
    Swal.fire({
      title: "Tem certeza que deseja remover este arquivo?",
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, remover",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        deleteFile(fileId);
      }
    });
  }

  useEffect(() => {
    if (editTaskData.attachmentsQuantity && editTaskData.attachmentsQuantity > 0) {
      getFiles();
    }
  }, []);

  useEffect(() => {
    anexos.map((anexo) => {
      if (!anexo.url) {
        getFile(anexo.id);
      }
    });

    const newColumns = columnsData.map((column) => {
      if (column.id === editTaskData.columnId) {
        if (column.tasks) {
          column.tasks = column.tasks.map((task) => {
            if (task.id == editTaskData.id) {
              task.attachmentsQuantity = anexos.length;
            }
            return task;
          });
        }
      }
      return column;
    });
    setColumnsData(newColumns);

  }, [anexos]);
  return (
    <>
      <Modal.Body className="bg-light px-3 pb-1">
        <label className="small"><i className="uil uil-paperclip me-2"></i>Anexos</label>
        {anexos.length > 0 ? (
          <>
            {loadingFiles ? (
              <>
                <div className="text-center py-5">
                  <CircularProgress size={40}/>
                </div>
              </>
            ) : (
              <>
                <div className="row g-2 mb-3">
                  {anexos.map((anexo, index) => (
                    <div key={index} className="col-12 content-hover position-relative">
                      <button onClick={() => { handleFileView(index); }} className="file-preview p-2 border rounded row g-0">
                        <div className="col col-auto position-relative align-self-center">
                          {imageExtensions.includes(anexo.fileExtension.toLowerCase()) ? (
                            <img src={anexo.url} className="me-2" alt={anexo.description} width={32} height={32} />
                          ) : (
                            <>
                              {videoExtensions.includes(anexo.fileExtension.toLowerCase()) ? (
                                <i className="uil uil-video fs-2 me-2"></i>
                              ) : (
                                <>
                                  <div className="file-extension">.{anexo.fileExtension}</div>
                                  <i className="uil uil-file fs-2 me-2"></i>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col">
                          <b className="d-block text-truncate">{anexo.description}</b>
                          <small className="opacity-50 lh-1">{formatFileSize(anexo.length)} • Clique para visualizar</small>
                        </div>
                      </button>
                      <div className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center pe-2">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Remover Arquivo</Tooltip>}
                        >
                          <button
                            className="show-hover btn btn-link text-danger bg-body"
                            onClick={() => { handleDeleteFile(anexo.id); }}
                          >
                            <i className="uil uil-trash"></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                </div>
                <ModalFile currentFile={currentFile} files={anexos} show={showModalFile} setShow={setShowModalFile} />
              </>
            )}
          </>
        ) : (
          <>
            <div className="py-5 text-center">
              Nenhum arquivo em anexo.
            </div>
          </>
        )}
        {files && files.length > 0 && (
          <div className="row g-2 mb-3">
            {files.map(fileData => (
              <div key={fileData.id} className="col-12 content-hover position-relative">
                <div className="file-preview p-2 border rounded row g-0">
                  <div className="col col-auto position-relative align-self-center">
                    {fileData.file.type.startsWith('image/') && previews[fileData.id] ? (
                      <>
                        <img src={previews[fileData.id]} className="me-2" alt={fileData.file.name} width={32} height={32} />
                      </>
                    ) : (
                      <>
                        {fileData.file.type.startsWith('video/') ? (
                          <i className="uil uil-video fs-2 me-2"></i>
                        ) : (
                          <>
                          <div className="file-extension">.{getFileExtension(fileData.file.name)}</div>
                          <i className="uil uil-file fs-2 me-2"></i>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col">
                    <b className="d-block text-truncate">{fileData.file.name}</b>
                    {fileData.errors.length > 0 ? (
                      <small className="text-danger lh-1">
                        {fileData.errors.map((error, index) => (
                          <span key={index} className="d-block">{error}</span>
                        ))}
                      </small>
                    ) : (
                      <>
                        <small className="lh-1">
                          {fileData.status === 'completed' ? (
                            <span className="text-success flex items-center">
                              <i className="uil uil-check-circle me-1" />
                              Concluído
                            </span>
                          ) : fileData.status === 'error' ? (
                            <span className="text-danger">Erro no upload</span>
                          ) : fileData.status === 'cancelled' ? (
                            <span className="opacity-50">Cancelado</span>
                          ) : (
                            `${fileData.progress}%`
                          )}
                        </small>
                      </>
                    )}
                  </div>
                  {fileData.status === 'uploading' && (
                    <div className="position-abolute left-0 bottom-0 right-0">
                      <div className="progress" style={{ height: 5 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${fileData.progress}%` }}></div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="position-absolute end-0 top-0 bottom-0 d-flex align-items-center pe-2">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Remover Arquivo</Tooltip>}
                  >
                    <button className="show-hover btn btn-link text-danger bg-body" onClick={() => removeFile(fileData.id)}><i className="uil uil-trash"></i></button>
                  </OverlayTrigger>
                </div>
              </div>
            ))}
          </div>
        )}

        {files.some(f => f.status === 'uploading') && (
          <button
            onClick={cancelUpload}
            className="btn btn-sm btn-outline-danger rounded-pill"
          >
            Cancelar Upload{files.length != 1 ? 's' : ''}
          </button>
        )}
      </Modal.Body >
      <Modal.Footer className={"bg-body position-sticky bottom-0"}>
        <div className="opacity-50 small">Tamanho máximo: {formatFileSize(MAX_SIZE)}</div>
        <button className="btn btn-indigo rounded-pill" onClick={handleUploadClick}><i className="uil uil-plus me-1"></i>Anexar Arquivo</button>
        <input
          hidden
          ref={hiddenFileInput}
          type="file"
          multiple
          onChange={handleFileSelect}
          className="hidden"
          id="fileInput"
          accept={ALLOWED_TYPES.join(',')}
        />
      </Modal.Footer>
    </>
  );
};
