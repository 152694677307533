import { Dispatch, SetStateAction, useState, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import Button from "components/Button";
import ModalComponent from "components/ModalComponent";
import { useFeedback } from "providers/Feedback";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { api } from "services";
import { toast } from "utils/toast";
import { TextareaAutosize } from "@mui/material";

interface ModalFeedbackProps {
  show: boolean;
  onHide?: () => void;
}

export interface FeedbackData {
  userId: number;
  type: string;
  description?: string;
}

const ModalFeedback = ({
  show,
  onHide,
}: ModalFeedbackProps) => {
  const navigate = useNavigate();
  const { showFeedbackModal, setShowFeedbackModal } = useFeedback();
  const { token } = useAuth();
  const { userData } = useUser();

  const schema = yup.object().shape({
    type: yup.string().required("*Campo obrigatório"),
    description: yup.string().required("*Campo obrigatório").trim(),
  });

  // const [typeValue, setTypeValue] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    control,
    getValues,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FeedbackData>({
    resolver: yupResolver(schema),
    defaultValues: {
      userId: userData.id,
      type: ''
    },
  });

  const selectedType = watch('type');

  const onSubmit = (data: FeedbackData) => {
    // Lógica de envio
    console.log('Form data', data); // Lógica de envio
    reset();
    setShowFeedbackModal(false);
    if (onHide) onHide();
  };
  
  return (
    <>
      <ModalComponent
        title="Enviar Feedback"
        show={showFeedbackModal}
        onHide={() => {
          reset();
          setShowFeedbackModal(false);
          if (onHide) onHide();
        }}
        dialogClassName="modal-45w"
        size="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-4 text-center">
              <ButtonGroup className="d-flex flex-row">
                <ToggleButton
                  id={`radio-idea`}
                  type="radio"
                  variant={'outline-primary'}
                  className={`text-center col-4 p-2 ${errors.type ? "btn-outline-danger" : "btn-outline-primary"}`}
                  value={'idea'}
                  {...register("type")}
                  checked={selectedType === 'idea'}
                  onChange={(e) => {setValue('type', e.currentTarget.value);trigger('type');}}
                >
                  <i className="uil uil-lightbulb-alt fs-4"></i>
                  <p className="mb-0">IDEIA</p>
                </ToggleButton>
                <ToggleButton
                  id={`radio-bug`}
                  type="radio"
                  variant={'outline-primary'}
                  className={`text-center col-4 p-2 ${errors.type ? "btn-outline-danger" : "btn-outline-primary"}`}
                  value={'bug'}
                  {...register("type")}
                  checked={selectedType === 'bug'}
                  onChange={(e) => {setValue('type', e.currentTarget.value);trigger('type');}}
                >
                  <i className="uil uil-bug fs-4"></i>
                  <p className="mb-0">BUG</p>
                </ToggleButton>
                <ToggleButton
                  id={`radio-other`}
                  type="radio"
                  variant={'outline-primary'}
                  className={`text-center col-4 p-2 ${errors.type ? "btn-outline-danger" : "btn-outline-primary"}`}
                  value={'other'}
                  {...register("type")}
                  checked={selectedType === 'other'}
                  onChange={(e) => {setValue('type', e.currentTarget.value);trigger('type');}}
                >
                  <i className="uil uil-comment-alt-message fs-4"></i>
                  <p className="mb-0">OUTRO</p>
                </ToggleButton>
              </ButtonGroup>
              {errors.type && <p className="text-danger small text-start">{errors.type.message}</p>}
            </div>
            <TextareaAutosize
              placeholder="Qual o seu feedback?"
              id="description"
              minRows={5}
              {...register("description")}
              className={`form-control ${errors.description ? "is-invalid" : ""}`}
            ></TextareaAutosize>
            {errors.description && <p className="text-danger small text-start">{errors.description.message}</p>}
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button type="button" className="btn-light rounded-pill" onClick={() => {
              reset();
              setShowFeedbackModal(false);
              if (onHide) onHide();
            }}>
              Cancelar
            </Button>

            <Button type="submit" className="btn-primary rounded-pill" onClick={() => { }}>
              Enviar <i className="uil uil-arrow-right"></i>
            </Button>
          </Modal.Footer>
        </form>
      </ModalComponent>
    </>
  );
};

export default ModalFeedback;
