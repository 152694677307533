import { ReactNode } from "react";
import { Modal } from "react-bootstrap";

interface ModalComponentProps {
  title?: string;
  show: boolean;
  onHide?: () => void;
  children: ReactNode;
  size?: "sm" | "lg" | "xl" | "fullscreen" | "full";
  dialogClassName?: string;
  contentClassName?: string;
  hasCloseButton?: boolean;
  keyboard?: boolean;
  backdrop?: boolean | "static";
  className?:string;
}

const ModalComponent = ({
  title,
  children,
  hasCloseButton = true,
  backdrop = "static",
  ...rest
}: ModalComponentProps) => {
  return (
    <Modal {...rest} backdrop={backdrop} centered>
      <Modal.Header closeButton={hasCloseButton}>
        <Modal.Title className="h5 m-0">{title}</Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
};

export default ModalComponent;
