import React, { useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomNode, LoopNodeData, LoopNodeProps, units, type Units } from "../utils/nodeTypes";

interface LoopFormProps {
  node: CustomNode;
  onUpdate: (id: string, data: LoopNodeData) => void;
}

const validationSchema = Yup.object().shape({
  times: Yup.string().required('O timeso é obrigatório'),
  unit: Yup.mixed<Units>().oneOf([...units], 'Unidade inválida'),
});

const LoopForm = ({ node, onUpdate }: LoopFormProps) => {

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<LoopNodeData>({
    resolver: yupResolver(validationSchema),
    defaultValues: node.data
  });

  // UseEffect para pré-carregar os valores de `node` quando o componente é montado ou o `node` muda
  useEffect(() => {
    if (node) {
      console.log(node.data);

      reset({
        times: node.data.times || '',
      } as LoopNodeData);
    }
  }, [node.data, reset]);

  // const onSubmit = useCallback((data: LoopNodeData) => {
  //   onUpdate(node.id, data);
  // }, [node.id, onUpdate]);

  const onSubmit = (data: LoopNodeData) => {
    onUpdate(node.id, data);
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="p-3">
        <div className="mb-3">
          <label className="form-label">Quantidade de Tentativas</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            {...register('times')}
          />
          {errors.times && <div className="text-start text-danger"><small>{errors.times.message}</small></div>}
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-primary rounded-pill">Salvar</button>
        </div>
      </div>

    </form>
  );
};

export default LoopForm;