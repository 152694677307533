import { useState, useEffect } from "react";
import { useParams } from 'react-router';
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import { CircularProgress } from "@mui/material";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useBoard } from "providers/Board";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task } from "types/board";
import logger from "utils/logger";
import boradsImg from "assets/boards.png";
import { isDev } from 'utils/isDev';

const QrCode = () => {
  useEffect(() => { document.title = 'Qr Code | CRM - Conectadoc'; }, []);

  const { token } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();

  const {
    boards,
    getBoards,
    setBoards,
    isLoadingBoards,
  } = useBoard();

  const [selectedNavTab, setSelectedNavTab] = useState(sessionStorage.getItem(`crm-board`) ?? "" as Id);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (token && userData && userData.name && !isLoadingBoards) {
      getBoards();
    }
  }, [token, userData]);

  useEffect(() => {
    if (boards.length > 0 && (!selectedNavTab || selectedNavTab == "")) {
      setSelectedNavTab(boards[0].id);
    }
  }, [boards]);

  const handleTabClick = (id: Id) => {
    sessionStorage.setItem(`crm-board`, id.toString());
    setSelectedNavTab(id);
  };

  return (
    <>
      <ContainerAnimation className="content">
        <div id="top" className="d-print-none">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1">QR-Code</h1>
              </div>
            </div>
            <ul className="nav nav-tabs scrollable pt-3">
              {(boards && boards.length > 0) && (<>
                {boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault)).map((b, index) => (
                  <li key={b.id} className="nav-item">
                    <button
                      type="button"
                      className={`nav-link link-primary ${(selectedNavTab === (b.id).toString()) ? "active" : (selectedNavTab == "" && index === 0) ? "active" : ""
                        }`}
                      onClick={() => { handleTabClick(b.id) }}
                    >
                      {b.isDefault && (<i className="uil uil-star text-warning me-2 small"></i>)}{b.name}
                    </button>
                  </li>
                ))}
              </>)}
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            {isLoadingBoards ? (
              <div className="card">
                <div className="card-body text-center p-3 p-lg-5">
                  <p className="d-print-none opacity-50">Carregando...</p>
                  <div className="text-center py-5">
                    <CircularProgress size={40} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="col-12 col-md-8 col-xl-6">
                  {(boards && boards.length > 0) ? (<>
                    <div className="card">
                      <div className="card-body text-center p-3 p-lg-5">
                        <p className="d-print-none opacity-50">Você pode alterar o texto abaixo como preferir.</p>
                        <h4 className="fw-semibold mb-5" contentEditable="true" suppressContentEditableWarning={true}>Utilize o Qr-Code para fazer o checkin de seu atendimento!</h4>
                        <div className="rounded-4" style={{ background: "#FFF", padding: "1rem", height: "auto", margin: "0 auto", maxWidth: 400, width: "100%" }}>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`https://crm.conectadoc.com.br/${userData.id}${selectedNavTab && boards && boards.length > 0 ? '/' + selectedNavTab : ''}`}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                        {isDev() && (
                          <a href={`https://crm.conectadoc.com.br/${userData.id}${selectedNavTab && boards && boards.length > 0 ? '/' + selectedNavTab : ''}`} target="_blank">{`https://crm.conectadoc.com.br/${userData.id}${selectedNavTab && boards && boards.length > 0 ? '/' + selectedNavTab : ''}`}</a>
                        )}
                        <div className="text-center pt-5 d-print-none">
                          <a role="button" onClick={() => { window.print(); }} className="btn btn-dark rounded-pill">Imprimir</a>
                        </div>
                      </div>
                    </div>
                  </>) : (<>
                    <div>
                      <h4 className="text-center">
                        Você não possui nenhum board cadastrado.
                      </h4>
                      <div className="text-center pt-3 pb-5 d-print-none">
                        <a role="button" onClick={() => { navigate("/"); }} className="btn btn-dark rounded-pill">Configurar Boards</a>
                      </div>
                      <div className="text-center mb-4">
                        <img src={boradsImg} style={{ maxHeight: '60vh', maxWidth: "100%" }} />
                      </div>
                    </div>
                  </>)}
                </div>
              </>
            )}
          </div>
        </div>
      </ContainerAnimation>
    </>
  );
};

export default QrCode;
