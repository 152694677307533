import { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import "moment/locale/pt-br";
import moment from "moment";

import ContainerAnimation from "components/ContainerAnimation";
import { useForm } from "react-hook-form";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, ToTaskForward } from "types/board";
import { hsvaToHex, hexToRgba, rgbaToRgb, rgbaStringToHsva, getContrastingColor } from '@uiw/color-convert';
import Input from "components/Input";
import { CircularProgress } from "@mui/material";
import ModalComponent from 'components/ModalComponent';
import ModalTask from "pages/Boards/components/ModalTask";
import ModalPatient from "pages/Boards/components/ModalPatient";
import ModalForward from "pages/Boards/components/ModalForward";
import ModalColumn from "pages/Boards/components/ModalColumn";
import ModalTag from "pages/Boards/components/ModalTag";
import SelectTag, { SelectOptions } from 'components/SelectTag';
import { numberValue } from "utils/formMasks";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useTutorial } from "providers/Tutorial";
import { useBoard } from "providers/Board";
import { usePatient } from "providers/Patient";
import logger from "utils/logger";
import boradsImg from "assets/boards.png";
import { TaskBadges } from "pages/Boards/components/TaskTabs/TaskBadges";
import { useDarkMode } from "providers/DarkMode";

const Patients = () => {
  useEffect(() => { document.title = 'Pacientes | CRM - Conectadoc'; }, []);

  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const { token } = useAuth();
  const { userData } = useUser();
  const [filtered, setFiltered] = useState<any[]>([]);
  const handleSearchPatient = (q: string) => {
    setSearchTerm(q);
    const term = q.toLowerCase().trim();
    var activeTagIDs: any = [];

    filterTags.map((tag: any) => {
      if (tag.isFixed) {
        activeTagIDs.push(tag.id);
      }
    });

    console.log(patientsByBoard);

    const filtered = patientsByBoard.filter((patient) => {
      var matchesTerms = true;
      var hasActiveTag = true;

      // Verifica se a task possui pelo menos uma tag ativa
      if (filterTags.length > 0 && activeTagIDs.length > 0) {
        hasActiveTag = patient.items.some((item: any) => {
          if (item.task.tags) {
            return item.task.tags.some((tag: any) => activeTagIDs.includes(tag.id));
          }
        });
      }

      // Verifica se o título da task contém todos os termos fornecidos
      if (term !== '') {
        const terms = term.split(' ').filter(t => t);
        matchesTerms = patient.items.some((item: any) => {
          return terms.every(t => item.patient?.name?.toLowerCase().includes(t)) ||
            terms.every(t => item.board.name.toLowerCase().includes(t)) ||
            terms.every(t => item.column.title.toLowerCase().includes(t)) ||
            terms.every(t => item.column.title.toLowerCase().includes(t)) ||
            terms.every(t => moment(item.task.createdDate).format("DD/MM/YYYY HH:mm").includes(t)) ||
            terms.every(t => moment(item.task.updatedDate).format("DD/MM/YYYY HH:mm").includes(t))
        });
      }

      // Retorna true somente se ambas as condições forem atendidas
      return hasActiveTag && matchesTerms;
    });

    setFiltered(filtered);
  }

  const updateTaskById = (data: any, taskIdToUpdate: Id, newTaskData: any) => {
    return data.map((patient: any) => ({
      ...patient,
      items: patient.items.map((item: any) =>
        item.task.id === taskIdToUpdate
          ? { ...item, task: { ...item.task, ...newTaskData } } // Atualiza apenas os dados da task com o id correspondente
          : item // Mantém os itens inalterados
      )
    }));
  };

  const {
    patients,
    patientLoading,
    setPatients,
    patientData,
    setPatientData,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    tags,
    filterTags,
    setFilterTags,
    getTagsFromPatients,
    handleTagChange,
    leadOrigin
  } = useBoard();

  const handleOpenModalTask = (task: any) => {
    handleTaskModal(true, task.task as Task);
    setColumnsData(task.board.columns as Column[] ?? []);
    setBoardData(task.board as Board);
    setTasksData(task.column.tasks);
  }

  // atualiza a task recém editada
  useEffect(() => {
    if (tasksData.length > 0 && tasksData[0].id) {
      const newPatients = updateTaskById(patientsByBoard, tasksData[0].id, tasksData[0]);
      setPatientsByBoard(newPatients);
    }
  }, [tasksData]);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (userData && userData.name) {
      getBoards();
    }
  }, [token, userData]);

  useEffect(() => {
    if (isLoadingBoards) {
      return;
    }
    if (boards.length > 0) {
      getPatientsByBoards(boards);
    }
  }, [boards]);

  useEffect(() => {

    getTagsFromPatients(patientsByBoard);

    if (userData && userData.name) {
      console.log("patientsByBoard", patientsByBoard);
      handleSearchPatient(searchTerm);
    }
  }, [patientsByBoard]);

  useEffect(() => {
    handleSearchPatient(searchTerm);
  }, [filterTags]);

  const {
    control,
  } = useForm();
  return (
    <>
      <ContainerAnimation className="content">
        <div id="top" className="d-print-none">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Pacientes</h1>
                {!isLoadingBoards && (<span className="badge bg-secondary">{patientsByBoard.length}</span>)}
              </div>

              <div className="col-12 col-md-6 col-lg order-5 order-md-1">
                <div className="border rounded-3 position-relative">
                  <span className="mt-2 ms-2 ps-2 lh-1 position-absolute" style={{ zIndex: 1 }}><i className="uil uil-search"></i></span>
                  <Input
                    type="search"
                    placeholder="Buscar por Nome / Board / Coluna / Data"
                    onChange={(e) => { handleSearchPatient(e.target.value); }}
                    className="ps-5 border"
                    defaultValue={searchTerm}
                  />
                </div>
              </div>
              {filterTags.length > 0 && (
                <div className="col-12 col-md-6 col-lg order-4 order-lg-2 position-relative z-2">
                  <SelectTag
                    control={control}
                    name="tags"
                    // defaultValue={tags.filter(item1 => editTaskData.tags?.some(item2 => item2.id === item1.id)).map((item: any) => ({ value: item.id, label: item.tagName, color: item.color }))}
                    isMulti
                    placeholder="Filtrar por TAG"
                    options={filterTags.map((tag) => ({
                      value: tag.id,
                      label: tag.tagName,
                      color: tag.color
                    }))}
                    handleChange={(e) => { handleTagChange(e); }}
                    closeMenuOnSelect={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container">
          {isLoadingBoards ? (<>
            <div className="placeholders">
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
              <div className="card border-0 pb-5 pt-3 mb-2 placeholder-wave"></div>
            </div>

          </>) : (<>
            {(patientsByBoard && patientsByBoard.length > 0) ? (<>
              {searchTerm != "" && (
                <>
                  {filtered.length > 0 ? (
                    <p className="text-center">Exibindo <b>{filtered.length}</b> de <b>{patientsByBoard.length}</b> paciente{patientsByBoard.length != 1 ? 's' : ''} na busca por <b>{searchTerm}</b></p>
                  ) : (
                    <p className="text-center">Nenhum paciente encontrado na busca por <b>{searchTerm}</b></p>
                  )}
                </>
              )}
              {filtered.sort((a, b) => {
                const nameA = a.items[0]?.patient?.name.trim() || ''; // Obtém o nome do paciente ou string vazia
                const nameB = b.items[0]?.patient?.name.trim() || ''; // Obtém o nome do paciente ou string vazia
                return nameA.localeCompare(nameB); // Compara os nomes
              }).map((item, index) => (
                <div key={index} className="card card-hover mb-2">
                  <div className="card-body p-3 pt-2">
                    <div className="row gx-3 content-hover">
                      <div className="col-12 col-xl-4 col-lg">
                        <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">NOME:</span></span>
                        <span className="lh-1">
                          {(item.items[0]?.patient && item.items[0]?.patient?.name != "") ? item.items[0]?.patient?.name : item.items[0].task.title}
                        </span>
                      </div>
                      <div className="col-12 d-xl-none border-top mb-2 mt-3"></div>
                      <div className="col-12 col-xl-8">
                        {item.items.map((task: any, key: number) => (
                          <div key={key} className="row gx-3">
                            <div className="col">
                              <div className="row gx-3">
                                <div className="col-6 col-md">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">BOARD:</span></span>
                                  <OverlayTrigger
                                    container={document.body}
                                    placement="bottom"
                                    overlay={<Tooltip className="small">Acessar Board</Tooltip>}
                                  >
                                    <a className="fw-semibold small position-relative" role="button" onClick={() => { sessionStorage.setItem(`crm-board`, task.board?.id); navigate("/boards"); }}>{task.board?.name}</a>
                                  </OverlayTrigger>

                                </div>
                                <div className="col-6 col-md">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">COLUNA:</span></span>
                                  <span className="badge align-middle" style={{ background: (task.column?.color !== "" ? task.column.color : "#FFFFFF"), color: getContrastingColor(darkMode ? rgbaStringToHsva(task.column?.color) : (task.column?.color ?? "#FFFFFF")) }}>
                                    {task.column?.title}
                                  </span>
                                </div>
                                <div className="col-6 col-md">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">TAGS:</span></span>
                                  {tags.filter(item1 => task.task.tags?.some((item2: any) => item2.id === item1.id)).map((tag: any, index: number) => (
                                    <span key={index} className="badge fw-semibold px-1" style={{ background: tag.color, color: getContrastingColor(tag.color) }}>{tag.tagName}</span>
                                  ))}
                                </div>
                                <div className="col-6 col-md small">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">VALOR:</span></span>
                                  {(task.task.taskVal && task.task.taskVal > 0) ? (
                                    <>
                                      <span className="opacity-50">R$</span> {numberValue(task.task.taskVal)}
                                    </>
                                  ) : (<>-</>)}
                                </div>
                                <div className="col col-2 align-self-end">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">&nbsp;</span></span>
                                  <div className="d-flex gap-1">
                                    <TaskBadges task={task.task} />
                                  </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md small">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">CRIADO EM:</span></span>
                                  {moment(task.task.createdDate).format("DD/MM/YYYY HH:mm")}
                                </div>
                                <div className="col-12 col-sm-6 col-md small">
                                  <span className="small text-upper text-10 d-block lh-1 show-hover"><span className="opacity-50">ATUALIZADO EM:</span></span>
                                  {task.task.updatedDate ? moment(task.task.updatedDate).format("DD/MM/YYYY HH:mm") : '-'}
                                </div>
                              </div>
                            </div>
                            <div className="col col-auto small align-self-center">
                              <span className="small text-upper text-10 d-block lh-1 show-hover">&nbsp;</span>
                              <OverlayTrigger
                                container={document.body}
                                overlay={<Tooltip className="small">Editar</Tooltip>}
                              >
                                <a role="button" className="btn btn-sm btn-light mt-n1 p-2 lh-1 rounded-circle" onClick={() => { handleOpenModalTask(task); }}>
                                  <i className="uil uil-edit-alt"></i>
                                </a>
                              </OverlayTrigger>

                            </div>
                            {key + 1 < item.items.length && (<div className="col-12 border-top mb-2 mt-3"></div>)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>) : (<>
              <div>
                <h4 className="text-center">
                  Você não possui nenhum paciente cadastrado.
                </h4>
                <div className="text-center mb-4">
                  <img src={boradsImg} style={{ maxHeight: '60vh', maxWidth: "100%" }} />
                </div>
              </div>
            </>)}
          </>)}
        </div>

      </ContainerAnimation>

      {/* modal task */}
      <ModalTask />

    </>
  );
};

export default Patients;
