import { useState, useEffect } from "react";
// import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Navigate, useNavigate, Link, useLocation } from "react-router-dom";
import "moment/locale/pt-br";
import moment from "moment";

import ContainerAnimation from "components/ContainerAnimation";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, ToTaskForward } from "types/board";
import { CircularProgress } from "@mui/material";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { useWhatsapp } from "providers/Whatsapp";
import { useTutorial } from "providers/Tutorial";
import { useBoard } from "providers/Board";
import { usePatient } from "providers/Patient";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2'
import logger from "utils/logger";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
);

const options = {
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  maintainAspectRatio: false
};

export default function Dashboard() {
  useEffect(() => { document.title = 'Dashboard | CRM - Conectadoc'; }, []);

  const navigate = useNavigate();

  const { token } = useAuth();
  const { userData } = useUser();

  const chartData = {
    labels: [
      'Boston',
      'Worcester',
      'Springfield',
      'Lowell',
      'Cambridge',
      'New Bedford'
    ],
    datasets: [
      {
        data: [617594, 181045, 153060, 106519, 105162, 95072],
        //backgroundColor:'green',
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(255, 99, 132, 0.6)'
        ]
      }
    ]
  };

  const updateTaskById = (data: any, taskIdToUpdate: Id, newTaskData: any) => {
    return data.map((patient: any) => ({
      ...patient,
      items: patient.items.map((item: any) =>
        item.task.id === taskIdToUpdate
          ? { ...item, task: { ...item.task, ...newTaskData } } // Atualiza apenas os dados da task com o id correspondente
          : item // Mantém os itens inalterados
      )
    }));
  };

  const {
    patients,
    patientLoading,
    setPatients,
    patientData,
    setPatientData,
    isLoadingPaciente,
    isLoadingPatientSearch,
    searchPatient,
    getPatientById,
    showAddPatientModal,
    setShowAddPatientModal
  } = usePatient();

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    showForwardPatientModal,
    setShowForwardPatientModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn,
    showModalTag,
    setShowModalTag,
    clearData,
    updateTask,
    getCardsByBoards,
    cardsByBoard,
    setCardsByBoard,
  } = useBoard();


  const {
    showWhatsappModal,
    setShowWhatsappModal,
    isLoadingWhatsapp,
    setIsLoadingWhatsapp,
    conectedWhatsapp,
    setConectedWhatsapp,
    checkInstance,
    inicializeInstance,
    qrCode,
    setQrCode,
    finishInstance,
    getChats,
    chats,
    setChats,
    isLoadingChats,
    getMessages,
    setIsLoadingChats,
    isLoadingMessages,
    setIsLoadingMessages,
    isLoadingChat,
    setIsLoadingChat,
    activeChat,
    setActiveChat,
    sendMessage,
    messages,
    setMessages
  } = useWhatsapp();

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (userData && userData.name) {
      getBoards();
      // verifica o whats
      checkInstance();
    }
  }, [token, userData]);

  useEffect(() => {
    if (conectedWhatsapp) {
      getChats();
    }
  }, [conectedWhatsapp]);

  useEffect(() => {
    if (isLoadingBoards) {
      return;
    }
    if (boards.length > 0) {
      console.log("boards", boards);

      const fetchData = async () => {
        await Promise.all([
          getPatientsByBoards(boards),
          getCardsByBoards(boards)
        ]);
        setIsLoadingPage(false);
      };

      fetchData();

    }
  }, [boards]);

  const formatChartData = () => {
    const data = [
      [0, 90],
      [20, 80],
      [30, 70],
      [40, 60],
      [45, 55],
    ];
    var newData: any = [];
    data.map((item: any, index) => {
      var diff = 100 - item[1] - item[0];
      newData.push([(item[0] + diff / 2), (item[1] + diff / 2)])
    })
    console.log(newData);
    return newData;
  }

  const funnelShape = {
    id: 'funnelChart',
    afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
      const TOTAL = 333;
      const { ctx, data, chartArea: { top, left, bottom, right, width, height }, scales: { x, y } } = chart;
      for (let i = 0; i < chart.getDatasetMeta(0).data.length - 1; i++) {
        if (chart.getDatasetMeta(0).data[i].width > 0) {
          ctx.save();
          ctx.beginPath();
          ctx.fillStyle = chart.getDatasetMeta(0).data[i].options.backgroundColor;
          // ctx.strokeStyle = chart.getDatasetMeta(0).data[i].options.borderColor;
          ctx.moveTo(chart.getDatasetMeta(0).data[i].base, chart.getDatasetMeta(0).data[i].y + (chart.getDatasetMeta(0).data[i].height / 2));
          ctx.lineTo(chart.getDatasetMeta(0).data[i].x, chart.getDatasetMeta(0).data[i].y + (chart.getDatasetMeta(0).data[i].height / 2));
          ctx.lineTo(chart.getDatasetMeta(0).data[i + 1].x, chart.getDatasetMeta(0).data[i + 1].y - (chart.getDatasetMeta(0).data[i + 1].height / 2));
          ctx.lineTo(chart.getDatasetMeta(0).data[i + 1].base, chart.getDatasetMeta(0).data[i + 1].y - (chart.getDatasetMeta(0).data[i + 1].height / 2));
          ctx.closePath();
          // ctx.stroke();
          ctx.fill();
          ctx.restore();
        }
      }
      for (let i = 0; i < chart.getDatasetMeta(0).data.length; i++) {
        const dataPoint = chart.getDatasetMeta(0).data[i];
        const dataPointPercentage = data.datasets[0].data[i][1] - data.datasets[0].data[i][0];
        const qtd = TOTAL * dataPointPercentage / 100;

        // Configurações do estilo para o valor em negrito
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = 'bold 15px sans-serif'; // Negrito
        ctx.fillStyle = 'rgba(0, 0, 0, 0.7)'; // Cor para o valor em negrito

        const textX = (left + right) / 2; // Centraliza horizontalmente
        const textY = dataPoint.y; // Usa a posição y do item

        // Desenha o valor em negrito
        ctx.fillText(`${qtd.toFixed(2)}`, textX - ctx.measureText(`${qtd}`).width / 2 - 10, textY);

        // Configurações do estilo para o percentual
        ctx.font = 'normal 12px sans-serif'; // Texto normal
        ctx.fillStyle = 'rgba(102, 102, 102, 1)'; // Cor para o percentual

        // Desenha o percentual logo após o valor
        ctx.fillText(` (${dataPointPercentage.toFixed(2)}%)`, textX + ctx.measureText(`${qtd}`).width / 2 + 10, textY);
      }

    }
  }

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const handleTabClick = (id: Id) => {
    logger.log(id);
    // getBoardById(id);
    sessionStorage.setItem(`crm-board`, id.toString());
    setSelectedNavTab(id);
  };

  const [selectedNavTab, setSelectedNavTab] = useState(sessionStorage.getItem(`crm-board`) ?? "" as Id);
  
  return (
    <>
      <ContainerAnimation className="content">
        <div id="top" className="d-print-none">
          <div className="container">
            <div className="row justify-content-md-between align-items-center gy-2 gx-2">
              <div className="col">
                <h1 className="mb-0 fw-semibold lh-1 d-inline-block me-2">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-3 mb-3">
            <div className="col-12 col-sm-6 col-lg-4 col-xl">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Boards</p>
                    <h2 className="fw-bold lh-1 mb-0">{boards.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-grids"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Cards</p>
                    <h2 className="fw-bold lh-1 mb-0">{cardsByBoard.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-postcard"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3`}>
                <Link to="/pacientes" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Pacientes</p>
                    <h2 className="fw-bold lh-1 mb-0">{patientsByBoard.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-chat-bubble-user"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3`}>
                <Link to="/boards" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Tasks</p>
                    <h2 className="fw-bold lh-1 mb-0">{cardsByBoard.filter((item: any) => (item.task.type !== 0)).length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-check-square"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl">
              <div className={`${isLoadingPage ? "placeholder-wave" : ""} card card-hover p-3`}>
                <Link to="/conversas" className="stretched-link"></Link>
                <div className={`row g-2 ${isLoadingPage ? "opacity-0" : ""}`}>
                  <div className="col">
                    <p className="fw-medium text-uppercase opacity-50 small mb-2">Conversas</p>
                    <h2 className="fw-bold lh-1 mb-0">{chats.length}</h2>
                  </div>
                  <div className="col col-auto">
                    <span className="text-bg-primary p-3 fs-4 d-block rounded-circle lh-1">
                      <i className="uil uil-comments-alt"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <ul className="nav nav-tabs position-relative scrollable pt-3 border-0" style={{marginBottom: -1, zIndex:1}}>
            {(boards && boards.length > 0) && (<>
              {boards.sort((a, b) => Number(b.isDefault) - Number(a.isDefault)).map((b, index) => (
                <li key={b.id} className="nav-item">
                  <button
                    type="button"
                    className={`nav-link link-primary ${(selectedNavTab === (b.id).toString()) ? "active" : (selectedNavTab == "" && index === 0) ? "active" : ""
                      }`}
                    onClick={() => { handleTabClick(b.id) }}
                  >
                    {b.isDefault && (<i className="uil uil-star text-warning me-2 small"></i>)}{b.name}
                  </button>
                </li>
              ))}
            </>)}
          </ul>
        </div>
        <div className="border-top bg-white py-5">
          <div className="container">
            <div className="row g-3">
              <div className="col-12 col-md-6" style={{ height: '30vh' }}>
                <div className={`${isLoadingPage ? "placeholder-wave" : ""} card h-100 blur-content`}>
                  <div className={`${isLoadingPage ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Gráfico de Funil</h5>
                    <div className="card-body">
                      <Bar
                        plugins={[funnelShape]}
                        options={{
                          indexAxis: 'y',
                          scales: {
                            x: {
                              border: {
                                display: false
                              },
                              grid: {
                                display: false
                              },
                              ticks: {
                                display: false
                              }
                            },
                            y: {
                              border: {
                                display: false
                              },
                              beginAtZero: true,
                              grid: {
                                display: false
                              },
                              ticks: {
                                display: false
                              }
                            }
                          },
                          plugins: {
                            legend: { display: false },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: function (context) {
                                  const label = context.label || ''; // Nome da barra
                                  const dataIndex = context.dataIndex;
                                  const dataset: any = context.dataset;

                                  // Calcular qtd e dataPointPercentage para o tooltip
                                  const dataPointPercentage = dataset.data[dataIndex][1] - dataset.data[dataIndex][0];
                                  const TOTAL = 333;
                                  const qtd = TOTAL * dataPointPercentage / 100;

                                  // Conteúdo personalizado do tooltip
                                  return `${qtd.toFixed(2)} (${dataPointPercentage}%)`;
                                }
                              }
                            }
                          },
                          maintainAspectRatio: false
                        }}
                        data={{
                          labels: ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5'],
                          datasets: [
                            {
                              data: formatChartData(),
                              borderWidth: 0,
                              // barThickness: 5,
                              backgroundColor: [
                                'rgba(255, 99, 132, 0.6)',
                                'rgba(54, 162, 235, 0.6)',
                                'rgba(255, 206, 86, 0.6)',
                                'rgba(75, 192, 192, 0.6)',
                                'rgba(153, 102, 255, 0.6)',
                                'rgba(255, 159, 64, 0.6)',
                                'rgba(255, 99, 132, 0.6)'
                              ]
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6" style={{ height: '30vh' }}>
                <div className={`${isLoadingPage ? "placeholder-wave" : ""} card h-100 blur-content`}>
                  <div className={`${isLoadingPage ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Gráfico de Barra</h5>
                    <div className="card-body">
                      <Bar
                        options={{
                          ...options,
                          indexAxis: 'y',
                        }}
                        data={chartData}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6" style={{ height: '30vh' }}>
                <div className={`${isLoadingPage ? "placeholder-wave" : ""} card h-100 blur-content`}>
                  <div className={`${isLoadingPage ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Gráfico de Pizza</h5>
                    <div className="card-body">
                      <Pie
                        options={options}
                        data={chartData}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6" style={{ height: '30vh' }}>
                <div className={`${isLoadingPage ? "placeholder-wave" : ""} card h-100 blur-content`}>
                  <div className={`${isLoadingPage ? "opacity-0" : ""}`}>
                    <h5 className="px-3 pt-3 mb-0">Gráfico de Linha</h5>
                    <div className="card-body">
                      <Line
                        options={options}
                        data={{
                          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                          datasets: [
                            {
                              label: 'Dataset 1',
                              data: [500, 100, -200, 300, 700, -500, 400],
                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                              label: 'Dataset 2',
                              data: [-100, 300, 200, 400, -300, 500, -100],
                              borderColor: 'rgb(53, 162, 235)',
                              backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContainerAnimation>
    </>
  );
}