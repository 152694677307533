import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CircularProgress, MenuItem } from "@mui/material";
import * as yup from "yup";

import { sessStorage } from "utils/storage";
import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import Input from "components/Input";
import SelectDropdown from "components/SelectDropdown";
import Alert from 'react-bootstrap/Alert';
import RadioInput from "components/RadioInput";
import ModalComponent from 'components/ModalComponent';
import { useAuth } from "providers/Auth";
import { usePatient } from "providers/Patient";
import { useBoard } from "providers/Board";
import { useUser } from "providers/User";
import { Gender } from "types/enums";
import { PatientData } from "types/patient";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task, TaskDefault } from "types/board";
import { cpfMask, mobilePhoneMask } from "utils/formMasks";
import { healthInsurenceOptions } from "utils/healthInsurence";
import { validateBirthDate, validateCpf } from "utils/formValidations";
import { api } from "services";
import { toast } from "utils/toast";
import {
  handleBirthDateInput,
  handleCpfInput,
  handleMobilePhoneInput,
} from "utils/handleInput";
import imgLogo from "assets/logo.svg";
import logger from "utils/logger";

interface PropsModalPatient {
  columnId: Id;
  updateTask: (task: Task) => void;
  setShowAddPatientModal: (bol: boolean) => void;
}

function ModalPatient({
  columnId,
  updateTask,
  setShowAddPatientModal,
}: PropsModalPatient) {
  const { token, userRole, loginQuick } = useAuth();
  const { userData } = useUser();

  const {
    patients,
    setPatients,
    patientData,
    setPatientData,
    editPatientData,
    patientLoading,
    isLoadingPaciente,
    getPatientById,
    getPatientByCpf,
    searchPatient,
    getPatientAppointments,
    appointments,
    setAppointments
  } = usePatient();

  const {
    boards,
    isLoading,
    getBoards,
    boardData,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    columnsData,
    setColumnsData,
    createBoard,
    deleteBoard,
    getBoardById,
    addColumnToBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    editColumnFromBoard,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate
  } = useBoard();

  const [termsOfUseModalShow, setTermsOfUseModalShow] = useState(false);
  const [endProcess, setEndProcess] = useState(false);

  const schemaCpf = yup.object().shape({
    cpf: yup
      .string()
      .required("*Campo obrigatório")
      .test(
        "cpfValidator",
        `*CPF inválido`,
        validateCpf
      )
      .transform((value) => value.replace(/\D/g, "")),
  });

  const schemaPatient = yup.object().shape({
    isTermsOfUseAccepted: yup
      .boolean()
      .oneOf([true], "*É necessário concordar com os Termos Gerais para prosseguir."),
    name: yup.string().required("*Campo obrigatório").uppercase().trim(),
    email: yup
      .string()
      .required("*Campo obrigatório")
      .email("*Formato inválido"),
    sex: yup.string(),
    birthDate: yup
      .string()
      .required("*Campo obrigatório")
      .test("birthDateValidator", "*Data inválida", validateBirthDate),
    mobilePhone: yup
      .string()
      .required("*Campo obrigatório")
      .min(15, "*Número de celular inválido"),
    // healthInsurence: yup
    //   .object()
    //     .required("*Campo obrigatório")
    //     .shape({
    //       value: yup.string().required("*Selecione uma opção"),
    //       label: yup.string().required("*Selecione uma opção"),
    //     }),
  });

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<PatientData>({
    resolver: yupResolver((patientData.cpf ? schemaPatient : schemaCpf))
  });

  const onSubmit = (data: PatientData) => {
    if (patientData.cpf) {
      var newPatient = { ...patientData, ...data };
      logger.log(newPatient);
      editPatientData(newPatient);
    } else {
      getPatientByCpf(data.cpf);
    }
  };

  const clearPatient = () => {
    setPatientData({} as PatientData);
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Modal.Body className="bg-light">
          {isLoadingPaciente ? (
            <div className="text-center py-5">
              <CircularProgress size={40} />
            </div>
          ) : (<>
            <div className="mb-3">
              {patientData.cpf ? (
                <>
                  {!patientData.name && (
                    <p className="text-muted mb-0">Não encontramos este CPF em nossa base, finalize o cadastro para: <b>{cpfMask(patientData.cpf)}</b>.</p>
                  )}
                </>
              ) : (
                <p className="text-muted">Informe o CPF para prosseguir!</p>
              )}
            </div>
            {!patientData.cpf ? (
              <Input
                containerClassName="form-floating mb-3"
                label="CPF: 999.999.999-99"
                placeholder="CPF: 999.999.999-99"
                type="tel"
                maxLength={14}
                register={register("cpf")}
                error={errors?.cpf?.message}
                onChange={(e) => handleCpfInput(e, setValue, "cpf")}
                defaultValue={cpfMask(`${patientData.cpf}`)}
                autoComplete="off"
              />
            ) : (
              <>
                <Input
                  containerClassName="form-floating mb-3"
                  className="text-uppercase form-floating"
                  type="text"
                  label="Nome Completo"
                  placeholder="Seu Nome Aqui"
                  register={register("name")}
                  error={errors?.name?.message}
                  defaultValue={patientData?.name}
                  autoComplete="off"
                  autoFocus
                />

                <Input
                  containerClassName="form-floating mb-3"
                  type="email"
                  label="E-mail"
                  placeholder="seu@email.com"
                  register={register("email")}
                  error={errors?.email?.message}
                  defaultValue={patientData?.email}
                  autoComplete="off"
                />

                <div className="form-floating mb-3 animated fadeInUp delay-200ms">
                  <div className="form-control form-control">
                    <div className="row">
                      <div className="col col-auto">
                        <RadioInput
                          value="M"
                          label="Masc."
                          register={register("sex")}
                          defaultChecked={patientData?.sex === Gender.Male}
                        />
                      </div>
                      <div className="col col-auto">
                        <RadioInput
                          value="F"
                          label="Fem."
                          register={register("sex")}
                          defaultChecked={patientData?.sex === Gender.Female}
                        />
                      </div>
                      <div className="col col-auto">
                        <RadioInput
                          value="I"
                          label="Intersexo"
                          register={register("sex")}
                          defaultChecked={patientData?.sex === Gender.Intersex}
                        />
                      </div>
                    </div>
                  </div>
                  <label htmlFor="select">Sexo</label>
                  <div className="text-danger mb-3">
                    <small>{!!errors?.sex && "*Selecione uma opção"}</small>
                  </div>
                </div>

                <Input
                  containerClassName="form-floating mb-3"
                  type="tel"
                  label="Data de Nascimento"
                  placeholder="dd/mm/aaaa"
                  maxLength={10}
                  register={register("birthDate")}
                  error={errors?.birthDate?.message}
                  onChange={(e) =>
                    handleBirthDateInput(e, setValue, "birthDate")
                  }
                  defaultValue={patientData?.birthDate ? moment(patientData?.birthDate).format("DD/MM/YYYY") : ''}
                  autoComplete="off"
                />

                <Input
                  containerClassName="form-floating mb-3"
                  type="tel"
                  label="Telefone"
                  placeholder="(99) 99999-9999"
                  maxLength={15}
                  register={register("mobilePhone")}
                  error={errors?.mobilePhone?.message}
                  onChange={(e) =>
                    handleMobilePhoneInput(e, setValue, "mobilePhone")
                  }
                  defaultValue={mobilePhoneMask(
                    `${patientData.dddMobilePhone}${patientData.mobilePhone}`
                  )}
                  autoComplete="off"
                />
                {/* <SelectDropdown
                  containerClassName="form-floating mb-3"
                  options={healthInsurenceOptions}
                  label="Convênio"
                  name="healthInsurence"
                  placeholder="Convênio"
                  control={control}
                  error={errors.healthInsurence?.label?.message}
                  isSearchable={true}
                  isDisabled={false}
                  isClearable={true}
                  defaultValue={healthInsurenceOptions.find(option => option.value === patientData.scheduleHealthInsurence)}
                /> */}
              </>
            )}
          </>)}
        </Modal.Body>
        {!isLoadingPaciente && (
          <Modal.Footer>
            {patientData.cpf && !patientLoading && !editTaskData.id && (
              <Button
                type="button"
                className="btn btn-light rounded-pill"
                onClick={() => {
                  clearPatient();
                }}
              >
                <i className="uil uil-arrow-left me-2"></i>Voltar
              </Button>
            )}
            <Button type="submit" className="btn-primary ms-auto rounded-pill" disabled={patientLoading}>
              {patientData.cpf ? "Salvar" : "Prosseguir"}
              {!!patientLoading && (
                <CircularProgress
                  color="inherit"
                  size={12}
                  className="align-text-top ms-2"
                />
              )}
            </Button>
          </Modal.Footer>
        )}
      </form>
    </>
  );
};

export default ModalPatient;
