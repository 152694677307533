import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { WhatsappInstance, Chat, Message, NewMessage } from "types/whatsapp";

import { api } from "services";
import { useAuth } from "providers/Auth";
import { useUser } from "providers/User";
import { toast } from "utils/toast";
import logger from "utils/logger";

interface WhatsappProviderProps {
  children: ReactNode;
}

interface WhatsappProviderData {
  isLoadingWhatsapp: boolean;
  setIsLoadingWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
  showWhatsappModal: boolean;
  setShowWhatsappModal: React.Dispatch<React.SetStateAction<boolean>>;
  conectedWhatsapp: boolean;
  setConectedWhatsapp: React.Dispatch<React.SetStateAction<boolean>>;
  qrCode: string;
  setQrCode: React.Dispatch<React.SetStateAction<string>>;
  checkInstance: () => void;
  inicializeInstance: () => void;
  finishInstance: () => void;
  getChats: (showLoading?: boolean) => Promise<void>;
  chats: Chat[];
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>;
  isLoadingChats: boolean;
  getMessages: (id: string, showLoading?: boolean) => Promise<void>;
  setIsLoadingChats: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingMessages: boolean;
  setIsLoadingMessages: React.Dispatch<React.SetStateAction<boolean>>;
  isSendingMessages: boolean;
  setIsSendingMessages: React.Dispatch<React.SetStateAction<boolean>>;
  activeChat: Chat;
  setActiveChat: React.Dispatch<React.SetStateAction<Chat>>;
  isLoadingChat: boolean;
  setIsLoadingChat: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: (body: NewMessage) => Promise<void>;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  deleteChat: (id: string, callback?: () => void) => Promise<void>; 
}

export const WhatsappContext = createContext<WhatsappProviderData>(
  {} as WhatsappProviderData
);

export const WhatsappProvider = ({
  children,
}: WhatsappProviderProps) => {

  const { token } = useAuth();
  const { userData } = useUser();

  const [showWhatsappModal, setShowWhatsappModal] = useState(false);
  const [conectedWhatsapp, setConectedWhatsapp] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [isLoadingWhatsapp, setIsLoadingWhatsapp] = useState<boolean>(false);
  const [isSendingMessages, setIsSendingMessages] = useState<boolean>(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoadingChats, setIsLoadingChats] = useState<boolean>(false);
  const [isLoadingChat, setIsLoadingChat] = useState<boolean>(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [activeChat, setActiveChat] = useState({} as Chat);
  const [messages, setMessages] = useState<Message[]>([]);

  const checkInstance = async () => {
    try {
      logger.log("WhatsApp checkInstance");
      setIsLoadingWhatsapp(true);
      setIsLoadingChats(true);
      const response = await api.get(`/WhatsApp/CheckInstance`);
      if (response.data as WhatsappInstance) {
        logger.log(response.data);
        setConectedWhatsapp(!response.data.error)
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingWhatsapp(false);
      setIsLoadingChats(false);
    }
  }

  const inicializeInstance = async () => {
    try {
      logger.log("WhatsApp checkInstance");
      setIsLoadingWhatsapp(true);
      const response = await api.post(`/WhatsApp/Initialize`);
      if (response.data && response.data.error) {
        console.log(response.data.error);
      }
      if (response.data.qrcode) {
        setQrCode(response.data.qrcode);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingWhatsapp(false);
    }
  }

  const finishInstance = async () => {
    try {
      logger.log("WhatsApp finishInstance");
      setIsLoadingWhatsapp(true);
      const response = await api.delete(`/WhatsApp/FinishInstance`);
      logger.log(response);
      if (response.status == 200) {
        setConectedWhatsapp(false);
        toast.fire({
          icon: "success",
          title: "O WhatsApp foi desconectado.",
        });
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingWhatsapp(false);
    }
  }

  const getChats = async (showLoading: boolean = false) => {
    if (showLoading) {
      setIsLoadingChats(true);
    }
    try {
      logger.log("getChats");
      const response = await api.get(`/WhatsApp/GetLastMessages`);
      if (response.status == 200) {
        const filteredArray = response.data.filter((item: Chat) => item.groupId === null);

        // Ordena as chaves (datas) de forma decrescente
        const orderedArray = filteredArray.sort((a: Chat, b: Chat) => {
          return moment(b.date).valueOf() - moment(a.date).valueOf();
        });

        setChats(orderedArray);
        logger.log(orderedArray);
      }

    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingChats(false);
    }
  }

  const getMessages = async (id: string, showLoading: boolean = false) => {
    if (!id || id === "") {
      toast.fire({
        icon: "error",
        title: "Informe o ID!",
      });
      return;
    }
    if (showLoading) {
      setIsLoadingMessages(true);
    }
    try {
      logger.log("WhatsApp getMessages");
      const response = await api.get(`/WhatsApp/GetMessages/${id}`);
      logger.log(response);
      if (response.status == 200) {
        setMessages(response.data);
      } else {
        setMessages([]);
      }

    } catch (error: any) {
      console.error(error);
      setMessages([]);
    } finally {
      setIsLoadingMessages(false);
    }
  }

  const sendMessage = async (body: NewMessage) => {
    try {
      logger.log("WhatsApp sendMessage");
      setIsSendingMessages(true);
      const response = await api.post(`/WhatsApp/SendTextMessage`, body);
      logger.log(response);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsSendingMessages(false);
    }
  }

  const deleteChat = async (id: string, callback?: () => void) => {
    if (!id || id === "") {
      toast.fire({
        icon: "error",
        title: "Informe o ID da conversa!",
      });
      return;
    }
    try {
      logger.log("WhatsApp deleteChat");
      setIsLoadingMessages(true);
      const response = await api.delete(`/WhatsApp/DeleteMessages/${id}`);
      logger.log(response);
      if (response.status === 200) {
        toast.fire({
          icon: "success",
          title: "Conversa apagada com sucesso!",
        });
        const removedChats = chats.filter((item: Chat) => ((item.sentOrReceived ? item.toId : item.fromId) != id));
        setChats(removedChats);

        // Executa o callback se fornecido
        if (callback) {
          callback();
        }
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoadingMessages(false);
    }
  }

  return (
    <WhatsappContext.Provider
      value={{
        showWhatsappModal,
        setShowWhatsappModal,
        isLoadingWhatsapp,
        setIsLoadingWhatsapp,
        conectedWhatsapp,
        setConectedWhatsapp,
        checkInstance,
        inicializeInstance,
        qrCode,
        setQrCode,
        finishInstance,
        getChats,
        chats,
        setChats,
        isLoadingChats,
        getMessages,
        setIsLoadingChats,
        isLoadingMessages,
        setIsLoadingMessages,
        isSendingMessages,
        setIsSendingMessages,
        isLoadingChat,
        setIsLoadingChat,
        activeChat,
        setActiveChat,
        sendMessage,
        messages,
        setMessages,
        deleteChat
      }}
    >
      {children}
    </WhatsappContext.Provider>
  );
};

export const useWhatsapp = () => useContext(WhatsappContext);
