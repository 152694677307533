/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useAuth } from "providers/Auth";
// import { useLocation, useNavigate, Navigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import { api } from "services";
// import { toast } from "utils/toast";
// import ProfessionalPaymentInfos from "./components/ProfessionalPaymentInfos";
// import PaymentOptions from "./components/PaymentOptions";
// import CreditForm from "./components/CreditForm";
// import TicketForm from "./components/TicketForm";
// import DebitForm from "./components/DebitForm";
// import PixForm from "./components/PixForm";
// import { useScheduleInfos } from "providers/schedule";
// import { AppointmentData } from "./types/appointment";
// import { ScheduleDetails, ScheduleInfosInterface } from "providers/schedule/types";
// import { allDoctorsResponseInterface } from "providers/doctorsData/types";
// import { ProfessionalPaymentInfosSkeleton } from "./components/ProfessionalPaymentInfosSkeleton";
// import FakeCreditForm from "./components/fakeCreditForm";
// import { FakePaymentInfos } from "./components/fakePaymentInfos";

// interface AppointmentInterface {
//   appointmentData: AppointmentData | undefined;
// }

const Payment = () => {
  // const { token } = useAuth();

  // const [loading, setLoading] = useState(true);

  // const { clearScheduleInfos } = useScheduleInfos();

  // const navigate = useNavigate();
  // const location = useLocation();

  // const [paymentOption, setPaymentOption] = useState<"credito" | "debito" | "boleto" | "pix">("credito");

  // const [scheduleInfos, setScheduleInfos] = useState<ScheduleInfosInterface>({} as ScheduleInfosInterface);

  // const localStorageScheduleInfos: ScheduleDetails = JSON.parse(localStorage.getItem("@Conectadoc:scheduleInfo") || "{}");

  // const reset = () => {
  //   clearScheduleInfos();
  //   navigate("/");
  //   return setLoading(false);
  // };

  // const handleGetDoctorData = async (idSpecialistDoctor: number) => {
  //   try {
  //     if (!localStorageScheduleInfos || !idSpecialistDoctor) {
  //       return reset();
  //     }
  //     const { data } = (await api.get(`/SpecialistDoctor/list?idSpecialistDoctor=${idSpecialistDoctor}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })) as {
  //       data: allDoctorsResponseInterface;
  //     };

  //     const doctor = data.specialistDoctors;

  //     return doctor.at(0);
  //   } catch (error) {}
  // };

  // const handleGetData = async () => {
  //   try {
  //     if (!localStorageScheduleInfos) {
  //       return reset();
  //     }
  //     setLoading(true);
  //     const doctor = await handleGetDoctorData(localStorageScheduleInfos.idDoctor);

  //     const place = doctor?.places.find((elm) => elm.id === localStorageScheduleInfos.idPlace);

  //     if (doctor && place) {
  //       const output = {
  //         doctor,
  //         place,
  //         scheduleDetails: localStorageScheduleInfos.details,
  //         schedule: localStorageScheduleInfos.schedule,
  //         isVideo: localStorageScheduleInfos.details.type === "video",
  //       };

  //       setScheduleInfos(output);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  // useEffect(() => {
  //   handleGetData();
  // }, []);

  // if (!location.state || !token) {
  //   return <Navigate to={"/"} />;
  // }

  // const { appointmentData } = location.state as AppointmentInterface;

  // const { isVideo, scheduleDetails, schedule } = scheduleInfos;

  // const handleSchedule = async () => {
  //   try {
  //     const body = {
  //       scheduleTimeId: schedule.agendas[0].idSchedule,
  //       healthInsuranceId:
  //         scheduleDetails.healtyInsurance.name.toLowerCase() === "particular" ? undefined : scheduleDetails.healtyInsurance.id,
  //       date: schedule.data,
  //       scheduleType: 0,
  //     };

  //     const { data } = await api.post("/Attendance/home", body);

  //     //* Resultado negativo vem vazio da api, por isso é verificado primeiro.
  //     if (data) {
  //       return toast.fire({
  //         title: "Algo deu errado",
  //         text: "Por favor, tente novamente mais tarde",
  //         icon: "warning",
  //       });
  //     } else {
  //       await toast.fire({
  //         title: "Consulta agendada",
  //         text: "Você será redirecionado para outra pagina...",
  //         icon: "success",
  //       });
  //       clearScheduleInfos();
  //       navigate("/consultas");
  //     }
  //   } catch (error) {
  //     // console.log({ error });

  //     return toast.fire({
  //       title: "Algo deu errado",
  //       text: "Por favor, tente novamente mais tarde",
  //       icon: "warning",
  //     });
  //   }
  // };

  // return (
  //   <>
  //     {loading ? (
  //       <div id="wrapper">
  //         <div className="page-header flex-fill justify-content-start">
  //           <div className="container py-5">
  //             <div className="row">
  //               <ProfessionalPaymentInfosSkeleton />
  //               <div className="col-lg-7 col-xl-8 order-lg-0">
  //                 <div className="card text-body bg-light p-3 px-md-5 py-md-4">
  //                   <FakePaymentInfos />
  //                   <hr />
  //                   <FakeCreditForm />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <div id="wrapper">
  //         <div className="page-header flex-fill justify-content-start">
  //           <div className="container py-5">
  //             {isVideo || appointmentData ? (
  //               <>
  //                 <h1 className="text-center mb-1">Pagamento</h1>
  //                 <p className="text-center mb-5">
  //                   {!appointmentData
  //                     ? "Confirme os dados da teleconsulta e insira os dados de pagamento"
  //                     : "Efetive o pagamento da sua teleconsulta"}
  //                 </p>
  //               </>
  //             ) : (
  //               <>
  //                 <h1 className="text-center mb-1">Consulta Presencial</h1>
  //                 <p className="text-center mb-5">Confirme os Dados da Consulta Presencial</p>
  //               </>
  //             )}

  //             <div className="row">
  //               <ProfessionalPaymentInfos scheduleInfos={scheduleInfos} appointment={appointmentData ? appointmentData : undefined} />

  //               <div className="col-lg-7 col-xl-8 order-lg-0">
  //                 <div className="card text-body bg-light p-3 px-md-5 py-md-4">
  //                   {isVideo ? (
  //                     <>
  //                       <h5 className="mb-3 center">Forma de Pagamento</h5>
  //                       <PaymentOptions value={paymentOption} setValue={setPaymentOption} />
  //                       <hr />
  //                     </>
  //                   ) : (
  //                     <>
  //                       <h5 style={{ textAlign: "center" }} className="mb-3">
  //                         Confira os dados da consulta presencial solicitada no quadro ao lado e clique no botão abaixo para confirmá-los.
  //                       </h5>

  //                       <div className="text-center">
  //                         <button className="btn btn-lg btn-success" onClick={handleSchedule}>
  //                           Confirmar Consulta Presencial
  //                         </button>
  //                       </div>
  //                     </>
  //                   )}

  //                   {isVideo && (
  //                     <>
  //                       {paymentOption === "credito" && (
  //                         <>
  //                           <h5 id="h5DadosCartao" className="mb-3">
  //                             Dados do cartão de crédito
  //                           </h5>
  //                           <div id="divCCAceitos" className="mb-3">
  //                             <label className="label d-block" htmlFor="txtNumeroCartao">
  //                               Cartões Aceitos:
  //                             </label>
  //                             <img src={"https://conectadoc.com.br/app-assets/img/imgFormaPagamento.jpg"} alt="Cartões Aceitos" />
  //                           </div>

  //                           <CreditForm
  //                             reportMessage={scheduleDetails.reportMessage}
  //                             healthyInsurance={scheduleDetails.healtyInsurance}
  //                             schedule={schedule}
  //                           />
  //                         </>
  //                       )}

  //                       {paymentOption === "debito" && (
  //                         <>
  //                           <h5 id="h5DadosCartao" className="mb-3">
  //                             Dados do cartão de débito
  //                           </h5>
  //                           <div id="divCCAceitos" className="mb-3">
  //                             <label className="label d-block" htmlFor="txtNumeroCartao">
  //                               Cartões/Bancos Aceitos:
  //                             </label>
  //                             <img src={"https://conectadoc.com.br/_assets/images/cartoesdebitos.png"} alt="Cartões Aceitos" />
  //                           </div>

  //                           <DebitForm healthyInsurance={scheduleDetails.healtyInsurance} schedule={schedule} />
  //                         </>
  //                       )}

  //                       {paymentOption === "boleto" && (
  //                         <>
  //                           <h5 id="h5DadosCartao" className="mb-3">
  //                             Dados do cliente
  //                           </h5>

  //                           <TicketForm />
  //                         </>
  //                       )}

  //                       {paymentOption === "pix" && (
  //                         <>
  //                           <h5 id="h5DadosCartao" className="mb-3">
  //                             Dados do cliente
  //                           </h5>

  //                           <PixForm />
  //                         </>
  //                       )}
  //                     </>
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );
  return (
    <>pagamento</>
  );
};

export default Payment;
