interface WppAttendanceProps {
  isToEditPwd?: boolean;
}

const WppAttendance = ({ isToEditPwd }: WppAttendanceProps) => {
  const wppNumber = 5541988776211;

  const message = `Olá!%0aGostaria de ajuda para ${
    isToEditPwd ? "alterar" : "recuperar"
  } minha senha...`;

  return (
    <>
      <span className="text-center">
        Caso ainda precise de ajuda, contate nossos atendentes clicando abaixo:
      </span>

      <a
        href={`https://wa.me/${wppNumber}?text=${message}`}
        target="_blank"
        rel="noreferrer"
        className="d-flex gap-2 justify-content-center py-2 text-decoration-none"
      >
        <i className="uil uil-whatsapp" />
        <span>Falar com um atendente</span>
      </a>
    </>
  );
};

export default WppAttendance;
