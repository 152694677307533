import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useState, useEffect } from "react";
import { Badge, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

// import { Board, Column, Task } from "./types";
import { useBoard } from "providers/Board";
import { usePatient } from "providers/Patient";
import { Board, BoardDefault, Id, Column, ColumnDefault, Task } from "types/board";
import { numberValue } from "utils/formMasks";

import TaskCard from "./TaskCard";
import logger from "utils/logger";
import Swal from "sweetalert2";
import { PatientData } from "types/patient";

interface PropsColumnContainer {
  column: Column;
  tasks: Task[];
}

function ColumnContainer({
  tasks,
  column,
}: PropsColumnContainer) {

  const {
    boards,
    setBoards,
    getBoards,
    boardData,
    // isLoading,
    isLoadingBoards,
    setBoardData,
    tasksData,
    setTasksData,
    editTaskData,
    setEditTaskData,
    editColumnData,
    setEditColumnData,
    columnsData,
    setColumnsData,
    taskName,
    setTaskName,
    taskDescription,
    setTaskDescription,
    taskDate,
    setTaskDate,
    taskMoveTo,
    setTaskMoveTo,
    taskType,
    setTaskType,
    taskId,
    setTaskId,
    showAddTaskModal,
    setShowAddTaskModal,
    createBoard,
    updateBoard,
    deleteBoard,
    getBoardById,
    getTasksByBoard,
    getPatientsByBoards,
    patientsByBoard,
    setPatientsByBoard,
    addColumnToBoard,
    editColumnFromBoard,
    addTaskToBoardColumn,
    getTasksByColumn,
    delColumnFromBoard,
    delTaskFromBoardColumn,
    editTaskFromBoardColumn,
    moveTaskBetweenBoardColumn,
    autoUpdate,
    setAutoUpdate,
    stopAutoUpdate,
    setStopAutoUpdate,
    checkDuplicatedTasks,
    keepDuplicates,
    setKeepDuplicates,
    showDuplicates,
    setShowDuplicates,
    handleClick,
    handleMouseEnter,
    handleMouseLeave,
    searchTerm,
    setSearchTerm,
    filteredTasks,
    setFilteredTasks,
    handleSearchChange,
    updateTasksOrder,
    handleDeleteTask,
    handleTaskModal,
    columnAddIndex,
    setColumnAddIndex,
    columnName,
    setColumnName,
    columnId,
    setColumnId,
    showAddColumnModal,
    setShowAddColumnModal,
    hex,
    setHex,
    createColumn,
    deleteColumn,
    updateColumn
  } = useBoard();

  const { patients, setPatients, patientData, setPatientData, patientLoading, searchPatient } = usePatient();

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const handleNewTaskModal = (columnId: Id, type: number = 0) => {
    // type 0 = patient, 1 = task
    setColumnId(columnId);
    setTaskDescription("");
    setTaskType(type);
    setShowAddTaskModal(true);
    setStopAutoUpdate(true);
    setTaskName("");
    setTaskId("");
    setPatients([]);
    setPatientData({} as PatientData);
    setEditTaskData({} as Task);
  };

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
    disabled: (column.type == 1),// 0 = normal, 1 = start, 2 = end, 3 = appointment
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  };

  const handleDeleteColumn = (id: Id) => {
    Swal.fire({
      title: "Tem certeza que deseja remover esta coluna?",
      icon: "question",
      iconColor: 'var(--bs-danger)',
      position: "center",
      showConfirmButton: true,
      confirmButtonText: "Sim, remover",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      focusConfirm: false,
      customClass: {
        confirmButton: "btn btn-outline-danger",
        cancelButton: "btn btn-light",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        const deleteIndex = columnsData.findIndex((c) => c.id === id);
        const tasks = columnsData[deleteIndex]?.tasks ?? [];
        if (columnsData[deleteIndex] && tasks.length > 0) {
          Swal.fire({
            title: "Você perderá todos os itens desta coluna, deseja mesmo continuar?",
            icon: "question",
            iconColor: 'var(--bs-danger)',
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "Sim, excluir",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            buttonsStyling: false,
            reverseButtons: true,
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              confirmButton: "btn btn-outline-light",
              icon: "text-white border-white",
              cancelButton: "btn btn-light",
              popup: "bg-danger text-white"
            },
          }).then((res) => {
            if (res.isConfirmed) {
              deleteColumn(id);
            }
          });
        } else {
          deleteColumn(id);
        }
      }
    });
  }

  const checkColumnDuplicates = (tasks: Task[]) => {
    var addClass = "";
    if (keepDuplicates && keepDuplicates.length > 0 && !tasks.some(task => keepDuplicates.includes(task.id))) {
      addClass += " opacity-50 ";
    }

    if (showDuplicates && showDuplicates.length > 0 && !tasks.some(task => showDuplicates.includes(task.id)) && keepDuplicates && keepDuplicates.length === 0) {
      addClass += " opacity-50 ";
    }
    return addClass;
  }

  const [totalColumn, setTotalColumn] = useState("0");

  const calcTotal = () => {
    var total = 0;
    if (tasks) {
      total = tasks?.reduce((sum: number, task: Task) => { return task.taskVal ? sum + task.taskVal : sum; }, 0)
    }
    setTotalColumn(numberValue(total));
  }

  useEffect(() => {
    calcTotal();
  }, [tasks]);

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        className={`kanban-board rounded border-top border-3 ${column.type === 4 ? 'border-blue' : ''} ${isDragging ? 'opacity-50' : ''} ${checkColumnDuplicates(tasks)}`}
      >
        {(column.type != 1) && (
          // 0 = normal, 1 = start, 2 = end, 3 = appointment, 4 = agendamento Conecta
          <OverlayTrigger
            placement="top"
            container={document.body}
            overlay={<Tooltip>Adicionar Coluna</Tooltip>}
          >
            <div className="add-column position-absolute mt-3" style={{ top: "1rem", left: -21 }}>
              <a className="btn btn-sm btn-outline-secondary rounded-circle" onClick={() => {
                setShowAddColumnModal(true);
                setEditColumnData({} as Column);
                setStopAutoUpdate(true);
                var colIndex = columnsData.findIndex((c) => c.id === column.id) - 1;
                setColumnAddIndex(colIndex);
              }}><i className="uil uil-plus"></i></a>
            </div>
          </OverlayTrigger>
        )}

        {(column.type != 2) && (
          <OverlayTrigger
            placement="top"
            container={document.body}
            overlay={<Tooltip>Adicionar Coluna</Tooltip>}
          >
            <div className="add-column position-absolute mt-3" style={{ top: "1rem", right: -21 }}>
              <a className="btn btn-sm btn-outline-secondary rounded-circle" onClick={() => {
                setShowAddColumnModal(true);
                setEditColumnData({} as Column);
                setStopAutoUpdate(true);
                var colIndex = columnsData.findIndex((c) => c.id === column.id);
                setColumnAddIndex(colIndex);
              }}><i className="uil uil-plus"></i></a>
            </div>
          </OverlayTrigger>
        )}

        {/* Column title */}
        <div className="kanban-board-header rounded-top">
          <div className="row position-relative z-1">
            <div className="col col-auto pe-0"
              {...listeners}
              {...attributes}>
              {([0, 3, 4].includes(column.type)) && (
                // 0 = normal, 1 = start, 2 = end, 3 = appointment
                <i className="uil uil-draggabledots drag-handle"></i>
              )}
              <Badge bg="secondary">
                {tasks.length}
              </Badge>
            </div>
            <div className="col">
              <b>{column.title}</b>
              <div className={`small opacity-50`}>
                R$ {totalColumn}
              </div>
            </div>
            <div className="col col-auto position-relative">
              <Dropdown>
                <Dropdown.Toggle variant="light" size="sm" className="no-arrow rounded-circle p-2 lh-1 position-absolute top-0 end-0 my-n1">
                  <i className="uil uil-ellipsis-h"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align='end'>
                  {column.type == 1 && (
                    // 0 = normal, 1 = start, 2 = end, 3 = appointment
                    <>
                      <Dropdown.Item as="button" onClick={() => { handleNewTaskModal(column.id); }}>
                        <i className="uil uil-user-plus me-2"></i>Adicionar Paciente
                      </Dropdown.Item>
                      <Dropdown.Item as="button" onClick={() => { handleNewTaskModal(column.id, 1); }}>
                        <i className="uil uil-plus me-2"></i>Adicionar Task
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item as="button" onClick={() => { setShowAddColumnModal(true); setEditColumnData(column); }}>
                    <i className="uil uil-edit-alt me-2"></i>Editar
                  </Dropdown.Item>
                  {([0, 3, 4].includes(column.type)) && (
                    // 0 = normal, 1 = start, 2 = end, 3 = appointment
                    <Dropdown.Item as="button" className='text-danger' onClick={() => { handleDeleteColumn(column.id); }}>
                      <i className="uil uil-trash me-2"></i>Remover
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {([3, 4].includes(column.type)) && (
            <div className="opacity-25 position-absolute end-0 top-0 pe-3 z-0">
              <i className="uil uil-calendar-alt display-1 opacity-25"></i>
            </div>
          )}
        </div>

        {!isDragging && (<>
          {/* Column task container */}
          <div className="kanban-drag">
            <SortableContext items={tasksIds}>
              {tasks.sort((a, b) => {
                if(column.type === 4 && a.appointment && b.appointment){
                  const dateA = a.appointment.date ? new Date(a.appointment.date) : null;
                  const dateB = b.appointment.date ? new Date(b.appointment.date) : null;
  
                  if (!dateA && !dateB) return 0; // Ambos são null, mantêm a mesma ordem
                  if (!dateA) return 1;           // Nulls vão para o final
                  if (!dateB) return -1;          // Nulls vão para o final
  
                  return dateA - dateB;           // Ordena por data
                }else{
                  return 0;
                }
              }).map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  column={column}
                  setColumnId={setColumnId}
                />
              ))}
            </SortableContext>
          </div>
        </>)}

        {/* Column footer */}
        {column.type == 1 && (
          // 0 = normal, 1 = start, 2 = end, 3 = appointment
          <div className="bottom-button border-top row g-0 flex-nowrap">
            <a role="button" className='col-6 py-2' onClick={() => { handleNewTaskModal(column.id, 1); }}>
              <i className="uil uil-plus me-2"></i>Task
            </a>
            <a role="button" className='col-6 py-2 border-start' onClick={() => { handleNewTaskModal(column.id); }}>
              <i className="uil uil-user-plus me-2"></i>Paciente
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default ColumnContainer;
