import moment from "moment";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuth } from "providers/Auth";
import axios from 'axios';
import { api } from "services";
import { Role } from "types/enums";
import { Professional, ProfessionalSearchData } from "types/professional";
import { ProfessionalEditData } from "types/register";
import { useUser } from "providers/User";
import { toast } from "utils/toast";

interface ProfessionalProviderProps {
  children: ReactNode;
}

interface ProfessionalProviderData {
  professionalSearchData: ProfessionalSearchData;
  setProfessionalSearchData: React.Dispatch<React.SetStateAction<ProfessionalSearchData>>;
  isLoadingProfessionals: boolean;
  setIsLoadingProfessionals: React.Dispatch<React.SetStateAction<boolean>>;

  activeProfessionals: Professional[];
  activeProfessionalsTotal: number;
  activeProfessionalsTotalPages: number;
  activeProfessionalsCurrentPage: number;
  activeProfessionalsFound: Professional[];
  activeProfessionalsFoundTotal: number;
  activeProfessionalsFoundTotalPages: number;
  activeProfessionalsFoundCurrentPage: number;

  getActiveProfessionals: (
    companyId?: number,
    currentPage?: number,
    search?: string,
    contract?: number
  ) => Promise<void>;

  searchActiveProfessionals: (
    data: ProfessionalSearchData,
    currentPage?: number
  ) => Promise<void>;

  setActiveProfessionalsFound: React.Dispatch<
    React.SetStateAction<Professional[]>
  >;

  setActiveProfessionalsCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;
  
  setActiveProfessionalsFoundCurrentPage: React.Dispatch<
    React.SetStateAction<number>
  >;

  editProfessional: (
    data: ProfessionalEditData,
    professionalId: number,
    onHide: () => void,
    companyId?: number
  ) => Promise<void>;
  
  isLoading: boolean;
}

export const ProfessionalContext = createContext<ProfessionalProviderData>(
  {} as ProfessionalProviderData
);

export const ProfessionalProvider = ({
  children,
}: ProfessionalProviderProps) => {
  const { token } = useAuth();
  const { userData } = useUser();

  const professionalsLimit = 10;

  const [isLoadingProfessionals, setIsLoadingProfessionals] = useState(false);

  //#region provider states activeProfessionals
  const [activeProfessionals, setActiveProfessionals] = useState<
    Professional[]
  >([]);

  const [activeProfessionalsTotal, setActiveProfessionalsTotal] = useState(0);

  const [activeProfessionalsTotalPages, setActiveProfessionalsTotalPages] =
    useState(0);

  const [activeProfessionalsCurrentPage, setActiveProfessionalsCurrentPage] =
    useState(1);

  const [activeProfessionalsFound, setActiveProfessionalsFound] = useState<
    Professional[]
  >([]);

  const [activeProfessionalsFoundTotal, setActiveProfessionalsFoundTotal] =
    useState(0);

  const [
    activeProfessionalsFoundTotalPages,
    setActiveProfessionalsFoundTotalPages,
  ] = useState(0);

  const [
    activeProfessionalsFoundCurrentPage,
    setActiveProfessionalsFoundCurrentPage,
  ] = useState(1);
  //#endregion

  const [professionalSearchData, setProfessionalSearchData] = useState({} as ProfessionalSearchData);

  const [isLoading, setIsLoading] = useState(false);

  // var searchProfessionalController:AbortController;
  const searchActiveProfessionals = async (
    data: ProfessionalSearchData,
    currentPage = 1,
  ) => {
    setIsLoadingProfessionals(true);
    setActiveProfessionalsFound({} as Professional[]);
    try {
      const response = await api.get("/Crm/Professionals/list", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          search: data.name,
          forceCancel: true
        }
      });
      if (response.data && response.data.specialistDoctors) {
        const filteredProfessionals = response.data.specialistDoctors.filter((professional: Professional) => professional.id !== userData.id);
        setActiveProfessionalsFound(filteredProfessionals);
        setActiveProfessionalsFoundTotal(
          Number(filteredProfessionals.length)
        );
      }
      setIsLoadingProfessionals(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Busca cancelada');
      } else {
        setIsLoadingProfessionals(false);
        console.error(error);
      }
    }
  };

  const getActiveProfessionals = async (
    companyId?: number,
    currentPage = 1,
    search = "",
    contract?: number
  ) => {
    setIsLoadingProfessionals(true);

    try {
      const response = await api.get("/Professional/search", {
        params: {
          page: currentPage,
          size: professionalsLimit,
          professionalType: Role.Professional,
          active: true,
          companyId: companyId === -1 ? undefined : companyId,
          search: search,
          contract: contract === -1 ? undefined : contract,
        },
      });

      setIsLoadingProfessionals(false);
      setActiveProfessionals(response.data);
      setActiveProfessionalsTotal(Number(response.headers["x-total-count"]));

      setActiveProfessionalsTotalPages(
        Math.ceil(
          Number(response.headers["x-total-count"]) / professionalsLimit
        )
      );
    } catch (error) {
      console.error(error);
      setIsLoadingProfessionals(false);
    }
  };

  const editProfessional = async (
    data: ProfessionalEditData,
    professionalId: number,
    onHide: () => void,
    companyId?: number
  ) => {
    setIsLoading(true);

    const body = {
      ...data,
      birthDate: moment(data.birthDate, "DD/MM/YYYY").toISOString(),
      dddMobilePhone: data.mobilePhone.slice(1, 3),
      mobilePhone: data?.mobilePhone?.replace("-", "").slice(5),
      dddComercialPhone: data.comercialPhone.slice(1, 3),
      comercialPhone: data?.comercialPhone?.replace("-", "").slice(5),
      role: Role.Professional,
    };

    try {
      await api.put(`/Professional/${professionalId}`, body);

      setIsLoading(false);

      onHide();

      toast.fire({
        icon: "success",
        title: "Dados atualizados com sucesso!",
      });

      getActiveProfessionals(companyId, activeProfessionalsCurrentPage);
    } catch (error: any) {
      setIsLoading(false);

      let message = "";
      if (error.response.status === 400) {
        message = (Object.values(error.response.data)[0] as string[])[0];
      } else {
        message = "Ocorreu um erro, tente novamente.";
      }

      toast.fire({
        icon: "error",
        title: message,
      });
    }
  };

  useEffect(() => {
    setProfessionalSearchData({} as ProfessionalSearchData);
  }, [token]);

  return (
    <ProfessionalContext.Provider
      value={{

        professionalSearchData,
        setProfessionalSearchData,
        isLoadingProfessionals,
        setIsLoadingProfessionals,

        activeProfessionals,
        activeProfessionalsTotal,
        activeProfessionalsTotalPages,
        activeProfessionalsCurrentPage,
        activeProfessionalsFound,
        activeProfessionalsFoundTotal,
        activeProfessionalsFoundTotalPages,
        activeProfessionalsFoundCurrentPage,

        getActiveProfessionals,
        searchActiveProfessionals,

        setActiveProfessionalsCurrentPage,
        setActiveProfessionalsFoundCurrentPage,
        setActiveProfessionalsFound,

        isLoading,

        editProfessional,
      }}
    >
      {children}
    </ProfessionalContext.Provider>
  );
};

export const useProfessional = () => useContext(ProfessionalContext);
